#reset-password-page {
    @include container;

    padding-top: 0;
    padding-bottom: 0;
    min-height: calc(100vh - 220px);
    display: flex;
    align-items: center;
    line-height: 1.3em;

    p {
        line-height: inherit;
    }

    .request-password-body {
        line-height: 1.5em;
    }

    .item-input {
        margin: 15px 0;
        display: block;

        input {
            &:focus {
                box-shadow: none;
                outline: none;
                border-bottom: 1px solid rgb(0,0,0);
            }
        }
    }

    .buttons {
        margin-top: 30px;

        .button + .button {
            margin-top: 8px;
        }
    }
}
