@mixin error-message {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCAzMiAzMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiNjMDAiPiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNiwzIEMyMy4yLDMgMjksOC44IDI5LDE2IEMyOSwyMy4yIDIzLjIsMjkgMTYsMjkgQzguOCwyOSAzLDIzLjIgMywxNiBDMyw4LjggOC44LDMgMTYsMyBMMTYsMyBaIE0xNiwwIEM3LjIsMCAwLDcuMiAwLDE2IEMwLDI0LjggNy4yLDMyIDE2LDMyIEMyNC44LDMyIDMyLDI0LjggMzIsMTYgQzMyLDcuMiAyNC44LDAgMTYsMCBMMTYsMCBaIiBpZD0iU2hhcGUiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPiAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjE0LjUiIHk9IjciIHdpZHRoPSIzIiBoZWlnaHQ9IjEwIj48L3JlY3Q+ICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgeD0iMTQuNSIgeT0iMjEiIHdpZHRoPSIzIiBoZWlnaHQ9IjQiPjwvcmVjdD4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
  background-position: 0 2px;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  color: $error-color;
}
.page-help-container {
  .order-search-return {
    .search-content-form {
      .alert.mm-form-message {
        padding-left: 20px;
        position: relative;
        @include error-message;
      }
    }
    &.search-result-wrapper {
      display: block;
      margin-top: 0;
    }
  }
  .help-section-wrapper {
    .help-content-box-title {
      // font-size: 12px;
      font-weight: normal;
      text-transform: unset;
    }
    &.contact-page {
      .header-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: none;
        .help-content-box-section-paragraph {
          margin-bottom: 0;
        }
      }
    }
    fieldset {
      border: none;
      margin: 0;
      padding: 0;
    }
  }
}
