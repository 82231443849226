@mixin container {
    padding: rem-calc(30);
    display: flex;
    justify-content: center;

    .inner {
        max-width: 640px;
        width: 100%;

        &-small {
            @include breakpoint(medium up) {
                max-width: 320px;
            }

            width: 100%;
        }
    }
}

.spacer-header {
    margin-top: 140px;
}

@include breakpoint(large up) {
    .spacer-header {
        margin-top: rem-calc(210);
    }
}

.header {
    margin-bottom: 16px;
    letter-spacing: 0.02em;
}
