.jil-pdp-badges {
    &.small {
        width: 100%;
        text-align: center;
        .tile-badges {
            margin-top: -5px;
            padding-bottom: 6px;
        }
    }
    .tile-badges {
        padding-bottom: 5px;
    }
    .product-badge {
        font-size: 11px;
        text-transform: uppercase;
    }
}