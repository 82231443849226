.geoip.message-notification {
  .notification-box {
    align-items: center;
    top: auto;
    bottom: 0;
    column-gap: 20px;
    flex-direction: row;
    justify-content: flex-end;
    left: 0;
    padding: 25px 15px 25px 60px;
    transform: none;
    width: 100%;
    max-width: none;

    .notification-message {
      align-self: auto;
      margin-right: auto;
      padding: 0;

      br {
        display: none;
      }
    }

    .jil-button {
      width: 330px;
    }

    .notification-close {
      padding: 0 10px;
      position: static;
    }
  }
}
