#modal-change-country {
    @include breakpoint(small down) {
        font-size: 14px;
        overflow-y: auto;

        .modal-box {
            max-height: none;
            max-width: none;
            height: 100%;
            width: 100%;

            .modal-box-header {
                min-height: rem-calc(33);
            }
        }

        .separator {
            margin-right: 0;
            margin-left: 0;
        }
    }

    .modal-box-countries {
        .is-current {
            border-color: black;
        }
    }

    .modal-box {
        max-height: 100vh;
    }

    .separator {
        margin-top: 40px;
        height: 0;
    }

    .country-list:nth-of-type(2) {
        margin-top: 20px;
    }
}
