.order-search {
    @include container;

    .header {
        border-bottom: 1px map_get($jil-palette, "black") solid;
        padding-bottom: 13px;
        margin-bottom: 16px;
    }

    .button {
        margin-top: 24px;
    }
}
