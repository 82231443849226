#notify-me-modal {
  .modal-background {
    padding: 0;
    .modal-notify-me {
      .modal-header {
        border-bottom: 1px solid $button-border-gray;
        padding: 25px;
        min-height: 58px;
        .modal-title {
          font-size: 12px;
          font-weight: normal;
          letter-spacing: 0.8px;
          text-align: center;
        }
      }
      .notify-description {
        font-size: 14px;
        font-weight: 500;
        text-align: start;
        margin-bottom: 30px;
      }
      .jils-form-fieldset {
        gap: 0;
      }
      .jils-form-input {
        font-size: 14px;
        margin-bottom: 30px;
      }
      .jils-form-check {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        font-size: 14px;
        input {
          align-self: flex-start;
        }
        span {
          a {
            color: $link-color-gray;
            border-bottom: 1px solid $link-color-gray;
            
          }
        }
      }
      .newsletter-checkbox {
        font-size: 14px;
        margin-top: 16px;
        a {
          color: $link-color-gray;
          border-bottom: 1px solid $link-color-gray;
        }
      }
      .submit-button {
        position: absolute;
        bottom: 30px;
        width: 100%;
        padding-right: 60px;
      }
    }
  }
}