/* For Firefox */
@supports (scrollbar-width: thin) {
  html {
    scrollbar-color: map-get($foundation-palette, lightgrey) transparent;
    scrollbar-width: thin;
  }
}

/* Not for Safari */
@supports not (-webkit-hyphens: none) {
  html {
    overflow: overlay;
    ::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: map-get($foundation-palette, lightgrey);
      border-radius: 10rem;
      border: 1px solid map-get($foundation-palette, lightgrey);
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}
