.order-confirmation_container {
  * {
    font-size: 14px;
    @include breakpoint(large up) {
      font-size: 12px;
    }
  }

  .order-confirmation_head {
    margin-bottom: rem-calc(30);
  }
  .jil-registration {
    .fields-select {
      margin: 55px 0;
      @include breakpoint(large up) {
        margin: 35px 0;
      }
    }
  }
  .accordion {
    margin-bottom: rem-calc(36);
  }

  .accordion-trigger {
    border-bottom: 1px solid #000;
    padding: 10px 0;

    .title-section {
      font-family: $body-font-family;
      font-weight: 500;
      // font-size: rem-calc(12);
      text-transform: none;
    }
  }

  .accordion-content {
    padding-top: rem-calc(23);
  }

  .register-form {
    margin-top: rem-calc(23);
  }

  .d-none {
    display: none !important;
  }

  .leading-lines {
    margin: 0;
    &.shipping-discount {
      display: none;
    }
  }

  .cart-sidebar-section {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    .grand-total {
      font-weight: 400;
      padding-top: 15px;
      padding-bottom: 15px;
      // .end-lines {
      //   font-weight: 600;
      // }
    }
  }

  .title-information {
    font-family: $body-font-family;
    font-weight: 500;
    // font-size: rem-calc(12);
    padding-top: 27px;
  }

  .summary-details {
    font-family: $body-font-family;
    font-weight: 500;
    // font-size: rem-calc(10);
    color: $link-color-gray;
    padding-top: 22px;

    .total-price {
      display: none;
    }
  }

  .order-summary-phone,
  .order-summary-email {
    font-family: $body-font-family;
    font-weight: 500;
    // font-size: rem-calc(10);
    color: $link-color-gray;
  }

  .gift-summary-container {
    padding: 0;
    .gift-summary-title {
      padding-top: 27px;
    }
    .gift-message-summary {
      padding-top: 22px;
    }
  }

  .cart-sidebar-section {
    width: 100%;
    margin-bottom: 20px;
  }

  .accordion-trigger {
    font-size: 14px;
    @include breakpoint(large up) {
      font-size: 12px;
    }
    .product-attributes {
      margin-bottom: 0;
    }
    &.accordion-pdp {
      border: 1px solid #c9c9c5;
      height: 55px;
      // padding-left: 14px;
      padding-left: 0;

      &:after {
        border-radius: 50%;
        border: 1px solid #000;
        height: 10px;
        width: 11px;
        margin-right: 10px;
        line-height: 6px;
      }

      &[aria-expanded='true'] {
        border: none;
      }
    }
  }

  .summary-items-number {
    // background: #d9d9d9;
    border: 1px solid;
    font-family: $body-font-family;
    font-style: normal;
    font-weight: 500;
    font-size: rem-calc(9);

    border-radius: 50%;
    display: inline-block;
    height: 13px;
    line-height: 13px;
    margin-left: 10px;
    width: 13px;
  }

  .wrapper-order-list {
    // background-color: $off-white;
    padding: 5px 13px;
  }

  .order-details-list-box {
    padding-top: rem-calc(25);
  }

  .wrapper-order-list {
    .detail-attribute {
      display: flex;
      margin-bottom: 2px;
    }
  }

  .single-order-detail {
    padding-bottom: 2.5rem;
    + .single-order-detail {
      padding-top: 0 0 2.5rem;
      border-top: 1px solid #c9c9c5;
    }
  }

  .eta-content {
    padding-top: rem-calc(10);
    font-weight: 500;
    // font-size: rem-calc(12);
    color: #969693;
  }
}
