// @import "../content-asset";

.order-search-return,
.help-section-wrapper {
  @extend .content-asset-page;
  @include container;

  .header {
    border-bottom: 1px map_get($jil-palette, 'black') solid;
    padding-bottom: 13px;
    margin-bottom: 16px;
  }

  .item-input {
    margin: 16px 0;
  }

  .button {
    margin-top: 24px;
  }

  .outro {
    margin-top: 40px;
  }
}
