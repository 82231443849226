// Menu Desktop
@use "sass:math";

$color-primary: #111;
$spacing-small: 5px;
$spacing-medium: 1rem;
$font-size-medium: 0.75rem;
$header-height: 44px;
$border-width: 1px;
$z-index-high: 1000;
$transition-fast: 0.3s;
$transition-slow: 0.8s;
$transition-ease: ease-in-out;

/* SHARED STYLES */
.customer-care-layer {
  ul {
    padding-left: 10px;
    text-transform: uppercase;
  }
  .help-menu-phone-number {
    margin-top: 4px;
  }
  .help-contact {
    a {
      display: block;
    }
  }
}
.header-banner-visible {
  .nav-primary,
  .nav-secondary {
    .nav-item {
      .mini-cart-content:not(.show) {
        margin-top: 96px;
        &::before {
          top: 46px;
        }
      }
    }
  }
}
.header-notification-slot-container {
  overflow: hidden;

  .swiper-wrapper {
    box-sizing: border-box;
  }
  .header-banner {
    display: flex;
    justify-content: center;
    padding: $spacing-medium 0;
    position: relative;
    width: 100%;
    font-size: rem-calc(10);
    line-height: 1.2rem;
    text-align: center;
    background-color: #f9f7ef;

    .close-button {
      padding: 0;
      position: absolute;
      top: 50%;
      right: 1.25rem;
      z-index: 1;
      transform: translate(0%, -50%);
      svg {
        display: block;
        height: 10px;
        width: 10px;
      }
    }
    p {
      margin-bottom: 0;
      font-size: inherit;
      line-height: inherit;
    }

    .notifications-slide {
      padding: 0 2.5rem 0 $spacing-medium;
    }

    @media screen and (orientation: landscape) {
      text-align: center;
      align-items: center;
      .notifications-slide {
        padding: 0 2.5rem;
      }
    }
  }

  &.mobile-version:not(.d-none)+header {
    position: sticky;
    top: 0;
    z-index: 105;
  }
}
.html-slot-container {
  display: flex;
  justify-content: center;
}

/* View all */
.viewAllIcon {
  display: none;
}

[id$='-viewall'] {
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  text-decoration: underline;
  gap: 8px;

  &:hover {
    color: #000 !important;
    opacity: 1 !important;
  }

  & > .viewAllIcon {
    display: flex;
    align-items: center;
  }
}

.descriptionMenu {
  display: block;
  padding: 10px 35px 25px;
}

/* MOBILE Specific */
.nav-desktop {
  display: none;
}
.nav-mobile {
  display: grid;
  grid-template-rows: 44px;
  grid-template-columns: 44px 44px auto 44px 44px;
  align-items: center;
  grid-template-areas: 'menuButton . logoMobile searchButton cartButton';
  transition: transform $transition-fast;
  position: absolute;
  width: 100%;
  z-index: $z-index-high;

  & .wrapper-visibility-menu-mobile {
    background: #f9f7ef;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &--link {
    .link-no-hover {
      display: block;
      padding: 11px 13px 13px;
    }
  }

  /* Specific buttons */
  & .open-menu-button {
    grid-area: menuButton;
  }
  & .search-button {
    grid-area: searchButton;
  }
  & .cart-button {
    grid-area: cartButton;
  }
  & .logo-mobile {
    grid-area: logoMobile;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    .home-page &,
    .search-open & {
      display: flex;
    }
  }

  // This chevron/arrow is used on first level menu and on back to menu link - mobile
  .chevronIcon {
    svg {
      width: 12px;
      height: 20px;
      filter: invert(1);
    }
  }

  .mm-menu__primary__start {
    margin-top: 20px;

    &:has(.is-open) {
      flex: 1;

      .nav-primary {
        margin-bottom: 0;
        height: 100%;
      }

      
      & + .mm-menu__primary__end {
        display: none;
      }
    }
  }

  .nav-item {
    .btn-open-submenu {
      width: 100%;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: $spacing-small 10px;
      font-size: $font-size-medium;
      text-transform: uppercase;
    }
    .btn-back-submenu {
      display: none;
    }
  }

  #menu-mobile {
    display: none;
    position: absolute;
    top: 200vh;
    left: 0;
    right: 0;
    /* Fallback for browsers without `100dvh` support */
    min-height: 100vh;
    /* Modern browsers supporting `100dvh` will use this */
    min-height: 100dvh;
    background-color: $off-white;
    box-shadow: 0 -1000px 100px rgba(0, 0, 0, 0.1);
    overflow: auto hidden;
    transition: top $transition-fast $transition-ease;
    z-index: $z-index-high;

    &.menu-mobile-open {
      display: block;
      overflow-y: scroll;
      height: 100%;
      position: fixed;
      top: 0;
      // .item-logo {
      //   display: none !important;
      // }
    }

    //////////////////////////////////////////////////////
    .menu-close {
      display: flex;
      justify-items: center;
      position: absolute;
      left: 0;
      top: 0;
      z-index: $z-index-high;
      padding: 17px;
      cursor: pointer;
    }

    .nav-item {
      &.is-open {
        height: 100%;
        display: flex;
        flex-direction: column;

        .nav-item-title {
          .btn-open-submenu {
            display: none;
          }
          &.my-account-menu {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 0;
            padding-left: 10px;
          }
        }

        .level-2 {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 29px;
          }

          & > .submenu {
            display: flex;
          }

          &.special-padding {
            padding-left: 20px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.headspace {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: top $transition-fast;
    height: $header-height;
    z-index: $z-index-high;
    
    &--fixed {
      position: fixed;
      top: 0;
      background-color: map-get($jil-palette, white);

      .logo-mobile {
        display: block;
      }
    }
    &--hidden {
      top: -$header-height;
    }

    &--pdp {
      position: fixed;
      background-color: transparent;
    }

    &.mobile-version {
      &:not(.d-none) {
        display: block;

        & + header {
          position: sticky;
          top: 0;
          z-index: 105;
          .nav-mobile.headspace {
            background-color: transparent;
            &.headspace--fixed {
              position: absolute;
            }
          }
        }
      }
    }
  }
}

.submenu-mobile {
  li {
    padding: 4px 10px;
    backface-visibility: hidden;
  }
}

.nav-primary,
.nav-secondary {
  .html-slot-container {
    justify-content: left;
  }
}

.customer-care-layer {
  display: flex;
  justify-content: space-between;
}

.nav-item {
  button,
  a {
    @include breakpoint(large down) {
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      padding: $spacing-small 15px;
      margin-left: 0;
      font-size: $font-size-medium;
    }

    &#button-country-mobile {
      font-size: 12px;
      padding-block: 55px;
      margin-top: 20px;
      position: relative;
      text-transform: uppercase;

      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: $spacing-small;
        width: 60%;
        height: $border-width;
        background-color: $color-primary;
      }
    }
  }
}

// menu left
.nav-primary {
  @include breakpoint(large down) {
    margin-bottom: 40px;
  }

  .nav-item {
    &-title {
      margin: 0 $spacing-small;
    }

    .submenu {
      background-color: transparent;
      min-height: auto;
      left: 0;

      .outer-ul-container#level-1 {
        margin-bottom: 26px;
      }
    }
  }

  .outer-ul-container li.level-2 {
    &:not(:first-child) {
      margin-top: 40px;
    }

    &.special-padding {
      margin-top: 0;
    }
  }
}

.nav-secondary {
  margin: 0 10px 0 0;

  @include breakpoint(medium down) {
    display: flex;
    flex-direction: column;
    margin-right: 0;
    padding-inline: $spacing-small;
    margin-top: 40px;
  }

  .nav-item {
    .submenu {
      right: 0;
      width: 25vw;
    }
  }
}

.nav-primary,
.nav-secondary {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: $spacing-small;
  margin-bottom: $spacing-small;

  ul {
    margin: 0;
  }

  .nav-item {
    margin: 0;
    padding: 0;
    position: relative;

    &.item-hidden {
      // &.item-logo
      display: none;
    }

    &.item-logo {
      margin: 14px auto 75px;
      display: none;
      flex-direction: column;
      justify-content: flex-end;

      @include breakpoint(large down) {
        &:not(.item-hidden) {
          display: block;
        }
      }

      .search-open & {
        display: flex;
      }

      .headspace-fixed & {
        display: flex;
      }

      svg {
        @include breakpoint(large down) {
          height: 17px;
        }
      }
    }

    &.item-mobile-hidden,
    .item-mobile-hidden {
      display: none;
    }

    &.search-nav-item {
      margin-left: 7px;

      & > .nav-item-title {
        &:before {
          left: 20px;
        }
      }
      .item-search {
        display: inline-flex;
        //align-items: center;
        // padding-left: 22px;
        margin-left: 0;

        .title-search {
          transition: opacity 0.2s ease-in-out;
          opacity: 0;
          margin-left: 5px;
        }
      }

      &:hover {
        .item-search {
          .title-search {
            opacity: 1;
            border-bottom: 1px solid #000;
          }
        }
      }
    }

    .submenu {
      display: none;
      flex-direction: column;
      top: 0;
      min-height: auto;
      width: 400px;
      margin: 0;
      padding: 0;
      background-color: $off-white;

      .submenu-title {
        text-transform: uppercase;
        font-size: 16px;
        margin-bottom: 6px;
        padding-left: 22px;
        padding-top: 17px;
      }

      @include breakpoint(large down) {
        &.submenu-mobile {
          padding: 15px 0 0;
          position: relative;
          display: flex;
          width: 100%;
          height: 100%;

          .menu-photo-tile {
            order: 1;
            padding: 0 35px 35px;
            margin-top: auto;

            .caption-box {
              margin: 0;
              max-width: 100%;
            }

            a.clickableItemContainer {
              display: flex;
              flex-direction: column;
              padding: 0;

              &:hover {
                border-bottom: 1px solid transparent;
              }
            }

            .experience-assets-richTexttile {
              padding: 10px 0;
              font-size: $font-size-medium;

              p {
                margin: 0;
                font-size: $font-size-medium;
              }

              a {
                padding-left: 0;
                padding-right: 0;

                &:hover {
                  border-bottom: 1px solid transparent;
                }
              }
            }

            .experience-assets-photoTile + .experience-assets-richTexttile {
              padding-bottom: 0;
            }
          }

          .account-submenu-container {
            display: flex;
            flex-direction: column;
            padding-left: 10px;

            .submenu-box-newsletter {
              order: 2;
              margin-top: 30px;
              padding: 0 25px 10px;
            }

            .account-submenu {
              text-transform: uppercase;
            }
          }
        }

        .outer-ul-container {
          .submenu {
            width: auto;
          }
        }
      }

      li {
        font-size: rem-calc(12);
        letter-spacing: 0.05em;

        button,
        a {
          padding: 3px 15px;

          &:hover {
            border-bottom-color: transparent;
          }

          &:hover:not(.disabled) {
            opacity: 0.6;
          }

          &.disabled {
            cursor: default;
            color: red;
          }
        }

        &.item-gray {
          > a {
            color: $dark-gray;

            &:hover:enabled {
              opacity: 0.7;
              border-bottom-color: $medium-gray;
            }
          }
        }

        .parent-category {
          font-size: 1rem;
          text-transform: uppercase;
          margin-bottom: rem-calc(6);
          padding-left: 25px;

          &.is-visible {
            display: block;
          }
        }
      }

      &-box-newsletter {
        padding: 0 40px 10px;
        width: 100%;
        letter-spacing: 0.05em;

        label {
          padding: 2px 0 0;

          .checkbox-label.terms {
            position: relative;
          }
        }
      }

      .widget-bag {
        padding: 0 25px;
      }
    }

    &-title {
      display: block;
      letter-spacing: 0.08em;
      position: relative;
      text-transform: uppercase;
      z-index: 999;
      font-size: rem-calc(12);
      padding: rem-calc(12) 10px;

      button,
      a {
        line-height: rem-calc(14);
        letter-spacing: 0.08em;

        @include breakpoint(large down) {
          width: 100%;
          padding: $spacing-small 10px;
          text-align: left;
          text-transform: uppercase;
        }

        &:hover {
          border-color: transparent;
        }
      }

      .icon-plus {
        svg {
          margin-right: -9px;
          position: relative;

          @include breakpoint(large down) {
            height: 10px;
          }
        }
      }

      .back-icon-menu {
        display: none;

        &.show-button-back {
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          gap: 5px;
          padding: 5px 20px;

          .link-text {
            text-transform: inherit !important;
            text-decoration: underline;
          }

          & + .account-submenu-title,
          & + .customer-care-title {
            display: none;
          }
        }
      }
    }
  }
}

/// Mini Cart
.minicart {
  &-backdrop {
    background: transparent;
    &.show {
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding-top: 110px;
      z-index: 1005;
      background: rgba($color: $color-primary, $alpha: 0.45);
      overflow-y: auto;
    }
  }

  &.nav-item > .nav-item-title {
    &:before {
      right: 12px !important;
      left: 25px !important;
    }
  }
  .mini-cart-content {
    overflow: auto;
    max-height: 100%;
    a:not(.button) {
      border: none;
    }
    .widget-bag-item .image a {
      border: none;
    }
    &.show {
      padding-top: 54px;
    }

    .js_paypal-content-minicart {
      margin-top: 15px;
    }
  }
  .mini-cart-content:not(.show) {
    max-height: 100%;
    overflow: auto;
    margin-top: 50px;
    padding-top: 46px;
    padding-bottom: 40px;
    .continue-shopping-wrapper {
      display: none;
    }
    &::before {
      content: '';
      width: inherit;
      height: 40px;
      background: $off-white;
      display: block;
      position: fixed;
      right: 0;
      top: 0;
      padding-bottom: 50px;
    }
  }
}

#menu-mini-cart {
  position: relative;

  .amount {
    color: $secondary-color;
    display: block;
    font-size: 9px;
    left: 50%;
    min-width: unset;
    position: absolute;
    top: 24px;
    transform: translateX(-50%) translateY(-50%);
    width: min-content;
  }
}

/////////////////

.floating-logo {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 35px;
  text-align: center;
  text-transform: uppercase;
  font-size: 9px;
  letter-spacing: 0.72px;
  line-height: 13.5px;
  transition: opacity $transition-slow $transition-ease;

  &.static {
    position: absolute;
    top: unset;
  }

  .headspace-hidden &,
  .headspace-fixed & {
    opacity: 0;
  }

  svg {
    width: 100%;
    height: 34px;
    margin-bottom: 6px;
  }

  @include breakpoint(small only) {
    padding: 0 15px;

    svg {
      width: 220px;
    }
  }
}

.static-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 0 35px;
  margin: 0 auto 30px;
  font-size: 9px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.72px;
  line-height: 13.5px;

  &.padded {
    margin-top: 50px;
  }

  svg {
    width: 100%;
    height: 34px;
    margin-bottom: 6px;
  }

  svg,
  div {
    z-index: 3;
  }

  @include breakpoint(small only) {
    padding: 0 15px;

    svg {
      display: none;
    }
  }
}

.home-page {
  .static-logo {
    @include breakpoint(small only) {
      svg {
        display: block;
      }
    }
  }
}

.mini-cart-spinner {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.spinner {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: $border-width solid $color-primary;
  border-top-color: $off-white;
  animation: spinner 600ms linear infinite;
}

.header-banner-visible {
  .nav-primary,
  .nav-secondary {
    .nav-item {
      &.level-1 {
        & > .nav-item-title {
          & > .btn-open-submenu {
            text-transform: uppercase;
          }
        }
      }

      .mini-cart-content:not(.show) {
        margin-top: 96px;
        &::before {
          top: 46px;
        }
      }
    }
  }
}

.cart-recommendations {
  .container-carousel {
    row-gap: 10px;
  }

  .product-grid .product {
    margin: 0 $spacing-small;
    padding: 0;
    width: calc(50% - 10px);
  }
}

#product-shows {
  .js-jils-header-with-modal {
    position: fixed;
    left: 0;
    right: 0;
  }
}

.menu-header-banner {
  position: relative;
  padding-block: 40px;
  margin: 0 35px 20px 35px;

  li {
    padding: 0;

    a {
      padding: 10px 0;
    }
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 60%;
    height: 1px;
    background-color: #111;
  }

  & > .viewAllList {
    a.viewAllLink {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      text-decoration: underline !important;
      color: #000 !important;
      padding-left: 0 !important;

      &:hover {
        text-decoration: underline !important;
        color: #000 !important;
      }
    }
  }
}

// Animations
@for $i from 1 through 20 {
  .submenu-mobile {
    li {
      &:nth-child(#{$i}) {
        animation-delay: $i * math.div(1s, 18);
      }
    }
  }
}
@for $i from 1 through 6 {
  .nav-primary {
    li {
      &:nth-child(#{$i}) {
        animation-delay: $i * math.div(1s, 16);
      }
    }
  }
}
@for $i from 1 through 6 {
  .nav-secondary {
    li {
      &:nth-child(#{$i}) {
        animation-delay: ($i + 5) * math.div(1s, 18);
      }
    }
  }
}

@keyframes mobile_menu_reveal {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.nav-desktop .nav-secondary .nav-item .submenu {
  .newsletter-box-label,
  .newsletter-input-wrapper {
    text-transform: none;
    * {
      text-transform: none;
    }

    .newsletter-cancel,
    .newsletter-next-btn {
      display: none;
    }
  }
}
.nav-desktop .nav-secondary .nav-item {
  .cart-recommendations {
    .product-tile .pdp-link {
      a {
        white-space: unset;
        font-size: 11px;
      }
    }
  }
}

.home-page {
  .nav-mobile .logo-mobile {
    display: none;
    text-align: center;
  }

  .nav-mobile.headspace--fixed .logo-mobile {
    display: block;
    text-align: center;
  }


  .search-open .nav-mobile .logo-mobile {
    display: block;
    text-align: center;
  }
}
.nav-mobile.headspace--fixed {
  position: fixed;
  background-color: #fbfbf6;

  .logo-mobile {
    text-align: center;
  }
}

.menu-mobile-visible.home-page,
.menu-mobile-visible {
  .nav-mobile .logo-mobile {
    display: block;
    text-align: center;
  }
}

.search-open .nav-mobile .logo-mobile {
  display: block;
  text-align: center;
}

.nav-mobile.headspace--fixed .logo-mobile {
  display: block;
  justify-self: center;
}

.home-page .nav-primary-center .nav-item-center.item-logo {
  display: none;
}

.headspace-fixed .nav-primary-center .nav-item-center.item-logo {
  display: flex;
}
