.account-content-wrapper {
  * {
    font-size: 14px;
    @include breakpoint(large up) {
      font-size: 12px;
    }
  }
  .d-none {
    display: none;
  }
  .leading-lines {
    margin: 0;
    padding: 13px 0;
    p {
      margin: 0;
    }
  }
  .cart-sidebar-section {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    .grand-total {
      padding: 13px 0;
    }
  }

  .title-information {
    font-family: $body-font-family;
    font-weight: 500;
    font-size: rem-calc(12);
    padding-top: 27px;
  }

  .summary-details {
    font-family: $body-font-family;
    font-weight: 500;
    // font-size: rem-calc(10);
    color: $link-color-gray;
    padding-top: rem-calc(22);

    .total-price {
      display: none;
    }
  }
}

.order-detail-page {
  padding-top: rem-calc(25);
  .cart-item-grid {
    max-width: 100%;
    column-gap: rem-calc(18);

    .detail-attribute {
      font-weight: 500;
      font-size: rem-calc(14);
      line-height: rem-calc(17);
      display: flex;
      margin-bottom: 2px;
      padding-bottom: 10px;
    }
    &.legacy-order {
      .product-attributes {
        grid-column: 1/3;
      }
    }
  }
  .single-order-detail {
    padding-bottom: rem-calc(25);
    + .single-order-detail {
      padding-top: rem-calc(25);
    }
  }

  .jil-button + .jil-button {
    margin-top: rem-calc(10);
  }

  .order-summary-phone,
  .order-summary-email {
    font-family: $body-font-family;
    font-weight: 500;
    font-size: rem-calc(10);
    color: $link-color-gray;
  }

  .preorder-badge {
    display: none;
  }
}

.ordered-items-section {
  .title-information {
    text-transform: none;
    color: #585856;
    // font-size: rem-calc(14);
  }

  .shipping-section {
    & + .order-details-list-box {
      margin-top: rem-calc(26);
    }
  }

  .summary-details {
    padding-top: rem-calc(10);
  }
  .eta-content {
    padding-top: rem-calc(10);
    font-weight: 500;
    font-size: rem-calc(12);
    color: #969693;

    @media (max-width: 768px) {
      font-size: rem-calc(14);
    }
  }
}
