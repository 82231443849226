.main--checkout {
  margin: 0 auto;
  max-width: 330px;
  padding: 30px 0;

  .checkout-container {
    font-size: 14px;
    @include breakpoint(large up) {
      font-size: 12px;
    }
    .checkoutpage {
      .-jils-checkout-title {
        margin-bottom: 20px;
      }
      &.payment {
        .order-summary-box-content {
          .accordion {
            margin: 55px 0;
            @include breakpoint(large up) {
              margin: 35px 0;
            }
          }
        }
      }
      &.review {
        .order-summary-box-container,
        .details-box {
          margin-top: 55px;
          @include breakpoint(large up) {
            margin-top: 35px;
          }
        }
      }
      &.shipping {
        .country-wrapper {
          &.select-disabled {
            .col-1 {
              display: none;
            }
          }
        }
      }
    }
  }

  .adyen-checkout__input-wrapper {
    .adyen-checkout__input:active,
    .adyen-checkout__input:active:hover,
    .adyen-checkout__input:focus,
    .adyen-checkout__input--focus,
    .adyen-checkout__input--focus:focus,
    .adyen-checkout__input:focus:hover {
      border: none;
      box-shadow: 0 0 0 1px $medium-gray;
    }
  }

  .adyen-checkout__field {
    .adyen-checkout__label--focused {
      .adyen-checkout__label__text {
        color: $medium-gray;
      }
    }
  }

  p {
    letter-spacing: 0;
    margin: 0;
  }

  div:empty,
  p:empty,
  .default-steps-container,
  .accordion-trigger::after {
    display: none;
  }

  .d-none {
    display: none !important; // JILS-435 JILS-449
  }

  .globalerrors {
    letter-spacing: -0.01em;
  }

  .invalid-feedback {
    color: #d63b3a;
  }

  .shipping-row {
    display: flex;
    justify-content: space-between;
    margin: 45px 30px;
    position: relative;

    &::before {
      background-color: #000000;
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      top: 15px;
      width: 100%;
      z-index: -1;
    }
  }

  .stepper-step {
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    width: 30px;

    .status-circle {
      align-items: center;
      background: #e1e1dc;
      border-radius: 50%;
      display: flex;
      height: 30px;
      justify-content: center;
      width: 30px;

      &-number {
        color: rgba(0, 0, 0, 0.15);
        font-size: 12px;
        margin: 0;
      }
    }

    .status-description {
      p {
        color: #585856;
      }
    }

    &.active,
    &.completed {
      .status-description button:not(:disabled) p {
        color: $link-color-gray;
      }
    }

    &.active {
      .status-circle {
        background: #fbfbf6;
        border: 1px solid #000000;

        &-number {
          color: #000000;
        }
      }

      .status-description {
        p {
          color: #585856;
          text-decoration: underline;
        }
      }
    }

    &.completed {
      .status-circle {
        background: #000000;

        &-number {
          color: #ffffff;
        }
      }
    }

    &:not(.active) {
      &:not(.completed) {
        .status-description {
          p {
            color: #bfbfbb;
          }
        }
      }
    }
  }

  .status-description {
    button {
      padding: 0;
      border: 0;
      opacity: 1 !important;
    }

    p {
      font-size: 12px;
      text-transform: none;
    }
  }

  h2 {
    color: $link-color-gray;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.065em;
    line-height: 16.8px;
    margin-bottom: 20px;
    @include breakpoint(large up) {
      font-size: 12px;
    }
  }

  .country-wrapper {
    margin-top: -8px;
  }

  .jils-form-fieldset {
    label {
      display: none;
    }

    [type='checkbox'] + label {
      display: block;
    }

    .row {
      display: flex;
      flex-direction: column;
      row-gap: 28px;

      .form-select > .row {
        display: block;
      }
    }

    .phone-row-checkout {
      flex-direction: row;
      column-gap: 20px;
    }

    .phone-dash {
      display: none;
    }
  }

  .jils-form-input {
    input {
      padding: 0 0 9px;
    }

    &.-invalid {
      input {
        @include error-message;
        background-position: right 2px !important;
        background-size: 13px 13px !important;
        border-bottom-color: #d63b3a;

        &::placeholder {
          color: #d63b3a;
        }
      }
    }
  }

  .invalid-feedback:not(.globalerrors) {
    background-image: none;
    margin: 30px 0 0;
    margin-top: 0.58333rem;
    padding: 0;
    color: #d63b3a;
  }

  input,
  select {
    font-size: 12px;
    line-height: 14.4px;
  }

  select {
    appearance: none;
    background: #fbfbf6;
  }

  .form-select {
    position: relative;
    .col-1 {
      position: absolute;
      top: 0;
      right: 0;
      left: auto;
      z-index: 2;
      $sprite: map-get($sprites, 'arrow-down');
      background: url($sprite) center no-repeat;
      display: block;
      height: 16px;
      width: 10px;
      pointer-events: none;
      transform: translateY(50%);
    }
  }

  .addresses {
    margin-bottom: 65px;
  }

  .address_form {
    .accordion-title {
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    .address_form-content {
      > div {
        > .row:not(.last-row-form) {
          margin-bottom: 28px;
        }
      }
    }
  }

  #shipping-data-accordion-content {
    padding: 8px 0 0;
    display: block;
    opacity: 1;
    max-height: none;

    > .form-group {
      margin-top: 28px;
    }

    > .row {
      margin-top: 20px;
    }
  }

  .checkout-shipping__options {
    display: flex;
    flex-direction: column;
    margin-top: 45px;
    row-gap: 5px;

    &__option {
      display: flex;
      flex-direction: column;
      padding: 0 15px 0 0;

      &[class*='-Timeslot'] {
        row-gap: 10px;

        .delivery-desc {
          font-size: 10px;
        }
      }
    }
  }

  .checkout-shipping__address {
    .voucher-wrapper {
      display: none;
    }
  }

  .delivery-title {
    color: $link-color-gray;
    // font-size: 14px;
    letter-spacing: 0.045em;
    line-height: 17px;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  .form-radio-field {
    position: relative;
  }

  [type='checkbox'],
  [type='radio'] {
    accent-color: #000000;
  }

  .input-radio {
    left: 13px;
    position: absolute;
    top: 13px;
    display: none;

    + * {
      border: 1px solid #c9c9c5;
      padding: 13px 13px 7px;
      display: block;
    }

    &:checked + * {
      border-color: #000000;
    }

    &[value*='-Timeslot'] {
      top: 22px;

      + * {
        padding-bottom: 32px;
        padding-top: 22px;
      }
    }
  }

  .name-and-cost-col,
  .delivery-desc {
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
  }

  .name-and-cost-col {
    display: flex;
    justify-content: space-between;
    margin-right: -15px;
  }

  .delivery-desc {
    color: $link-color-gray;
    margin: 5px 0 0;
  }

  .timeslot-selector {
    .col-1 {
      display: none;
    }
  }

  .gift-option-container {
    margin: 55px 0;
    @include breakpoint(large up) {
      margin: 35px 0;
    }
  }

  .gift-option-header {
    display: none;
  }

  .gift-input-wrapper {
    .desc-wrapper {
      display: none;
      margin: 20px 0 10px;
    }

    &-open {
      .desc-wrapper {
        display: block;
      }
    }

    .gift-desc {
      color: $dark-gray;
      letter-spacing: -0.015em;
      line-height: 1.5;
    }
  }

  .gift-input-container {
    display: flex;

    label {
      flex: 1;
      // font-size: 14px;
      letter-spacing: -0.02em;
      line-height: 17px;
    }
  }

  .gift-input {
    appearance: none;
    border: 0;

    &::after {
      $sprite: map-get($sprites, 'arrow-down');
      background: url($sprite) center no-repeat;
      display: block;
      content: '';
      height: 14px;
      transition: 0.3s;
      width: 14px;
    }

    &:checked {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .gift-option-fields {
    margin: 0;

    .jils-form-fieldset {
      row-gap: 11px;
    }

    input,
    textarea {
      letter-spacing: -0.015em;
      line-height: 12px;
    }

    textarea {
      height: 50px;
      margin-top: 5px;
      padding: 7px;
      resize: none;
    }

    .gift-input-field-hidden {
      display: none;
    }
  }

  .navigations-buttons,
  .button-and-terms-conditions {
    margin: 30px 0;
  }

  .proceed-to-payment button,
  .proceed-to-review button,
  .place-order button,
  .proceed button {
    font-size: 14px;
    font-weight: 400;
    height: 49px;
    line-height: 17px;
    width: 100%;
    @include breakpoint(large up) {
      font-size: 12px;
    }
  }

  .proceed {
    &.mobile-sticky-cta {
      display: none;
    }

    &:not(.mobile-sticky-cta) {
      margin-bottom: 30px;
    }
  }

  @include breakpoint(large down) {
    .proceed {
      &.mobile-sticky-cta {
        position: sticky;
        bottom: 0;
        padding: 30px 0;
        background-color: #fbfbf6;
        display: block;
      }
    }
  }

  .order-summary-box-container {
    @include breakpoint(large down) {
      margin-top: 20px;
    }
  }

  .order-summary-title {
    display: none;
  }

  .order-summary-box-review {
    .accordion-trigger {
      border: 1px solid #c9c9c5;
      padding: 7px 7px 7px 13px;
      &.product-list-accordion {
        height: 55px;
        padding: 7px;
      }
    }

    &.is-active .accordion-trigger {
      border-color: transparent;
    }

    .order-number-wrapper {
      border: 1px solid;
      border-radius: 50%;
      display: inline-block;
      font-size: 9px;
      height: 13px;
      line-height: 13px;
      margin-left: 10px;
      width: 13px;
    }

    .mm-icon {
      display: block;
      height: 10px;
      width: 10px;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    &.is-active .circled-plus,
    &:not(.is-active) .circled-minus {
      display: none;
    }
  }

  .bag-review-container {
    // background: #f9f7ef;
    margin-top: 7px;
    // padding: 5px 15px;
    // padding: 5px 15px 11px; // JILS-449
    padding: 5px 8px 11px; // OTB REVIEW SESSION
    position: relative;

    > div:not([class]),
    > .bag-review-title {
      margin-bottom: 22px;
    }

    > div:not([class]) {
      column-gap: 5px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: left;
    }
  }

  .bag-review-store {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;

    &::before {
      content: '- ';
    }

    i {
      display: none !important;
    }
  }

  .bag-review-title {
    color: #000000;
    letter-spacing: 0;
    margin-bottom: 0;
    text-transform: none;
  }

  .bag-review {
    display: flex;
    flex-direction: column;
    row-gap: 22px;
  }

  .product-info {
    column-gap: 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 0;

    &-img {
      flex: 94px 0 0;
      height: 143px;
    }
  }

  .product-image {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  .bag-product {
    display: flex;
    flex-direction: column;
    position: relative;
    row-gap: 13px; // JILS-449

    p {
      letter-spacing: -0.04em;
    }

    &__content {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      @include breakpoint(mobile only) {
        max-width: 10rem;
      }

      p {
        letter-spacing: -0.03em;
      }
    }

    &__details {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
    }

    &__name {
      margin-right: 30px;

      &-product {
        display: -webkit-box;
        overflow: hidden;
        text-transform: uppercase;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    }

    .row-details-value {
      display: flex;
      gap: 5px;
    }

    &__details-quantity {
      display: none;
    }

    &__price {
      position: absolute;
      right: -6px;
      top: 0;
    }
  }

  .bag-product__shipping-text,
  .bag-product_not-returnable-text {
    display: block !important;
    color: #969693;
    font-size: 12px;
    line-height: 14px;

    > span {
      display: block;
    }
  }

  // JILS-449
  .available-days-additional-text {
    display: none !important;
  }

  .edit-bag-wrapper-desktop {
    display: none;
  }

  .edit-bag-wrapper {
    margin-top: auto;

    a {
      // JILS-449
      color: $dark-gray;
      letter-spacing: 0.04em;
      &:hover {
        border-color: #000;
        color: #000;
      }
    }
  }

  .checkout-shipping .navigations-buttons,
  .proceed-to-review .navigations-buttons {
    display: none;
  }

  .voucher-wrapper {
    margin: 35px 0;
    @include breakpoint(large up) {
      margin: 35px 0;
    }

    .accordion-mini__header::after {
      height: 14px;
      width: 14px;
    }

    &.discounts {
      .voucher-input-container,
      .accordion-mini__header {
        display: none;
      }

      .accordion-mini__panel {
        height: auto !important;
        visibility: visible !important;
      }
    }
  }

  .accordion-mini__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    letter-spacing: -0.02em;
    width: 100%;

    &::after {
      $sprite: map-get($sprites, 'arrow-down');
      background: url($sprite) center no-repeat;
      content: '';
      display: block;
      height: 18px;
      transition: 0.3s;
      width: 18px;
    }

    &[aria-expanded='false'] {
      + .accordion-mini__panel {
        height: 0;
        visibility: hidden;
      }
    }

    &[aria-expanded='true'] {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .promocode-title {
    display: none;
  }

  .voucher-input-container {
    column-gap: 10px;
    margin-top: 17px;
  }

  .coupon-code-field {
    padding: 9px 0;
  }

  .coupon-error-message {
    display: block;
    letter-spacing: -0.02em;
    margin-top: 10px !important;
  }

  .promo-code-submit button {
    border: 1px solid rgba(0, 0, 0, 0.4);
    letter-spacing: 0.07em;
    padding: 11px 20px;
    text-transform: uppercase;
  }

  .coupon-price-adjustment {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .mm-badge {
      display: none;
    }
  }

  .coupon-code {
    letter-spacing: -0.02em;
  }

  .remove-coupon {
    color: #969693;
    margin-right: 106px;
    text-decoration: underline;
  }

  .details-box,
  .estimated-total {
    p,
    .items__label {
      letter-spacing: -0.03em;
      line-height: 17px;
      padding: 15px 0;
    }
  }

  .items__subtotal-cost {
    display: flex;
    column-gap: 5px;
  }

  .previous-price {
    color: rgba(0, 0, 0, 0.4);
  }

  .details-box {
    border-top: 1px solid #c8c8c4;
    margin-top: 48px;
    padding-top: 14px;
  }

  .subtotal-label {
    display: block;

    + .mm-badge {
      display: none;
    }
  }

  .items__subtotal-description {
    .items__label {
      display: flex;
      align-items: center;
      .tooltip-tail,
      .tooltip-tail-2 {
        display: block;
      }
    }
  }

  .estimated-total {
    border-bottom: 1px solid #c8c8c4;
    margin-bottom: 29px;
    padding-bottom: 15px;
  }

  .items__subtotal > div,
  .items__subtotal-row {
    display: flex;
    justify-content: space-between;
  }

  .wrapper-checkout-recap-bottom {
    margin-top: 3px;
    padding: 0;

    .accordion-title {
      padding: 17px 12px 16px 8px;

      &-icon {
        margin: 0;
      }
    }

    svg {
      width: 14px;
    }
  }

  .checkout-payment-title {
    display: none;
  }

  .billingAddressForm-checkbox {
    margin-top: 25px;
  }

  .checkbox__field {
    align-items: center;
    column-gap: 10px;
    display: flex;

    label {
      font-size: 12px;
    }
  }

  :not(.gift-input)[type='checkbox'] {
    accent-color: #000000;
    appearance: initial;
    border: 1px solid #000000;
    display: flex;
    height: 15px;
    opacity: 1;
    width: 15px;

    &::before,
    &::after {
      display: none;
    }

    &:checked {
      &::after {
        content: '';
        background: #000000;
        display: block;
        height: 9px;
        margin: auto;
        width: 9px;
      }
    }
  }

  .addresses-label {
    display: block;
    margin: 20px 0 10px;
    text-transform: uppercase;
  }

  .billingAddressForm {
    margin-top: 30px;
  }

  #billing-address-accordion-control {
    display: none;
  }

  .add-to-address {
    margin-top: 25px;
  }

  .paymentmethods {
    margin-top: 25px;

    .input-radio + * {
      padding: 13px 13px 0;
    }
  }

  .radio {
    position: relative;
  }

  .radio-section-container {
    display: flex;
    flex-direction: column;
    // padding: 0 30px;
    padding: 0 0 0 30px;
    row-gap: 20px;
  }

  .container-radio-payment {
    display: flex;
    flex-direction: column;
  }

  .radio__icon {
    margin-bottom: 13px;

    &.credit-card {
      margin-bottom: 15px;
    }
  }

  .single-payment-box {
    .col-3 {
      display: none;
    }

    &-card-type {
      column-gap: 5px;
      display: flex;
      flex-wrap: wrap;
    }
  }

  // JILS-435
  .store-payment-by-default {
    display: none;
  }

  .payment-method__info {
    color: #585856;
    font-size: 10px;
    line-height: 12px;
    padding: 2px 0 5px;
  }

  .adyen-checkout {
    &__label__text {
      font-size: 12px;
      padding: 0;
    }

    &__input {
      background: transparent;
      border-bottom: 1px solid #c9c9c5;
      border-left: 0;
      border-radius: 0;
      border-right: 0;
      border-top: 0;
      height: 32px;
      padding: 0;

      iframe {
        transform: scale(0.8);
        transform-origin: 0 100%;
      }

      img,
      + .adyen-checkout__card__cvc__hint__wrapper {
        display: none;
      }
    }

    &__checkbox {
      display: flex;
      align-items: center;

      &__label {
        font-size: 12px;

        &::before,
        &::after {
          display: none;
        }
      }
    }

    &__store-details,
    &__card__holderName:last-child {
      margin-bottom: 25px;
    }
  }

  #addressbookid > div {
    margin: 0 -13px 0 -43px;
    padding: 7px 43px;
    border: 1px solid #eeeee8;
  }

  .radio-item-checked {
    background: #eeeee8;
  }

  .sub-radio__element {
    align-items: center;
    column-gap: 10px;
    display: flex;

    &-card-details {
      column-gap: 3px;
      display: flex;
    }

    p {
      color: #585856;
      font-size: 11px;
    }

    div:not(:last-child) p::after {
      content: ',';
    }

    // JILS-344
    &-card-details-holder p {
      max-width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .option-card {
    align-items: center;
    display: flex;
    column-gap: 10px;
    margin: 5px 0;

    &::before {
      $sprite: map-get($sprites, 'arrow-left');
      background: url($sprite) center no-repeat;
      content: '';
      display: block;
      height: 11px;
      opacity: 0.3;
      width: 11px;
    }

    button {
      border: 0;
      color: #757575;
      font-size: 12px;
      letter-spacing: 0;
      padding: 0;
      text-align: left;
      text-transform: none;
    }
  }

  #addressbookid {
    .option-card {
      margin: 0;

      &::before {
        $sprite: map-get($sprites, 'pay-plus');
        background-image: url($sprite);
        height: 25px;
        opacity: 1;
        width: 35px;
      }

      button {
        color: #585856;
      }
    }
  }

  .cvv-expiration-fields {
    margin: 15px 35px 0 0;
  }

  .credit-card-fields-box + .cvv-expiration-fields {
    margin: 35px 0 25px;
  }

  #reviewStep {
    .step__header {
      display: none;
    }

    .checkout-review,
    .row-review {
      display: flex;
      flex-direction: column;
      row-gap: 23px;
    }

    h3 {
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      margin-bottom: 23px;
      margin-top: 0;
    }

    p {
      color: #585856;
      letter-spacing: -0.02em;
      line-height: 1.5;
    }
  }

  .checkout-review-title {
    margin-bottom: 7px;
  }

  .gift-summary-title,
  .shipping-method-title,
  .payment-method-title {
    margin-top: 18px;
  }

  .row-information,
  .review-summary-box {
    display: none;
  }

  #paypal-button-container {
    margin-top: 30px;
  }

  .paypal-button-custom-preference-layout,
  & + footer {
    display: none;
  }

  #vertex-modal {
    .modal-content,
    .validation-result-wrapper,
    .address-details {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    .vertex-modal-content {
      padding: 0;
    }
  }
}

.page-designer-checkout {
  @include breakpoint(large down) {
    display: none;
  }
}
