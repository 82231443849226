.widget-sizeguide {
  
    @include breakpoint(large up) {
      margin-top: 0;
      min-height: 480px;
    }

    .sizeguide-tabs {
        ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;

            @include breakpoint(small only) {
                align-items: center;
            }

            @include breakpoint(medium) {
                flex-direction: row;
            }
        }

        li {
            padding: 6px 0;
            cursor: pointer;

            @include breakpoint(small only) {
                font-size: 16px;
                padding: 9px 0;
                letter-spacing: 0.96px;
                line-height: 16.8px;
            }

            @include breakpoint(medium) {
                padding: 10px 30px 10px 0;
            }

            a {
                &:hover,
                &:focus {
                    border-bottom: 0;
                }

                @include breakpoint(small only) {
                    font-size: 16px;
                }

                span {
                    font-size: 12px;
                    padding-bottom: 2px;
                    text-transform: uppercase;
                    letter-spacing: 0.8px;

                    @include breakpoint(medium) {
                        font-size: 10px;
                    }
                }
            }

            @include breakpoint(medium) {
                padding-top: 9px;
                border-top: none;
            }

            &.is-active {
                cursor: default;
                z-index: 1;

                span {
                    border-bottom: 1px solid #000;
                }
            }
        }
    }

    .sizeguide-content {
        display: none;
        padding-top: 45px;

        @include breakpoint(medium) {
            padding-top: 30px;
        }

        &.is-active {
            display: block;
        }

        ul {
            line-height: 1.2;
            padding-left: 16px;

            @include breakpoint(small only) {
                line-height: 1.5;
            }
        }

        li {
            position: relative;

            @include breakpoint(small only) {
                padding-bottom: 0;
                padding-top: 0;
            }

            &::before {
                content: "\2013";
                position: absolute;
                left: -16px;
                top: 0;
            }

            @include breakpoint(small only) {
                font-size: 14px;
            }
        }
    }

    .sizeguide-measurement-switcher {
        padding-top: 30px;
        color: rgba(0, 0, 0, 0.6);

        @include breakpoint(medium) {
            text-align: right;
        }

        @include breakpoint(small only) {
            font-size: 14px;
        }

        a {
            color: rgba(0, 0, 0, 0.6);
            text-decoration: none;

            @include breakpoint(small only) {
                font-size: 16px;
            }

            &:hover,
            &.is-on {
                border-bottom: 1px solid rgba(0, 0, 0, 0.4);
            }
        }
    }

    .item-table {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        padding-top: 27px;
        margin-top: 30px;
        overflow: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        @include breakpoint(small only) {
            margin-left: -30px;
            margin-right: -30px;
            margin-top: 60px;
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 60px;
            padding-bottom: 30px;
        }

        table {
            width: 700px;

            // @include breakpoint(medium) {
            //     width: 100%;
            // }
            min-width: max-content;

            th {
                font-size: 12px;
                line-height: 2.2em;
                font-weight: 400;
                text-align: left;
                height: 28px;

                @include breakpoint(small only) {
                    font-size: 14px;
                }
            }

            td {
                font-size: 12px;
                line-height: 2.2em;
                padding-right: 23px;
                text-align: left;
                height: 28px;
                display: table-cell;

                @include breakpoint(small only) {
                    font-size: 14px;
                }

                .measurement-values-inches {
                    display: none;
                }

                .measurement-values-centimetres {
                    display: inline;
                }
            }
        }

        &.is-inches {
            .measurement-values-centimetres {
                display: none;
            }

            .measurement-values-inches {
                display: inline;
            }
        }

        &.is-centimetres {
            .measurement-values-centimetres {
                display: inline;
            }

            .measurement-values-inches {
                display: none;
            }
        }

        &.-no-border {
          border: none;
          margin-top: 0;
          padding-top: 17px;
        }
    }
}

// #sizechart-modal {
//     .modal-content {
//         max-width: 700px;
//     }
// }
