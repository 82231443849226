$include-fonts: false;

@mixin previousPrice {
  color: $medium-gray;
}

#cart {
  // width: 320px;
  font-size: 14px;
  margin: 0 30px;
  // margin-top: 140px;

  // @include breakpoint(small only) {
  //   margin-top: 150px;
  // }

  @include breakpoint(large up) {
    width: 329px;
    margin: 0 auto;
  }

  .strike-through {
    @include previousPrice();
  }

  .summary-row {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
    font-size: 12px;
    line-height: 1.3em;
    letter-spacing: 0.02em;
    font-weight: 500;

    @include breakpoint(small only) {
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 0.02em;
    }

    > div {
      flex-grow: 2;
    }

    select {
      background: transparent;
      border: none;
      padding: 0;
      text-align: right;
      display: inline;
      width: auto;
      height: auto;

      @include breakpoint(small only) {
        font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0.02em;
      }
    }

    &.summary-total {
      margin-bottom: 60px;
    }
  }

  .summary-divider {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }

  .promo-code-submit {
    padding-left: 5px;
  }

  .remove-product {
    opacity: 0.5;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);

    @include breakpoint(small only) {
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 0.02em;
    }

    &:hover {
      color: $featured;
      border-bottom: 1px solid $featured;
      opacity: 1;
    }
  }

  .remove-promo {
    opacity: 0.5;
    margin-left: 10px;

    @include breakpoint(small only) {
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 0.02em;
    }

    &:hover {
      opacity: 1;
    }
  }

  // .empty-cart {
  //   margin-top: 200px;
  // }

  #toggle-promo-form {
    cursor: pointer;

    .down {
      display: none;
    }

    &.active {
      .down {
        display: inline;
      }

      .right {
        display: none;
      }
    }
  }

  #promo-form {
    display: none;

    &.visible {
      display: block;
    }

    .coupon-error {
      margin: 10px 0;

      .coupon-missing-error,
      .coupon-error-message {
        background-position: 0 0 !important;
        padding-bottom: 2px;
      }
    }
  }

  /* JILS-342
  .coupon-price-adjustment {
    margin: 15px 0;
    // border: 1px solid #ccc;
    border: none;
    display: flex;
    justify-content: space-between;
    // padding: 15px 15px 13px;
    padding: 0;

    .coupon-code {
      display: flex;
      // justify-content: space-between;

      span {
        &:first-child {
          display: flex;
          margin-left: 4px;
          order: 2;
        }
      }
      .coupon-semicolon {
        display: none;
        opacity: 0;
      }

      .coupon-code-value {
        display: flex;
        align-items: center;

        @include breakpoint(small only) {
          font-size: 14px;
          line-height: 1.5em;
          letter-spacing: 0.02em;
        }
      }
    }
  }
  */

  .hide-order-discount,
  .hide-shipping-discount {
    display: none;
  }

  .promo-code-form {
    @include breakpoint(small down) {
      #couponCode {
        line-height: 1.9;
        font-size: 14px;
      }
    }
  }

  .coupon-message {
    &.error {
      color: $error-color;
      margin-top: rem-calc(7);
      margin-bottom: 0;
      font-size: rem-calc(11);
      font-weight: 500;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCAzMiAzMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiNjMDAiPiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNiwzIEMyMy4yLDMgMjksOC44IDI5LDE2IEMyOSwyMy4yIDIzLjIsMjkgMTYsMjkgQzguOCwyOSAzLDIzLjIgMywxNiBDMyw4LjggOC44LDMgMTYsMyBMMTYsMyBaIE0xNiwwIEM3LjIsMCAwLDcuMiAwLDE2IEMwLDI0LjggNy4yLDMyIDE2LDMyIEMyNC44LDMyIDMyLDI0LjggMzIsMTYgQzMyLDcuMiAyNC44LDAgMTYsMCBMMTYsMCBaIiBpZD0iU2hhcGUiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPiAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjE0LjUiIHk9IjciIHdpZHRoPSIzIiBoZWlnaHQ9IjEwIj48L3JlY3Q+ICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgeD0iMTQuNSIgeT0iMjEiIHdpZHRoPSIzIiBoZWlnaHQ9IjQiPjwvcmVjdD4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
      background-repeat: no-repeat;
      background-size: 11px 11px;
      background-position: 0 2px;
      padding: 0 0 0 20px;
    }
  }

  .cart-error-messaging {
    &.unavailable-product-error {
      margin-bottom: 70px;
    }
  }

  // JILS-448 JILS-456
  // .list-products > [class*='-container']:not(:first-of-type) {
  .list-products > .js-pickup-container:not(.d-none) + .js-ship-container {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding-top: 27px;
  }
}

.product-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  &.unavailable {
    opacity: 0.5;
  }

  .item-image {
    padding-right: 15px;

    .product-image {
      max-width: 61px;
    }
  }

  .line-item-name {
    font-size: 10px;
    letter-spacing: 0.08em;
    line-height: 1.4em;
    padding: 0 10px 10px 0;
    font-weight: 400;
    text-transform: uppercase;

    @include breakpoint(small only) {
      font-size: 12px;
      line-height: 1.5em;
      letter-spacing: 0.02em;
    }
  }

  .item-attributes {
    span {
      font-size: 12px;
      line-height: 1.3em;
      letter-spacing: 0.02em;
      font-weight: 400;

      @include breakpoint(small only) {
        font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0.02em;
      }
    }
  }

  .product-info {
    display: flex;
    flex-grow: 2;

    .item-caption {
      flex-grow: 2;
    }

    .item-price {
      text-align: right;

      .strike-through,
      .pricing {
        white-space: nowrap;
      }

      .strike-through {
        color: map_get($jil-palette, 'medium-gray');
      }

      @include breakpoint(small only) {
        font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0.02em;
      }
    }
  }

  .line-item-tool {
    padding-top: 10px;
    display: flex;
    align-items: center;

    @include breakpoint(small only) {
      display: block;
      font-size: 12px;
      line-height: 1.5em;
      letter-spacing: 0.02em;
    }
  }

  .quantity-form {
    display: flex;
    align-items: center;
    padding-right: 10px;

    @include breakpoint(small only) {
      display: block;
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 0.02em;
    }

    .quantity-text {
      min-width: 10px;
      text-align: center;
    }

    button {
      padding: 0 3px;

      @include breakpoint(small only) {
        padding: 10px;
      }

      &:first-child {
        @include breakpoint(small only) {
          margin-left: -10px;
        }
      }

      svg {
        @include breakpoint(small only) {
          transform: scale(1.2);
        }
      }
    }

    .quantity-increase-buttons {
      position: relative;
    }

    .loading-quantity-spinner {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fbfbf6;
      top: 50%;
      padding: 4px;
      @include breakpoint(small only) {
        left: 10%;
        padding-left: 14px;
        padding-right: 14px;
      }
    }
  }
}

// new
.empty-cart-wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    font-weight: 500;
    font-size: rem-calc(14);
    line-height: rem-calc(17);
  }
}

#custom-coupon-error-message {
  display: block;
  &.-hidden {
    display: none;
  }
}

#confirmation-bopis-modal {
  .modal-confirmation-actions {
    .jil-button {
      background-color: rgba(0,0,0,0);
      background-color: transparent !important;
    }
  }
}
