.jils-link {
  border: none;
  color: $primary-color;
  text-decoration-line: underline;
  &:hover {
    border-bottom: none;
    text-decoration: underline;
  }
}

.jils-form-fieldset {
  border: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  gap: 1rem;
  width: 100%;

  /* modifier */
  $form-check-icon-md: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiAxMiI+PHBhdGggZD0ibTguOTY5IDMuOTY5LTQgNEwzIDYiIHN0cm9rZT0iI0Y3RjdGMiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjwvc3ZnPg==');

  .jils-form-input {
    position: relative;
    &.-floating-label {
      input {
        height: 49px;
        &::placeholder {
          opacity: 0;
        }

        /* states */
        // &:focus ~ label {
        //   color: $primary-color;
        // }
        &:focus ~ label,
        &:not(:placeholder-shown) ~ label,
        &.valid-input ~ label {
          top: 0;
          // @include type('global-label-productive-3');
        }
      }

      label {
        position: absolute;
        top: 0;
        left: 1rem;
        z-index: 2;
        padding: 0 0.1875rem;
        color: $primary-color;
        pointer-events: none;
        background-color: $secondary-color;
        transition-timing-function: ease-out;
        transition-duration: 75ms;
        transition-property: font-size top;
        transform: translate3d(0, -50%, 0);
        // @include type('global-control-productive-3');
      }
    }

    input {
      padding-bottom: 4px;
      border: none;
      border-bottom: 1px solid $light-gray;
      outline: 0;
      box-shadow: none;
      background: transparent;
      margin-bottom: 0;
    }
  }
  .jils-form-check {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;

    input {
      display: inline-flex;
      flex-direction: column;
      line-height: inherit;
      margin-right: 10px;
    }

    [type='radio'] {
      align-self: center;
      width: 15px;
      height: 15px;
      vertical-align: middle;

      /* states */
      cursor: pointer;
      border: 1px solid;
      border-radius: 0;
      box-shadow: 0 0 0 4px transparent;
      transition-timing-function: ease-out;
      transition-duration: 75ms;
      transition-property: box-shadow background-color background-position;
      appearance: none;

      &:hover:not(:focus) {
        // &:not(:disabled) {
        //   &[indeterminate],
        //   &:checked {
        //     // background-color: blueviolet;
        //   }
        // }

        &:not(:checked):not(:disabled):not([indeterminate]) {
          cursor: pointer;
          border-color: black;
        }
      }

      .checkbox-label {
        cursor: pointer;
      }

      &:checked + .checkbox-label {
        &::after {
          content: '';
          background: $black;
          position: absolute;
          top: 4.5px;
          left: 4px;
          width: 7px;
          height: 7px;
        }
      }

      &:disabled {
        cursor: not-allowed;
      }

      // &:disabled:checked {
      //   border-color: transparent;
      // }

      // &:active:checked:not(:disabled) {
      //   border-color: transparent;
      // }

      &:focus {
        outline: 0;
      }
    }
    [type='checkbox'] {
      align-self: center;
      vertical-align: middle;
      /* states */
      box-shadow: 0 0 0 4px transparent;
      transition-timing-function: ease-out;
      transition-duration: 75ms;
      transition-property: box-shadow background-color background-position;
      appearance: none;
      color: $black;
      width: 15px;
      height: 15px;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: 1px solid $black;
      cursor: pointer;
      flex-shrink: 0;
      border-radius: 0;
      &:active:checked:not(:disabled) {
        border-color: $black;
      }
      &::after {
        position: relative;
        display: block;
        background-color: $black;
        transform: none;
        top: 3px;
        left: 3px;
        height: 7px;
        width: 7px;
        background: $black;
        content: none;
      }
      &:checked {
        background-color: transparent;
        border-color: $black;
        &::after {
          content: '';
        }
      }
      &:hover:not(:focus) {
        &:not(:disabled) {
          &[indeterminate],
          &:checked {
            border-color: $black;
          }
        }
      }
    }
  }
}

.jils-button {
  &.-solid {
    background-color: $button-bg-blue;
    color: $secondary-color;
    a,
    span,
    p {
      align-items: center;
      // border: 1px dashed;
      color: $secondary-color;
      display: flex;
      height: 100%;
      justify-content: center;
      text-decoration: none;
    }
  }
}

.phone-wrapper {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  .error-message {
    position: absolute;
    bottom: -35px;
  }
}
