.geoip.message-notification {
  z-index: 200001; //IBT-4969

  &.-show {
    z-index: 200001;
  }

  .notification-box {
    padding: 30px;
    row-gap: 20px;
    text-align: left;

    .notification-message {
      align-self: flex-start;
      font-weight: 500;
      letter-spacing: 0.07em;
      padding: 25px 0 10px;
      text-transform: none;
    }

    .jil-button {
      font-size: 12px;
      line-height: 14px;
      text-transform: none;
    }

    .notification-close {
      right: 18px;
      top: 18px;
    }
  }
}
//
