// Widget bag, items in the cart

.minicart-icon-hidden {
  display: none;
}

.widget-bag {
  margin-bottom: 30px;

  .attributes-wrapper {
    display: flex;
    .product-color-detail {
      display: flex;
      order: 1;
      text-transform: lowercase;
    }
    .size-color-separator {
      margin: 0 4px;
    }
  }
  .product-color-size-wrapper {
    .name-field {
      margin-right: 4px;
    }
  }

  .price-quantity-wrapper {
    display: flex;
    .item-price {
      margin-bottom: 0;
      margin-right: 4px;
    }
    .price {
      margin-right: 4px;
    }
  }

  &-item {
    display: flex;
    flex-direction: row;
    border-top: 1px solid $lightest-gray;
    padding: 15px 0;

    .info {
      width: 100%;

      .title {
        letter-spacing: 0.08em;
        line-height: 1.4em;
        text-transform: uppercase;
        margin: 0;
        padding-bottom: 10px;
        font-size: 10px;
        padding-left: 0;
        padding-right: 0;
        text-align: left;
      }

      .value,
      .price {
        margin-bottom: 0;
      }

      .price {
        del {
          display: inline-block;
        }
      }
      .product-remove-wrapper {
        margin-top: 13px;
      }
    }

    .image {
      height: 80.33px;
      margin-left: 15px;
      width: 53px;
      a {
        height: inherit;
        width: inherit;
        &:hover {
          border: none;
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &-total {
    padding: 30px 0 17px;
    border-top: 1px solid $light-gray;

    .edit-link {
      padding-left: 15px;
    }

    .grand-total {
      border-top: none;
      padding: 0;
    }

    .line-totals-item {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .total-label {
    display: flex;
  }

  .checkout-button-wrapper {
    margin-top: 21px;
  }

  .continue-shopping-wrapper {
    margin-top: 25px;
    text-align: center;
    .continue-shopping-btn {
      text-transform: none;
    }
  }
}

.cart-body-section {
  // margin-top: 175px;
  margin-top: 134px;
  border-bottom: 1px solid $light-gray;

  .detail-attribute {
    display: flex;
    margin-bottom: 2px;
    .name-field {
      margin-right: 4px;
    }
    &.color {
      text-transform: lowercase;
    }
  }

  .product-info-img {
    // border: 1px dashed;
    width: 94px;
  }

  .product-wrapper {
    padding: 0 0 32px;
    margin-bottom: 27px;
    list-style: none; // JILS-448
  }
  .price {
    display: flex;
    font-size: unset;
  }
}

.cart-sidebar-section {
  border-bottom: 1px solid $light-gray;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  .leading-lines,
  .line-totals-item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    &.cart-shipping-method {
      .mm-badge {
        display: none;
      }
    }
  }

  .grand-total {
    font-style: normal;
    // font-weight: 600;
    font-size: 14px;
    border-top: none;
    padding: 0;
  }
}

.cart-checkout-buttons-wrapper {
  margin-top: 30px;
  .checkout-button-wrapper {
    .button {
      width: 100%;
      height: 39px;
      padding: 12px 0 16px 0;
      font-size: 14px;
      @include breakpoint(large up) {
        font-size: 12px !important;
      }
    }
  }
  .js_paypal-content {
    margin-top: 30px;
  }
}

.wrapper-accordion-editorial {
  margin-top: 40px;
  .accordion-title-icon {
    svg {
      height: 11px;
      width: 11px;
    }
  }
  // .accordion {
  //   > div {
  // padding: 16px 12px;
  // border-bottom: 1px solid $light-gray;
  // .js-accordion-trigger {
  //   padding-right: 0;
  // }
  // }
  // }
}

.voucher-wrapper {
  margin-top: 16px;
  .voucher-input-container {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    .coupon-code-field {
      width: 227px;
    }
  }
  .accordion.is-enabled .is-active .accordion-content {
    margin-top: 20px;
  }

  &.discounts {
    #accordion-cart-voucher,
    .voucher-input-container {
      display: none;
    }

    #cart-voucher {
      display: block;
      max-height: none;
      opacity: 1;

      .coupon-price-adjustment {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .coupon-code {
        font-size: 14px;
        letter-spacing: -0.02em;
      }

      .coupon-applied {
        display: none;
      }

      .remove-coupon {
        color: #969693;
        font-size: 14px;
        margin-right: 106px;
        text-decoration: underline;
      }
    }
  }
}

/** CART ITEM GRID setup -->START*/
.cart-item-grid {
  display: grid;
  grid-template-columns: 103px auto auto;
  grid-template-rows: auto 1fr;
  column-gap: 0.75rem;
  grid-template-areas:
    'message message message'
    'product-image attributes price'
    'product-image quantity price'
    'message-eta message-eta message-eta'
    'product-actions product-actions product-actions'
    'delete-edit-actions delete-edit-actions delete-edit-actions';
  @include breakpoint(large up) {
    max-width: 329px;
  }
}

.delete-edit-wrapper {
  display: flex;
  font-size: 12px;
  grid-area: delete-edit-actions;
}

.product-message {
  grid-area: message;
}

.product-message-eta {
  grid-area: message-eta;
  padding: 0.5rem;
  // border-top: 1px solid get-color('ecru-95', 10);
  margin: 0 -0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 1.5rem);
}

.product-image-wrapper {
  grid-area: product-image;
  grid-column: 1;
  grid-row: 2 / 4;
}

.product-attributes {
  display: flex;
  flex-direction: column;
  // font-size: 14px;
  grid-area: attributes;
  grid-column: 2 / 3;
  justify-content: space-between;
  justify-self: stretch;
  margin-bottom: 0.25rem;
  h4 {
    // font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    word-break: break-all;
  }
  a {
    border-bottom: none;
    text-decoration: none;
    width: 127px;
  }
}

.product-edit {
  grid-area: edit;
  justify-self: end;
  margin-right: 20px;
}

.product-prices-wrapper {
  grid-area: price;
  grid-column: 3/3;
  justify-self: end;
  width: max-content;
  place-self: start end;
  margin-bottom: 0.5rem;

  .line-item-total-price {
    display: block;
    .price {
      display: flex;
      flex-direction: column;
      .strike-through {
        color: $medium-gray;
        display: flex;
        order: 2;
      }
    }

    .discount-price-label {
      order: 3;
    }

    .line-item-total-price-amount {
      order: 4;
    }

    .original-price-label {
      order: 1;
    }
  }
}

#cart {
  .product-quantity-wrapper {
    grid-area: quantity;
    margin-bottom: 0.5rem;
    place-self: center start;
    padding-left: 10px;
    &.quantity {
      align-items: center;
      display: flex;
      width: 100%;
      .quantity-form {
        align-items: center;
        display: flex;
        height: 17px;
        position: relative;
        width: 60px;
      }
      input {
        border: none;
        background: transparent;
        text-align: center;
        width: 32px;
        position: absolute;
        left: calc(50% - 15px);
        @include breakpoint(large up) {
          left: calc(50% - 9px);
        }
      }
      .btn--minus {
        position: absolute;
        left: 0;
      }
      .btn--plus {
        position: absolute;
        right: 0;
      }
    }
  }

  .title-list,
  .title-list + .store-item,
  .option-ship-type {
    display: inline;
  }

  .title-list,
  .current-store-name {
    font-size: 14px;
    line-height: 17px;
  }

  .title-list {
    letter-spacing: -0.01em;
    margin: 0;

    ~ ul {
      margin-top: 25px;
    }
  }

  .current-store-name {
    letter-spacing: -0.01em;
    text-transform: none;
    transform: translateX(5px);
  }

  .title-list + .store-item {
    /*
    &::before {
      content: '-';
    }
    */

    .icon--info,
    .current-store-name::before {
      display: none;
    }
  }

  .bopis-btn {
    color: #969693;
    float: right;
    padding: 0;
    text-decoration: underline;
    width: auto;
  }

  .product-message:not(.error-message-cart) {
    display: none;
  }

  .icon--shipping-medium,
  .icon--pick-up-small {
    display: none;
  }

  .option-ship-label {
    &::before {
      display: none;
    }

    text-transform: none;
  }
}

.product-actions-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  grid-area: product-actions;
  justify-content: space-between;
  margin: 0 -0.5rem;
  padding: 27px 0.5rem 25px;
  width: calc(100% + 1.5rem);
}

/** CART ITEM GRID setup --> END*/

.no-border-bottom {
  border-bottom: none !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.special-message-wrapper {
  .jils-form-message {
    margin: 13px 0 30px;
  }
}

.duties-tax-label,
.sales-tax-label {
  display: flex;
  .tooltip-global-container {
    top: -2px;
  }
}

#confirmation-bopis-modal {
  .modal-confirmation-actions {
    display: flex;
    justify-content: space-between;
  }
}

.prices-add-to-cart-actions {
  .product-notify {
    display: none;
  }
  &.show-notify-me {
    .add-to-cart-wrapper {
      display: none;
    }
    .product-notify {
      display: block;
    }
    ~ .findinstore {
      display: none;
    }
  }
}
