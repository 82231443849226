.message-notification {
  display: none;

  &.-show {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1005;
    background-color: rgba($color: #000000, $alpha: 0.1);
    overflow-y: auto;
  }

  .notification-box {
    color: $darkgrey;
    background-color: $off-white;
    font-size: 1.2rem;
    width: 100%;
    min-height: 100px;
    padding: 2.25rem 1.5rem;

    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 2;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;

    .notification-close {
      color: $darkgrey;
      position: absolute;
      top: 20px;
      right: 18px;
    }
    .box-decoration-element {
      display: none;
      width: 55px;
      height: 4px;
      background-color: $lightgrey;
      border-radius: 50px;
      position: absolute;
      top: 12px;
    }

    .notification-message {
      padding-top: 3rem;
      padding-bottom: 2.5rem;
      text-transform: uppercase;
      font-size: 12px;
    }
  }

  .js-notification-link--checkout {
    margin-top: 15px;
  }

  .recommendations {
    h2 {
      display: none;
    }

    .container-carousel {
      padding: 0 5px 0;
      margin-top: 0;

      .product-grid {
        gap: 20px;
      }
    }
  }
}
