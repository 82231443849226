:root {
  --colors-box-margin-right: 0;
}

$include-fonts: false;

@import '../components/sizechart';
// @import 'search/product-tile';

@mixin disableVariation {
  // pointer-events: none;
  // cursor: default;
  opacity: 0.25;
}

// 00 . Temp styles
// 01 . General
// 02 . Left side
// 03 . Right side
// 04 . Right panels
// 05 . Find store panel
// 06 . Recommended
// 07 . Zoom

// 01 . General
.product-wrapper {
  margin-bottom: 140px;

  .content-product-info {
    .dot-list {
      margin: 12px 0 15px;
    }
  }

  .payment-icons-wrapper {
    margin-bottom: 18px;

    .payment-icon-line-1 {
      margin-bottom: 15px;
    }
  }
}

// 02 . Left side
.primary-images {
  position: relative;

  @include breakpoint(medium up) {
    margin-top: 50px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: block;
    cursor: pointer;
    border: solid black;
    border-width: 0 1px 1px 0;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 50%;
    z-index: 2;
  }

  .swiper-button-prev {
    left: 18px;
    transform: rotate(135deg);
  }

  .swiper-button-next {
    right: 18px;
    transform: rotate(-45deg);
  }

  .swiper-button-disabled {
    display: block;
  }

  .js-swiper-wrapper.swiper-wrapper {
    max-height: unset;
    margin-left: 0;
    width: unset;
    height: unset;

    .js-carousel-item.carousel-item {
      height: 100% !important;
    }
  }

  .swiper-slide {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    min-width: 100%;
    width: 100% !important;
    display: block;
  }

  .swiper-wrapper {
    -webkit-transform-style: preserve-3d;
  }

  .swiper-pagination {
    display: none;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction .swiper-pagination-vertical.swiper-pagination-bullets,
  .swiper-vertical > .swiper-pagination-bullets {
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    justify-content: space-around;
    padding: 0 69px;
    align-items: center;
    height: 48px;
    display: flex;
    position: absolute;
    text-align: center;
    transition: 0.3s opacity;
    transform: translate3d(0, 0, 0);

    .swiper-pagination-bullet {
      margin: 8px 0;
    }
  }

  @include breakpoint(small only) {
    width: 100vw;
  }

  .carousel {
    text-align: right;

    @include breakpoint(small only) {
      width: 100vw;
    }

    .owl-nav {
      button {
        border-color: black;
        width: 50px;
        height: 50px;
        border: none;
        transform: translateY(-50%);
        top: 50%;

        &.owl-next {
          right: 0;

          &::after {
            @include arrow(10px, 10px, black, 1px, right);

            content: '';
            display: block;
            position: relative;
            right: -20px;
          }
        }

        &.owl-prev {
          left: 0;

          &::after {
            @include arrow(10px, 10px, black, 1px, left);

            content: '';
            display: block;
            position: relative;
            left: 20px;
          }
        }
      }
    }

    @media screen and (max-height: $landscapeHeightBreakpoint) and #{breakpoint(landscape)} {
      .carousel-item {
        text-align: center;
      }
    }
  }

  .carousel-item {
    @include breakpoint(medium up) {
      margin: 0 0 50px auto;
    }

    img {
      cursor: zoom-in;
      width: auto;

      @include breakpoint(medium up) {
        max-height: calc(100vh - 76px);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    figure {
      position: relative;
      overflow: hidden;
    }

    figcaption {
      position: absolute;
      top: 0;
      left: -10000px;
    }

    @include breakpoint(small only) {
      // display: none;

      &:first-child {
        display: block;
        margin-bottom: 0;
      }

      img {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

// 03 . Right side
.info-col {
  position: relative;
  .pdp-size {
    width: 100%;
  }
  @include breakpoint(medium) {
    // width: 50%;
    // float: left;
    // height: calc(100vh - 50px);

    .sticky-wrap {
      min-height: 100vh;
      padding: 38px;
      padding-left: 68px;
      // padding-top: 100px;
      padding-top: 86px;
      position: sticky;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    .sticky-wrap-inner {
      display: block;
      width: 100%;
      max-width: 320px;

      .row.justify-content-center {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: column;
      }
    }
  }

  .attributes {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }

  @include breakpoint(small only) {
    .sticky-wrap {
      margin: 0 30px;
    }
  }

  .not-returnable-message,
  .availability-date {
    color: #666;
    margin-top: 10px;

    @include breakpoint(medium up) {
      font-size: 11px;
    }
  }

  @media screen and (max-height: $landscapeHeightBreakpoint) and #{breakpoint(landscape)} {
    .sticky-wrap {
      padding-top: rem-calc(100);

      &-inner {
        width: 100%;
      }

      .name-price-wrap,
      .short-desc-wrap,
      .attributes [data-attr='color'] {
        margin-bottom: rem-calc(10);
      }

      .name-price-wrap {
        padding-bottom: rem-calc(10);
      }

      .prices-add-to-cart-actions {
        margin-top: rem-calc(25);
      }

      .extra-actions {
        margin-top: rem-calc(10);
      }

      .sizing-label {
        padding-top: rem-calc(5);
      }
    }
  }
}

.dynamic-selected-assistive-text-width {
  // flex-wrap: wrap;
  // padding-right: var(--colors-box-margin-right);
  // padding-right: 60px;
  display: grid;
  grid-template-columns: repeat(9, min-content);
  grid-template-rows: auto;
  position: relative;
  column-gap: 2px;
  row-gap: 2px;
  @include breakpoint(large up) {
    // padding-right: 110px;

    display: grid;
    grid-template-columns: repeat(7, min-content);
    grid-template-rows: auto;
    column-gap: 0;
    row-gap: 2px;
  }

  li {
    padding: 0;
    label {
      margin: 0;
    }
  }
}

.name-price-wrap {
  align-items: center;
  position: static;
  bottom: 0;
  padding: 20px;
  text-align: center;
  background: $body-background;
  width: 100%;
  justify-content: center;
  z-index: 1;

  display: flex;
  @include breakpoint(small only) {
    letter-spacing: 0.08em;
    &.show-for-small-only {
      position: initial;
      bottom: 0;
      // align-items: baseline;
    }
  }

  @include breakpoint(medium) {
    position: static;
    border-bottom: 1px solid $black;
    padding: 0;
    padding-bottom: 10px;
    margin-bottom: 10px;
    text-align: left;
    background: transparent;
    justify-content: start;
    // color: $link-color-gray;
    color: $black;
  }

  .product-name {
    font-size: 14px;
    @include breakpoint(large up) {
      font-size: 12px;
    }
    line-height: 17px;
    letter-spacing: 0.065em;
    margin-right: 10px;
    text-transform: none;
    font-weight: 500;
    // color: $link-color-gray;
    color: $black;
  }

  .js-description-content {
    font-size: 14px;
    @include breakpoint(large up) {
      font-size: 12px;
    }
  }

  @include breakpoint(small only) {
    .product-name {
      text-transform: uppercase;
    }
  }
}

.short-desc-wrap {
  // margin-bottom: 35px;
  margin-bottom: 15px;
  font-size: 14px;
  @include breakpoint(large up) {
    font-size: 12px;
  }

  .pdp-panel-trigger {
    position: relative;
    margin-left: 14px;
    display: inline-block;
    line-height: 1;
    text-decoration: underline;

    svg {
      position: absolute;
      top: 2px;
      left: -12px;
      width: 7px;
      height: 7px;

      @include breakpoint(small only) {
        top: 4px;
      }
    }

    @include breakpoint(small only) {
      &:hover {
        border: none;
      }
    }
  }
}

.quantity,
.availability {
  &:not(.minicart-quantity) {
    display: none;
  }
}

div[data-attr='color'] {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 25px;

  .attribute {
    position: relative;
    // display: flex;
  }

  .color-attribute {
    width: 26px;
    height: 26px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      padding-left: 0;
    }

    &.unselectable {
      @include disableVariation;
    }

    .selected-assistive-text {
      display: none;
      position: absolute;
      // top: 50%;
      right: 0;
      // transform: translateY(-50%);
      text-transform: lowercase;
      // color: $medium-grey;
      bottom: 5px;
      color: $black;
    }

    &.disable-color-attribute {
      .selected-assistive-text {
        display: block;
      }
    }
  }

  .color-additional {
    font-size: 13px;
    @include breakpoint(medium) {
      font-size: 11px;
    }

    line-height: 1.3em;
    letter-spacing: 0.02em;
    margin-left: auto;
    font-weight: 400;
    text-align: left;
    text-transform: none;
    color: #000;
    opacity: 0.6;
    margin-top: 11px;
    cursor: pointer;

    > span {
      padding-left: 12px;
    }
  }

  .swatch-circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    appearance: none;
    cursor: pointer;

    @include breakpoint(small only) {
      width: 16px;
      height: 16px;
    }

    &.selected-color,
    &.selected {
      &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }
    }

    &.bordered {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid rgba(0, 0, 0, 0.4);
        border-radius: 50%;
        height: 16px;
        width: 16px;

        @include breakpoint(small only) {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

div[data-attr='size'] {
  .sizing-label {
    display: block;
    opacity: 1;
    text-align: right;
    color: $medium-gray;
    padding-top: 8px;
    position: absolute;
    right: 0;
  }

  .size-chart {
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 100px;

    @include breakpoint(small only) {
      font-size: 13px;
    }

    a {
      color: #666;
      font-size: 13px;
      border-bottom: 0;
      padding-bottom: 0;
      text-align: right;

      @include breakpoint(medium) {
        font-size: 11px;
      }

      &:hover,
      &:focus {
        border-bottom: 0;
      }
    }
  }

  .pdp-size {
    position: relative;
  }

  &:hover {
    .size-chart {
      a {
        color: $black;
      }
    }
  }
}

.pdp-size-picker {
  position: relative;
  flex-wrap: nowrap;
  gap: 5px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #dadbd6;
    z-index: -1;
  }
  .size-guidelines-trigger-box {
    align-self: end;
    text-align: right;
    // margin-bottom: 10px;
    margin-bottom: 5px;
    .size-guidelines {
      color: $black;
    }
  }
}

.pdp-size-picker-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 70%;

  @include breakpoint(medium) {
    max-width: auto;
    width: auto;
  }

  li {
    padding: 0;
    text-align: center;
    line-height: 1.2;
    border-right: 1px solid #dadbd6;
    border-bottom: 1px solid #dadbd6;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;

    //  &.disabled {
    //    display: none;
    //    border: none;
    //  }

    .do_selectsize,
    a {
      display: block;
      min-width: 40px;
      padding: 10px 5px;

      @include breakpoint(small only) {
        font-size: 13px;
      }

      &:hover {
        border-color: transparent;
        @include breakpoint(medium up) {
          text-decoration: underline;
          text-underline-offset: 2px;
        }
      }

      &.disabled {
        @include disableVariation;
      }

      span {
        border-bottom: 1px solid transparent;
      }
    }

    &.selected {
      border: 1px solid $black;
      z-index: 2;

      .do_selectsize,
      a {
        &:hover {
          span {
            border-color: transparent;
          }
        }
      }
    }
  }
}

.prices-add-to-cart-actions {
  z-index: 9;
  margin-top: 60px;
  position: fixed;
  bottom: 8px;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  max-width: calc(100% - 60px);

  &.sticky-add-to-cart {
    position: fixed;
    max-width: calc(100% - 60px);
  }

  @include breakpoint(large up) {
    margin-top: 30px;
  }
  .js-retail,
  .find-in-store,
  .out-of-stock {
    width: 100%;
    height: 100%;
    display: block;
    text-align: center;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.4);
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: transparent;
      color: $black;
      border: 1px solid $black;
    }

    &.disabled {
      color: $black;
      opacity: 1;
      cursor: default;

      &:hover,
      &:focus {
        border: 1px solid rgba(0, 0, 0, 0.4);
      }
    }

    &.is-loading {
      color: transparent;

      &::before {
        content: '';
        display: block;
        z-index: 1;
        width: 10px;
        height: 10px;
        background-color: #000;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -5px;
        margin-top: -5px;
        border-radius: 50%;
        animation: ani_addtobag_button_isloading 1s linear infinite;
      }
    }

    @keyframes ani_addtobag_button_isloading {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 1;
      }

      50.1% {
        opacity: 0;
      }

      100% {
        opacity: 0;
      }
    }
  }

  .out-of-stock {
    cursor: default;
  }

  .addtocart-error {
    padding-bottom: 29px;
    text-align: center;
    display: flex;
    justify-content: center;
    svg {
      fill: $error-messages;
      margin-right: 5px;
      width: 14px;
      @include breakpoint(large up) {
        width: 12px;
      }
    }

    .addtocart-error-message {
      background-position: 0 0 !important;
      padding-bottom: 2px;
      font-size: 14px;
      @include breakpoint(large up) {
        font-size: 12px;
      }
    }
  }
  .product-notify {
    display: none;
  }
  &.show-notify-me {
    .add-to-cart-wrapper {
      display: none;
    }
    .product-notify {
      display: block;

      .jil-button.js-notify-me-button {
        background-color: #f9f7ef;
      }
    }
    // JILS-293
    ~ .findinstore {
      display: none;
    }
  }
}

.addtocart-error {
  margin-top: 60px;
  // padding-bottom: 29px;
  text-align: center;
  display: flex;
  justify-content: center;

  @include breakpoint(large up) {
    margin-top: 30px;
  }

  svg {
    fill: $error-messages;
    margin-right: 5px;
    width: 14px;
    @include breakpoint(large up) {
      width: 12px;
    }
  }

  .addtocart-error-message {
    background-position: 0 0 !important;
    padding-bottom: 2px;
    font-size: 14px;
    @include breakpoint(large up) {
      font-size: 12px;
    }
  }
}

.add-to-wishlist-div {
  display: flex;
  position: relative;
  width: 100%;

  a.wishlist-text {
    display: none;
  }
  &.country-JP {
    display: block;

    .js_add-to-wish-list.active + a.wishlist-text {
      position: relative;
      margin-top: 5px;
    }
  }
}

.js_add-to-wish-list.active + a.wishlist-text {
  margin-top: 0px;
  position: absolute;
  right: 0;
  display: block;
  font-size: 13px;
  text-decoration: underline;
  border: none;
}

.add-to-whishlist {
  align-items: baseline;
  display: flex;
  flex-direction: column;
  // margin-top: 46px;
  margin-top: 40px;
  .add-to-wish-list {
    .wishlist-icon-wrapper {
      cursor: pointer;
      align-items: center;
      display: flex;

      &.wishlist-icon-active {
        display: none;
      }
    }
    &.active {
      .wishlist-icon-wrapper {
        display: none;
        &.wishlist-icon-active {
          display: flex;
        }
      }
    }

    svg {
      height: 15px;
      width: 16px;
      margin-right: 9px;
      stroke-width: 0;
      @include breakpoint(large up) {
        height: 15px;
        width: 16px;
      }
    }
  }
  .addtowishlist-error {
    margin-bottom: 0;
  }
  .wishlist-text {
    align-items: flex-end;
    display: flex;
    height: 15px;
    font-size: 13px;
    text-decoration: underline;
    @include breakpoint(large up) {
      font-size: 13px;
    }
  }
}

.product-size-message-warning {
  margin: 30px 0 30px 0;
  .jil-form-message {
    font-size: 14px;
  }

  @include breakpoint(large) {
    margin-top: 5px;
    margin-bottom: 0;
    .jil-form-message {
      font-size: 12px;
    }
  }
}

.sizing-type-message {
  text-align: end;
  margin-top: 10px;
  @include breakpoint(large) {
    margin-bottom: 10px;
  }
}

.jil-messages-wrapper {
  display: flex;
  justify-content: space-between;
}

.pdp-sizeChart-link {
  border-bottom: 1px solid $lightgrey;
  text-align: left;
}

.add-to-cart-error-messaging:not(.d-none) {
  margin: 15px 0 29px 0;
  display: flex;
  svg {
    fill: $error-messages;
    flex-basis: 10%;
    margin-top: 2px;
  }
  .jil-form-message {
    font-size: 14px;
  }
}

.extra-actions {
  margin-top: 50px;
  font-size: 11px;

  @include breakpoint(small only) {
    margin-bottom: 80px;

    a:hover {
      border: none;
    }
  }

  li {
    position: relative;
    padding-left: 12px;

    @include breakpoint(small only) {
      padding-left: 15px;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 7px;
      height: 7px;
    }

    a {
      @include breakpoint(small only) {
        font-size: 13px;
      }
    }
  }

  @media screen and (max-height: $landscapeHeightBreakpoint) and #{breakpoint(landscape)} {
    ul {
      columns: 2;

      li {
        padding: rem-calc(0 0 0 15);
        font-size: 10px;
      }
    }
  }
}

.pdp-sticky-bottom {
  position: sticky;
  left: 0;
  bottom: 13px;
  width: 50%;
  margin-left: 50%;

  .cell {
    margin-bottom: 6px;
  }

  @include breakpoint(small only) {
    position: static;
    width: 100%;
    margin-left: 0;
  }

  a {
    @include breakpoint(small only) {
      font-size: 13px;
    }
  }
}

.pdp-sticky-bottom-inner {
  position: absolute;
  top: -38px;
  padding-right: 38px;
  padding-left: 68px;
  width: 100%;

  > div {
    justify-content: space-between;
    max-width: 320px;
  }

  @media screen and (max-height: $landscapeHeightBreakpoint) and #{breakpoint(landscape)} {
    padding-left: 0;
    top: rem-calc(-10);

    > div {
      justify-content: initial;

      .assistance {
        margin-left: rem-calc(7);
      }
    }

    a {
      font-size: 10px;
    }
  }

  @include breakpoint(small only) {
    padding-left: 30px;
    position: static;
    max-width: 100%;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 0;
    width: 8px;
    height: 8px;
    transform: translateY(-50%);
  }
}

.back-to-category {
  position: relative;
  padding-left: 14px;
  font-size: 11px;
}

.assistance {
  position: relative;
  padding-left: 14px;

  @include breakpoint(medium up) {
    font-size: 11px;
  }

  > a {
    cursor: default;
    border: 0;
  }

  .assistance-info {
    display: none;
    position: absolute;
    width: 200px;
    padding-bottom: 6px;
    line-height: 1.5;
    top: 40px;

    @include breakpoint(medium up) {
      text-align: right;
      bottom: 100%;
      right: 0;
      top: auto;
    }
  }

  @media screen and (max-height: $landscapeHeightBreakpoint) and #{breakpoint(landscape)} {
    .assistance-info {
      top: rem-calc(-1);
      right: rem-calc(-60);
    }
  }

  &:hover {
    .assistance-info {
      display: block;
    }
  }
}

// 04 . Right panels
.pdp-panel {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(251, 251, 246, 0.75);
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
  z-index: 1000;

  .panel-veil {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .panel-container {
    width: 25%;
    min-width: 320px;
    margin-left: auto;
    background-color: #f9f7ef;
    transform: translate3d(100%, 0, 0);
    transition: transform 0.8s ease-in-out;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @include breakpoint(small only) {
      width: 100%;
      transform: translate3d(0, 100%, 0);
    }
  }

  .panel-container-inner {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;

    &.medim-padding {
      padding-left: 20px;
      padding-right: 20px;
    }

    @include breakpoint(medium) {
      max-width: 320px;
    }

    h4 {
      text-transform: none;
    }

    .ul-listing li {
      position: relative;
      padding-left: 10px;

      &::before {
        content: '\2013';
        left: 0;
        width: 10px;
        display: inline-block;
        position: absolute;
      }
    }
  }

  &.is-open {
    opacity: 1;
    pointer-events: all;

    .panel-container {
      transform: translate3d(0, 0, 0);
    }
  }

  .close-panel {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    width: 44px;
    height: 44px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    a {
      display: block;
      width: 10px;
      height: 10px;
      border: none;

      &:hover {
        border-color: transparent;
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

// Login Panel

#login-box {
  .inner-box {
    margin-left: 0;
    margin-right: 0;

    &.login {
      width: auto;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

// 05 . Find store panel
.store-panel {
  .panel-container {
    align-items: normal;
  }

  h4 {
    text-transform: none;
    display: block;
    margin-bottom: 22px;
    font-size: 11px;
    letter-spacing: 0.02em;

    @include breakpoint(small only) {
      font-size: 13px;
    }
  }

  .size-list {
    margin-bottom: 80px;
  }

  .stores-init,
  .content-asset {
    margin-top: 10px;
    margin-bottom: 12px;
  }

  .panel-container-inner > .pdp-size {
    position: sticky;
    top: 0;
    z-index: 50;

    .pdp-size-picker {
      padding-top: 10px;
      background-color: #f9f7ef;

      &::after {
        z-index: 1;
      }
    }
  }

  .status {
    padding-left: 0 !important;
  }

  .info {
    display: none;

    &.open {
      display: block;
    }

    a {
      border-bottom: none !important;
    }
  }
}

.stores-list {
  font-size: 11px;

  h4 {
    letter-spacing: normal;
    margin-bottom: 12px;
  }

  a {
    border-bottom: 1px solid $light-gray;

    &:hover {
      border-bottom: 1px solid $primary-color;
    }
  }

  @include breakpoint(small only) {
    font-size: 13px;
  }
}

.stores-list-item {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  padding: 14px 0 30px;

  .title {
    line-height: 1.5;
  }

  .details {
    float: right;

    a {
      opacity: 0.6;
      border: none;

      &:hover {
        opacity: 1;
        border: none;
      }
    }
  }

  .status {
    position: relative;
    margin-top: 8px;
    padding-left: 13px;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 7px;
      height: 7px;
    }
  }
}

.info-col.details-column-content {
  z-index: 1;
}

// 06 . Recommended
.pdp-recommendations {
  .recommendations-title {
    font-size: 11px;
    letter-spacing: 0.08em;
    line-height: 1.5em;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    margin: 220px 20px 20px;

    @include breakpoint(large) {
      font-size: 10px;
      letter-spacing: 0.08em;
      line-height: 1.4em;
      margin: 180px 20px 20px;
    }
  }

  .pdp-recommendations-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 15px;
    margin: 0 15px;

    @include breakpoint(large) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 10px 20px;
      margin: 25px 40px;
    }

    @include breakpoint(xlarge) {
      margin: 25px 60px;
    }
  }
}

// 07 . Zoom
.zoom-wrapper {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: #fbfbf6;
  overflow: hidden;
  transform: translate3d(0, 100vh, 0);
  transition: all 0.5s ease-in-out;

  .zoom-carousel {
    width: 100%;
    height: 100vh;
    overflow: auto;
    cursor: zoom-out;
  }

  img {
    width: 100%;

    @include breakpoint(small only) {
      width: 150%;
      max-width: none;
    }
  }

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 0;
    width: 44px;
    height: 44px;
    cursor: pointer;
    z-index: 10001;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 10px;
      height: 10px;
    }
  }

  &.isOpen {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    pointer-events: all;

    img {
      opacity: 1;
    }
  }
}

.contact-panel,
.reserve-panel {
  letter-spacing: 0.02em;
  line-height: 1.5em;

  @include breakpoint(medium up) {
    font-size: 11px;
  }

  .item-input {
    display: block;
    margin: 22px 0;
  }

  textarea {
    padding: 6px;
    min-height: 70px;
  }

  .button {
    margin-top: 30px;
  }

  .recaptcha-container {
    @include breakpoint(medium up) {
      transform: scale(0.8);
      transform-origin: left;
    }

    .invalid {
      border: 1px solid $error-color;
    }
  }

  .privacy-policy {
    max-height: 80px;
    overflow-y: auto;
  }
}

#details-panel {
  letter-spacing: 0.02em;

  h4 {
    font-size: inherit;
    letter-spacing: inherit;
    line-height: 1.5em;
  }

  .ul-listing {
    li {
      padding: 0;
      padding-left: 12px;
    }
  }

  .block {
    margin: 50px 0;
  }
}

#pay-ship-panel {
  letter-spacing: 0.02em;
  font-size: 13px;

  @include breakpoint(medium up) {
    font-size: 11px;
  }

  h4 {
    font-size: inherit;
    letter-spacing: inherit;
    line-height: 1.5em;
    margin-bottom: 12px;
    margin-top: 25px;
  }

  .ul-listing {
    li {
      padding: 0;
      padding-left: 12px;
    }
  }

  img {
    width: 26px;
    margin-right: 10px;
  }
}

.price {
  font-size: 14px;
  @include breakpoint(large up) {
    font-size: 12px;
  }
  del {
    display: inline-block;
    color: #c8c8c4;
  }
  span {
    align-items: center;
    display: flex;
    .default-price-container {
      margin-bottom: 1rem;
    }
  }

  .strike-through {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 0;
    color: #c8c8c4;
  }
}

.has_multiple-price {
  flex: 100%;
  @include breakpoint(small only) {
    display: flex;
    justify-content: center;
  }
}

.jil-message-wrapper {
  font-size: 14px;
  @include breakpoint(large up) {
    font-size: 12px;
  }
  p {
    margin-bottom: 0;
  }
}

.mb-2 + .mb-2 {
  margin-top: 2rem;
}

#content-contact-info {
  font-size: 12px;
}

/*.do_selectsize {
  &.disabled {
    text-decoration-line: line-through;
  }
}*/

.findinstore {
  margin: 20px 0 16px;

  @include breakpoint(large up) {
    margin: 5px 0;
  }

  .bopis-btn {
    padding: 15px 20px;
    width: 100%;
    color: #585856;

    @include breakpoint(large up) {
      padding: 10px 20px;
    }
    span {
      align-items: center;
      column-gap: 8px;
      display: flex;
      justify-content: center;
      letter-spacing: 0.065em;
      text-transform: uppercase;

      &::before {
        // PDP IMG STORE LOCATOR
        $sprite: map-get($sprites, 'store-marker-grey');
        background: url($sprite) center no-repeat;
        content: '';
        display: block;
        height: 16px;
        // opacity: 0.3;
        width: 16px;
      }
    }

    &:hover {
      background-color: $button-bg-blue;
      color: $secondary-color;

      span {
        &::before {
          $sprite: map-get($sprites, 'store-marker-white');
          background: url($sprite) center no-repeat;
          content: '';
          display: block;
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  // JILS-440
  .store-selected-display {
    display: none;
  }
}

.findinstore-modal {
  p {
    margin: 0;
  }

  .modal-background {
    display: flex;
    flex-direction: column;
    padding: 0;

    > div {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }

  // .modal-header {
  //   border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  //   height: 59px;
  // }

  .modal-title {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 1px;
    line-height: 14px;
    text-align: center;
    transform: translateY(1px);
  }

  .modal-close {
    right: 18px !important;
    top: 18px !important;
  }

  .modal-content {
    display: flex;
    flex: 1;
    flex-direction: column;

    margin: 0 auto;

    > div {
      display: flex;
      flex-direction: column;
      // row-gap: 50px;
    }
  }

  .box-actions-search-store {
    display: flex;
    flex-direction: column;
    // row-gap: 30px;
  }

  .country-name {
    border: 1px solid #969693;
    display: flex;
    column-gap: 12px;
    font-size: 14px;
    letter-spacing: -0.02em;
    line-height: 17px;
    padding: 0 15px;
    align-items: center;
    height: 49px;
    text-transform: uppercase;

    &::before {
      $sprite: map-get($sprites, 'store-marker-grey');
      background: url($sprite) center no-repeat;
      content: '';
      display: block;
      height: 16px;
      // opacity: 0.3;
      transform: translate(3px, 1px);
      width: 16px;
    }

    @include breakpoint(large) {
      font-size: 12px;
    }
  }

  .bopis-error-message {
    color: #d63b3a;
    letter-spacing: -0.015em;
    line-height: 17px;
    // margin-bottom: -20px;
    margin-bottom: 49px;
    &:not(:empty) {
      margin: 30px 0;
    }
  }

  .stores {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }

  .store-extended-openinghours {
    font-size: 12px;
    margin-bottom: 10px;
    display: none;
  }

  .store-extended-hours {
    padding: 15px 0 20px;
  }

  .store-item {
    display: flex;
    flex-direction: column;
    position: relative;
    // row-gap: 17px;

    &:not(:first-child) {
      border-top: 1px solid lightgray;
      padding-top: 30px;

      input[type='radio'] {
        top: 38px;
      }
    }

    &:not(.expanded) {
      .store-extended-info {
        display: none;
      }
    }
  }

  .store-main-info {
    display: flex;
    flex-direction: column;
    // row-gap: 14px;
  }

  .store-name {
    color: #585856;
    font-size: 14px;
    letter-spacing: 0.05em;
    line-height: 17px;
    text-transform: uppercase;
    order: 1;
    @include breakpoint(large) {
      font-size: 12px;
    }
  }

  input[type='radio'] {
    align-items: center;
    appearance: none;
    border: 1px solid #000;
    border-radius: 50%;
    display: flex;
    height: 17px;
    justify-content: center;
    position: absolute;
    right: 21px;
    top: 23px;
    width: 17px;

    &:checked::after {
      content: '';
      display: block;
      width: 11px;
      height: 11px;
      background: #000;
      border-radius: 50%;
    }
  }

  .store-more-info,
  .store-map button {
    font-size: 12px;
    letter-spacing: -0.02em;
    line-height: 14px;
    order: 5;
    text-align: left;
    text-decoration: underline;
  }

  .store-more-info {
    margin-top: 15px;
    @include breakpoint(large) {
      margin-top: 20px;
    }
  }

  // JILS-459
  .store-address-short,
  .store-phone {
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    @include breakpoint(large) {
      font-size: 12px;
    }
  }

  .store-address-short {
    letter-spacing: 0.02em;
    order: 2;
    margin-top: 16px;
    // transform: translateY(3px);
  }

  .store-avail-message {
    // JILS-459
    // color: rgba(0, 0, 0, 0.25);
    color: #c8c8c4;

    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.01em;
    // transform: translateY(-1px);
    margin-top: 15px;

    @include breakpoint(large) {
      font-size: 12px;
      margin-top: 20px;
    }
  }

  .store-main-info .store-avail-message {
    order: 4;
  }

  .store-phone {
    order: 3;
    // transform: translateY(-2px);
    margin-top: 10px;
  }

  .icon--phone {
    display: none;
  }

  .store-hours {
    order: 6;
    // transform: translateY(1px);

    p {
      color: #000000;
      font-size: 14px;
      letter-spacing: -0.005em;
      line-height: 17px;
      margin: 0;
      @include breakpoint(large) {
        font-size: 12px;
      }
    }
  }

  // .store-map button {
  // transform: translateY(-1px);
  // }

  // .btn {
  //   border: 1px solid #969693;
  //   color: rgba(0, 0, 0, 0.8);
  //   font-size: 12px;
  //   line-height: 14px;
  //   padding: 15px 16px;
  //   text-transform: uppercase;
  //   letter-spacing: 0.065em;
  // }

  // .mm-button {
  //   // background-color: #000;
  //   border: 0;
  //   color: #fbfbf6;
  //   font-size: 14px;
  //   line-height: 17px;
  // }

  /*
  .btn-confirm-address {
    bottom: 30px;
    left: 30px;
    position: absolute;
    width: calc(100% - 60px);
  }
  */

  .modal-confirmation-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  .modal-confirmation-actions {
    display: flex;
    flex-direction: column;
    row-gap: 15px;

    .-solid {
      background: transparent;
      border: 1px solid lightgray;
      color: #000000;
      padding: 15px;
    }
  }
}

.info-col {
  @include breakpoint(medium) {
    .sticky-wrap.sticky-justify-center {
      justify-content: center;
    }
  }
}

.preorder-release-date-pickup {
  color: #c8c8c4;
  margin-top: 15px;
  @include breakpoint(large) {
    margin-top: 20px;
  }
}

#payment-shipping-info {
  .ul-listing {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding-left: 20px;
      position: relative;
      &:before {
        content: '\2013';
        position: absolute;
        left: 6px;
      }
    }
  }
  img {
    margin: 0 10px 0 0;
  }
}

.recommendations {
  .container-carousel {
    display: flex;
    flex-direction: column;
  }

  .carousel {
    padding: 0;
    position: relative;
    z-index: 0;
  }

  h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    display: flex;
    justify-content: center;
  }
}

@include breakpoint(medium up) {
  .product-set-detail {
    .name-price-wrap {
      border: 0;
      .product-look-item {
        display: block;
        margin-top: 8px;
      }
    }

    .product-grid {
      flex-direction: column;
      margin: 0;
      row-gap: 15px;

      .product {
        margin: 0;
        padding: 0;
        width: auto;
      }

      .product-tile {
        align-items: center;
        background: #f9f7ef;
        column-gap: 10px;
        display: flex;
        margin: 0;

        &:not(:hover) {
          .attributes {
            display: none;
          }
        }
      }

      .image-container {
        flex: 0 0 108px;
        margin: 0;
      }

      .tile-body {
        min-width: 0;
      }

      .pdp-link {
        margin: 0;
      }

      .link {
        font-size: 10px;
        text-align: left;
      }

      .price {
        > span {
          justify-content: left;
        }

        span {
          font-size: 10px;
        }
      }

      .attributes {
        flex-flow: column-reverse;
      }

      .swatches {
        column-gap: 8px;
        font-size: 10px;

        > span:first-child {
          display: none;
        }

        a {
          padding: 0;
        }
      }
    }

    .primary-images {
      .js-image-list {
        align-items: center;
        display: flex;
        min-height: calc(100vh - 50px);
      }
    }

    &.no-live-products {
      .sticky-wrap {
        justify-content: center;
      }
    }
  }
}

@include breakpoint(large) {
  .findinstore-modal {
    // JILS-459
    .modal-header {
      border-bottom: 0;
    }

    .modal-content {
      max-width: none;
    }

    .btn {
      align-self: center;
      width: 330px;

      // JILS-459
      font-size: 14px;
      line-height: 17px;
    }

    // JILS-459
    .store-phone a {
      border-bottom: 0;
    }
  }
}

//IBT-4982
.set-items {
  .product-tile .attributes {
    display: flex;
    align-items: flex-start;
  }
}

//IBT-4980
.product-set-detail {
  .info-col .sticky-wrap {
    margin: 0;
  }
  .product-name {
    a {
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }
  }
  .primary-images {
    .carousel-item {
      img {
        cursor: default;
      }
    }
  }
}
//IBT-4981
.product-set-back {
  @include breakpoint(medium) {
    margin-top: 30px;
  }
  a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    text-transform: uppercase;

    &:hover {
      border-bottom: 1px solid #000;
    }
  }
}

#findinstore-modal {
  .store-item {
    .add-to-cart-actions {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 15px 20px 15px;
      border: 1px solid $button-border-gray;
      font-size: rem-calc(12);
      font-weight: 400;
      line-height: rem-calc(17);
      letter-spacing: 0.065rem;
      text-transform: uppercase;
      width: 100%;

      @include breakpoint(large up) {
        padding: 10px 20px;
      }
    }
  }
}
