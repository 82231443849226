@import '../../mixins/mixins';
@import 'plyr/src/sass/plyr';

.video-custom-container {
  .plyr {
    .plyr__video-wrapper {
      margin-top: rem-calc(-1);
    }
  }

  .plyr__tooltip {
    display: none;
  }

  .plyr__control {
    background-color: transparent !important;
  }

  .plyr--full-ui input[type='range']::-webkit-slider-thumb {
    border-radius: 0;
    width: 1px;
  }

  --plyr-color-main: #999;
  --plyr-control-icon-size: 16px;
  --plyr-menu-radius: 1px;
  --plyr-range-track-height: 2px;
  --plyr-progress-loading-size: 60px;

  .plyr--fullscreen-fallback {
    .plyr__video-wrapper {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      max-width: 100% !important;
    }
  }
}

.youtube-container {
  iframe {
    aspect-ratio: 16 / 9;
    width: 100% !important;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.experience-layouts-photoGrid {
  overflow: hidden;

  .swiper {
    position: relative;
    .swiper-button-prev {
      position: absolute;
      display: block;
      top: 45%;
      left: 5%;
      width: 16px;
      height: 16px;
      border: solid #fff;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
      border-color: #000;
      z-index: 1;
    }
    .swiper-button-next {
      position: absolute;
      top: 45%;
      right: 5%;
      display: block;
      width: 16px;
      height: 16px;
      border: solid #fff;
      border-width: 0 1px 1px 0;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      border-color: #000;
      z-index: 1;
    }
  }
}

a.clickableItemContainer {
  color: $black;
  text-decoration: none;

  &:hover {
    color: $black;
    text-decoration: none;
    border-bottom: unset;
  }
}

.page-content-box {
  text-align: center;
  text-transform: uppercase;
  max-width: 460px;
  padding: 0 30px;
  margin: 32px auto 50px;

  .page-intro {
    margin: 0 0 58px;
  }

  p {
    margin: 7px 0;
  }

  .page-intro,
  p
   {
    @include breakpoint(medium down) {
      font-size: rem-calc(14);
    }
  }
}

.page-content-full {
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.panel-gray {
  background-color: $off-white;
}

.space-title {
  margin: 1.3em 0 10px;
}

.space-paragraph {
  letter-spacing: 0.08em;
  line-height: 1.4em;
  margin: 10px;
}

.project-content {
  margin-left: auto;
  margin-right: auto;

  @include breakpoint(large) {
    width: 480px;
  }

  @include breakpoint(xlarge) {
    width: 640px;
  }
}

p,
.richtext {
  &.title {
    text-align: center;
    font-size: 18px;
    line-height: 1.5em;
    letter-spacing: 0.08em;
    margin-top: 18px;
    margin-bottom: 18px;
    padding-left: 30px;
    padding-right: 30px;

    &-project p {
      font-size: 17px;
      line-height: 1.5em;
      letter-spacing: 0.02em;
      -webkit-font-smoothing: auto;
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: 15px;

      @include breakpoint(small only) {
        padding-bottom: 60px;
        letter-spacing: 0.32px;
        line-height: 24px;
      }
    }
  }

  &.subtitle-project p {
    font-size: 9px;
    letter-spacing: 0.08em;
    line-height: 1.5em;
    margin-top: 0;
    margin-bottom: 0;

    @include breakpoint(small only) {
      font-size: 11px;
      letter-spacing: 0.88px;
      line-height: 16.5px;
    }
  }

  &.huge {
    @include breakpoint(small only) {
      padding-left: 30px;
      padding-right: 30px;
    }

    font-size: 16px;
    line-height: 1.5em;
    letter-spacing: 0.02em;
    margin-top: 18px;
    margin-bottom: 18px;

    @include breakpoint(xlarge) {
      font-size: 17px;
    }

    p {
      margin: 0%;
    }
  }

  &.intro {
    padding-top: 120px;
    padding-bottom: 60px;
    border-bottom: 1px solid #000;
    margin-bottom: 30px;
    margin-top: 0;

    @include breakpoint(small only) {
      padding-left: 0;
      padding-right: 0;
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  &.blockquote {
    padding-top: 90px;
    padding-bottom: 90px;
    margin-left: -32px;
    margin-right: -32px;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    text-align: center;

    @include breakpoint(small only) {
      margin-left: 0;
      margin-right: 0;
      font-size: 14px;
      letter-spacing: 1.12px;
      line-height: 24px;
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  &.quote {
    padding: 32px 0 15px 32px;
    margin-top: 0;

    @include breakpoint(small only) {
      padding: 32px 30px 15px 62px;
    }
  }
}

.past-season-slider {
  position: fixed;
  top: 0%;
  left: 0%;
  bottom: 0%;
  width: 100%;
  z-index: 2;

  .static-logo {
    position: absolute;
    left: 0%;
    top: 0%;
  }

  .grid-container {
    height: 100%;
    display: flex;

    .home-grid {
      margin: auto;
      width: 100%;
    }
  }

  .close-slider {
    text-align: center;
    position: relative;
    z-index: 3;

    a:hover {
      text-decoration: underline;
    }
  }

  .slider-container {
    @include breakpoint(large) {
      height: unset;
      max-width: 480px;
    }

    /* @include breakpoint(xlarge) {
            min-height: 640px;
        } */

    .pd-slider {
      position: relative;
      height: 100%;

      .photo-grid {
        margin: 0% !important;
        height: 100%;
      }

      .owl-carousel .owl-item img {
        max-height: 480px;
        width: auto;
      }
    }

    .black .owl-nav button {
      border-color: $black;
    }

    .owl-nav button span {
      display: none;
    }
  }

  .photo-grid {
    .owl-stage-outer {
      margin-right: -17px;
    }
  }

  .photo-tile-container {
    display: flex;
    justify-content: center;
  }
}

.photo-grid {
  @include breakpoint(medium) {
    display: grid;
    grid-gap: 20px;
    margin: 0 60px 60px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include breakpoint(xlarge) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  a {
    &:hover {
      border-bottom: 0;
    }
  }

  .content-video {
    position: relative;
    width: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    [data-video] {
      padding-bottom: 139%;
    }
  }
}

// align captions under images on photo-grid (P-D feature)

.pd-default-photo-grid {
  .item-container {
    height: 100%;

    .content {
      height: 100%;

      .experience-content {
        height: 100%;

        .experience-assets-photoTile {
          height: 100%;

          .photo-tile-container {
            height: 100%;
            display: flex;

            picture {
              margin: auto;
            }
          }
        }
      }
    }
  }
}

[data-video] {
  position: relative;
  z-index: 1;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background-color: transparent;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .player-bar {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    height: 20px;
    z-index: 100;
    display: flex;
    justify-content: space-between;

    svg {
      width: 20px;
      height: 20px;
      fill: #fff;
      stroke-width: 2;
      stroke: #fff;

      &.pause {
        display: none;
      }

      &.small {
        display: none;
      }
    }
  }

  .play-btn,
  .fullscreen-btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .seeker-wrapper {
    flex-grow: 2;
    margin: 0 20px;
    height: 20px;
    position: relative;
    cursor: text;

    &::after {
      content: '';
      background-color: #fff;
      height: 1px;
      top: 10px;
      position: absolute;
      left: 0;
      right: 0;
    }

    .seeker-btn {
      width: 20px;
      position: absolute;
      height: 20px;
      top: 0;
      margin-left: -10px;
      cursor: col-resize;
      z-index: 100;

      &::after {
        content: '';
        width: 1px;
        background: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 10px;
      }
    }
  }

  &.video-playing,
  .video-playing {
    .play-btn {
      svg {
        &.pause {
          display: block;
        }

        &.play {
          display: none;
        }
      }
    }
  }

  &.video-fullscreen,
  .video-fullscreen {
    .fullscreen-btn {
      svg {
        &.small {
          display: block;
        }

        &.full {
          display: none;
        }
      }
    }
  }
}

.fullscreenEnabled[data-video] {
  /*
    necessary to catch mousemove event,
    because the iframe enclose his events
     */
  iframe {
    pointer-events: none;
  }
}

#modal-video {
  z-index: 3;

  .modal-close {
    position: absolute;
    top: 13%;
    right: 5%;
    cursor: pointer;
    padding: rem-calc(10);
    z-index: 10;

    svg {
      width: rem-calc(10);
      height: rem-calc(10);
    }
  }

  .modal-overlay {
    z-index: 1;
    background-color: $off-white;
  }

  @include breakpoint(xxlarge) {
    .owl-next {
      right: 30%;
    }

    .owl-prev {
      left: 30%;
    }
  }

  .content-video {
    position: relative;
    width: 100%;

    img {
      position: absolute;
      top: 0;
      left: 3px;
      z-index: 2;
      transform: translateX(170%);
    }

    @include breakpoint(small only) {
      [data-video] {
        padding-bottom: 139%;
        width: 100%;
      }
    }

    @include breakpoint(medium) {
      [data-video] {
        transform: translateX(24%);
        padding-bottom: 95%;
        width: 69%;
      }

      [data-video],
      img {
        transform: translateX(24%);
      }
    }

    @include breakpoint(large) {
      [data-video] {
        transform: translateX(32%);
        padding-bottom: 85%;
        width: 61%;
      }

      [data-video],
      img {
        transform: translateX(32%);
      }
    }

    @include breakpoint(xlarge) {
      [data-video] {
        transform: translateX(67%);
        padding-bottom: 60%;
        width: 43%;
      }

      [data-video],
      img {
        transform: translateX(67%);
      }
    }

    @include breakpoint(xxlarge) {
      [data-video] {
        transform: translateX(170%);
        padding-bottom: 32%;
        width: 23%;
      }

      [data-video],
      img {
        transform: translateX(170%);
      }
    }
  }
}

.logo-small {
  svg {
    height: 18px;
  }
}

.show {
  display: block !important;
}

// generate helper classes to set padding on page-designer

@each $size in $padding-sizes {
  @include breakpoint(small down) {
    @each $direction in $padding-directions {
      .padding#{$direction}-small-#{$size} {
        padding#{$direction}: #{$size}rem !important;
      }
    }

    .padding-horizontal-small-#{$size} {
      padding: 0 #{$size}rem !important;
    }

    .padding-vertical-small-#{$size} {
      padding: #{$size}rem 0 !important;
    }
  }

  @include breakpoint(medium) {
    @each $direction in $padding-directions {
      .padding#{$direction}-desktop-#{$size} {
        padding#{$direction}: #{$size}rem !important;
      }
    }

    .padding-horizontal-desktop-#{$size} {
      padding: 0 #{$size}rem !important;
    }

    .padding-vertical-desktop-#{$size} {
      padding: #{$size}rem 0 !important;
    }
  }
}

.pd-slider {
  .owl-widget.owl-carousel .owl-item {
    margin: 0 -2px 0 2px;
  }
}

.button.pd-button {
  padding: 12px 20px 12px;
}

.pd-link-underline {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  &:hover {
    border-bottom: 1px solid #000;
  }
}

.pd-link-no-underline {
  border: none;
  &:hover {
    border-bottom: none;
  }
}
