footer {
  padding: 15px 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 200px;

  .footer-menu {
    .footer-newsletter {
      padding-bottom: 48px;

      .newsletter-page-wrapper {
        width: auto;
        min-height: auto;
        margin-top: 0;
        padding: 0;
        align-items: normal;
      }

      .newsletter-box-label-secondary {
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0.91px;

        span {
          display: none;
        }
      }

      .newsletter-feedback > div {
        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0.91px;
        text-transform: lowercase;

        &::first-letter {
          text-transform: capitalize;
        }
      }

      .checkbox-label.terms,
      .newsletter-success-text,
      .marketing-policy {
        font-size: 9px !important;
        line-height: 15px !important;
        letter-spacing: 0.88px !important;
      }

      .go-to-home {
        display: none;
      }

      .newsletter-submit ~ .spacer {
        display: none;
      }
    }

    .footer-links {
      padding-bottom: 24px;

      .footer-accordion-content {
        .editorial-footer {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        .single-footer-section {
          .button-icon {
            width: 14px;
            height: 14px;
          }

          .icon--minus-new {
            display: none;
          }

          &.is-active {
            .icon--plus-new {
              display: none;
            }
  
            .icon--minus-new {
              display: block;
            }

            .accordion-content {
              padding-top: 15px;
            }
          }

          .title-section-footer {
            font-size: 12px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0.91px;
          }

          .unstyled-list {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .footer-list-item {
              a {
                font-size: 9px;
                line-height: 15px;
                letter-spacing: 0.88px;

                p {
                  all: unset;
                }
              }
            }
          }

          .button-open-footer {
            display: flex;
            padding: 0;
          }

          & >.title-section-footer {
            display: none;
          }
        }
      }
    }
  }

  .footer-links-otb {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.88px;
    text-transform: uppercase;

    li {
      padding: 0;
    }
  }

  @include breakpoint(small only) {
    justify-content: flex-end;
    margin-top: 100px;
    min-height: 420px;
  }
}

.footer-links {
  .d-lg-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.4;
    letter-spacing: 0.08em;
    margin-bottom: 40px;

    @include breakpoint(small only) {
      display: none;
    }

    .content-asset {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    ul {
      display: flex;

      @include breakpoint(small only) {
        flex-direction: column;
        align-content: center;
      }
    }

    li {
      padding: 0;
    }

    a {
      display: inline-block;
      vertical-align: middle;
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 0.08em;
      line-height: 1.4em;
      border-bottom: 1px solid transparent;
      margin: 4px 12px;
      -webkit-font-smoothing: auto;

      &.jils-link {
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        text-decoration: none;
      }

      &:hover {
        border-color: #000;
      }

      @include breakpoint(small only) {
        padding: 10px;
        text-align: center;
        font-size: rem-calc(11);
      }
    }
  }
  .d-lg-none {
    text-transform: uppercase;
    font-size: 11px;
    line-height: 1.5em;
    letter-spacing: 0.08em;
    font-weight: 400;
    text-align: center;
    -webkit-font-smoothing: auto;

    @include breakpoint(medium) {
      display: none;
    }

    a {
      padding: 10px;
      display: block;
      text-decoration: none;
      border: none;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        padding: 0;
      }
    }

    .content-asset > ul,
    > ul {
      margin-bottom: 30px;

      > li {
        ul {
          display: none;
          padding: 10px 0 20px;
        }

        &.active {
          a {
            span {
              border-bottom: 1px solid;
            }
          }

          > ul {
            display: block;
          }
        }
      }
    }
  }
}

.footer-logo {
  width: 100%;
  height: 14vw;
  margin-bottom: 6px;

  @include breakpoint(small only) {
    padding-top: 10px;
    margin-bottom: 14px;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}
