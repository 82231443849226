// #account-profile,
// #account-password {
//     .inner-box {
//         margin-top: 0;
//     }

//     .card {
//         width: 100%;

//         .card-header {
//             border-bottom: 1px map_get($jil-palette, "black") solid;
//             padding-bottom: 13px;
//             margin-bottom: 40px;
//             letter-spacing: 0.02em;

//             .action {
//                 @include card-header-action;
//             }
//         }
//     }

//     .form-group {
//         margin-top: 22px;

//         &.required {
//             label::after {
//                 content: "*";
//             }
//         }
//     }

//     button[name="save"] {
//         margin-top: 30px;
//     }

//     .info-required {
//         color: map_get($jil-palette, "dark-gray");
//     }
// }

// #account-password {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     min-height: calc(100vh - 220px);

//     .form-group {
//         @include password-toggle();
//     }
// }

// #account-profile {
//     .fields-select {
//         display: flex;
//         width: 100%;

//         label {
//             margin-right: 20px;
//             margin-top: 22px;

//             &:last-child {
//                 margin-right: 0;
//             }
//         }
//     }

//     .form-group {
//         @include password-toggle();

//         &-change-password {
//             margin-top: 6px;
//         }

//         &.spaced {
//             margin-top: 80px;
//             margin-bottom: 32px;

//             @include breakpoint(medium up) {
//                 margin-top: 40px;
//                 margin-bottom: 22px;
//             }
//         }
//     }

//     .newsletter-interest-box {
//         padding: 14px 20px 0;

//         .checkbox-label {
//             line-height: normal;
//         }

//         .form-field {
//             margin-left: 14px;
//             margin-right: 0;
//         }
//     }
// }

//

.address-data-page {
  .fields-select {
    margin-top: 22px;
    margin-bottom: 0 !important;
    width: 100%;

    > div {
      display: flex;
    }

    label {
      margin-right: 20px;
      margin-top: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
