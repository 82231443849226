.page-storelocator {
  padding: 83px 0 0;

  h1,
  h2,
  h3 {
    font-size: 12px;
  }

  .store-box-info {
    font-size: 12px;
  }

  .reset-filter-stores-by-country {
    font-size: 12px;
  }
  .countries {
    row-gap: 17.5px;

    .filter-stores-by-country {
      &::before {
        transform: translate(-1px, -1px);
      }
    }

    li {
      font-size: 12px;
    }
  }

  &-detail {
    height: auto;
    min-height: 100vh;
    justify-content: center;
    padding-left: 0;

    section {
      &:first-child {
        margin-bottom: 10px;
      }
    }

    .container {
      margin-bottom: 12px;
    }
  }
}
