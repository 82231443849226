.order-return-wrapper {
  @include breakpoint(large up) {
    max-width: 330px;
    margin: 0 auto;
  }
  .invalid-feedback {
    &.error-message {
      background: none;
      margin: 0;
    }
  }
  .default-steps-container {
    display: none;
  }
  .desktop-steps-container {
    display: block;
  }
  .order-date {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    &-label {
      text-transform: uppercase;
      margin: 0;
    }
    &-value {
      color: #757575;
      margin: 0;
    }
  }
  .order-number-back-to-orders {
    display: flex;
    justify-content: space-between;
    .order-number-label {
      margin-bottom: 0;
    }
    .back-to-my-orders {
      border: none;
      color: #969693;
      font-size: 14px;
      text-decoration: underline;
      &:hover {
        border: none;
      }
    }
  }
  .what-to-do-question {
    margin-bottom: 16px;
  }
  .return-exchange-detail {
    margin: 12px 0 30px;
    &.returned-quantity-label {
      margin-top: 30px;
    }
  }
  .line-item-attribute {
    display: flex;
    font-size: 14px;
    margin: 0 0 10px;
    padding: 0;
    .value-field {
      align-items: center;
      display: flex;
      margin-left: 4px;
      .previous-attribute-icon {
        width: 12px;
        display: inline-block;
        height: 12px;
        margin: 0 8px;
      }
    }
    .quantity {
      display: flex;
      padding: 0;
    }
    &.show-new-label {
      .attribute-name {
        text-transform: lowercase;
      }
    }
  }
  .-jils-return-header-section {
    font-size: 14px;
  }
  .product-image {
    max-width: 94px;
    height: 143px;
  }
  .select-element-wrapper {
    margin: 30px 0;
    position: relative;
    select {
      background: none;
      border: none;
      border-bottom: 1px solid #c9c9c5;
      height: auto;
      padding: 0 0 10px;
      width: 100%;
    }
    .select-icon {
      position: absolute;
      right: 0;
      top: 5px;
      z-index: -1;
    }
  }
  .return-error {
    margin-top: 0;
    &.invalid-feedback {
      margin: -10px 0;
    }
  }

  .order-step-content {
    .question-note {
      margin-bottom: 30px;
    }
    .option-desc {
      display: none;
    }

    .product-main-info {
      &.product-main-info--final-sale,
      &.product-main-info--not-returnable {
        .product-title,
        .product-prices-wrapper,
        .details-attribute-box,
        .return-options {
          color: #969693;
        }

        .mini-cart-item-link {
          pointer-events: none;
        }
      }
    }
  }
  .next-step-button {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #000;
    height: 49px;
    margin-top: 1.5rem;
    width: 100%;
    text-transform: uppercase;
    &.step-2 {
      margin-top: 0;
    }
  }
  .product-line-item {
    border-bottom: 0;
    p {
      margin-bottom: 0;
    }
  }
  .product-color-size-wrapper {
    margin: 0 0 13px;
  }
  .return-confirmation-page {
    .return-confirmation-content {
      margin: 229px 30px 0;
      p {
        text-align: center;
      }
    }
    .return-confirmation-button {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
    .action-buttons-wrapper {
      display: none;
    }
  }
}

.return-content {
  .total-steps,
  .status-description-next-step,
  .o-progress-circle__fill {
    display: none;
  }

  .order-step {
    margin: 8px 0;
    @include breakpoint(large down) {
      padding: 0 30px 24px;
    }

    .horizontal-row {
      width: 100%;
      border-top: 1px solid #c9c9c5;
      &.upper-title-row {
        margin: 10px 0 30px;
      }
      &.product-inner-separator {
        display: none;
      }
      &.upper-return-produtc-row {
        display: none;
      }
    }

    .return-type-label {
      margin-left: 0.5rem;
      .option-title {
        text-transform: none;
      }
    }

    .step-subtitle {
      display: none;
    }

    .current-step-title {
      margin-bottom: 1.75rem;
      text-transform: uppercase;
    }

    .return-quantity-desktop {
      display: none;
    }

    .variation-attributes {
      ul {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        padding-left: 0;
        margin-top: 12px;
        .variation-attribute {
          padding: 0;
          .product-variation-attribute {
            &.unic-element {
              margin-top: 0;
              display: none;
              height: 0;
              opacity: 0;
            }
            &.color {
              order: 1;
            }
          }
        }
      }
    }
  }

  .card-body {
    display: none;
  }

  .return-options-box {
    &.visible {
      display: block;
    }
  }
}

.o-progress-circle,
.status-circle {
  align-items: center;
  background: #e1e1dc;
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  width: 30px;
  color: rgba(0, 0, 0, 0.15);
  &.active {
    color: #000;
    background: #fbfbf6;
    border: 1px solid #000;
  }
  &.completed {
    background-color: #000000;
    color: #fff;
  }
}

.js_order-step2 {
  display: none;
}
.js_order-step3 {
  display: none;
  .default-steps-container {
    .status-description-title {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .section-title {
    font-size: 12px;
    margin: 30px 0 15px;
  }

  .return-confirmation-product-bottom {
    display: none;
  }
}

.confirm-return-note {
  margin-top: 2rem;
  display: none;
}

.return-confirmation-content {
  .return-confirmation-title {
    margin: 16px 0;
    padding: 0 16px;
    text-transform: uppercase;
  }
  .return-confirmation-img {
    img {
      height: 100px;
    }
  }
}

.page-account-content {
  &.not-logged {
    justify-content: center;
    .return-content {
      .order-step {
        .desktop-steps-container {
          .col-4 {
            .col-3 {
              margin-right: 8px;
              max-width: 48px;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.description-box {
  margin-top: 6px;
  width: 90px;
  .status-description {
    text-align: center;
    color: #bfbfbb;
    p {
      font-size: 12px;
    }
    &.active {
      color: #585856;
      text-decoration: underline;
    }
    &.completed {
      color: #585856;
      text-decoration: none;
    }
  }
}

.steps-flex-box {
  display: flex;
  justify-content: space-between;
  margin: 76px 30px 68px;
  position: relative;

  &::before {
    background-color: #000000;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    top: 15px;
    width: 100%;
    z-index: -1;
  }
  .col-4 {
    > .row {
      z-index: 1;
      width: 30px;
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    .status-circle {
      p {
        margin-bottom: 0;
      }
    }
  }
}

.select-where-to-return {
  .form-group {
    display: flex;
    flex-direction: column;
    .radio {
      display: flex;
    }
  }
}

.jils-squared-radio {
  margin-bottom: 18px;
  display: flex;
  input {
    align-self: center;
    vertical-align: middle;
    box-shadow: 0 0 0 4px transparent;
    transition-timing-function: ease-out;
    transition-duration: 75ms;
    transition-property: box-shadow background-color background-position;
    appearance: none;
    color: #000;
    width: 15px;
    height: 15px;
    border: 1px solid #000;
    cursor: pointer;
    flex-shrink: 0;
    border-radius: 0;
    &:checked {
      &::after {
        content: '';
        background: #000000;
        display: block;
        height: 9px;
        margin: auto;
        margin-top: 2px;
        width: 9px;
      }
    }
  }
}

.return-privacy-content {
  margin: 12px 0;
}

.quantity-semicolon {
  display: block;
}

.mm-hidden-el {
  display: none !important;
}

.order-download {
  a {
    margin-top: 2rem;
    width: 100%;
  }
}

.return-error-visible {
  &.invalid-feedback {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
  }
}

.order-detail-data-container {
  .line-totals-item.cart-duties-tax {
    .duties-tax-label,
    .tax-total {
      align-items: center;
      display: flex;
    }
  }
}

.select-return-change-type {
  display: flex;
  position: relative;
  .return-option {
    margin-right: 32px;
  }
  .return-exchange-wrapper-fake,
  .return-reason-option {
    left: 0;
    position: absolute;
    top: 30px;
    width: 100%;

    .size-guidelines-button {
      display: none;
    }
  }
}
