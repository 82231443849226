#login {
  height: 100vh;
  justify-content: center;

  @include breakpoint(small only) {
    padding-top: 160px;
  }

  @include breakpoint(large) {
    padding-top: 20px;
  }

  @include breakpoint(xlarge) {
    padding-top: 30px;
  }
}

.inner-container {
  display: flex;
  justify-content: center;
  @include breakpoint(large down) {
    flex-direction: column;
  }

  @include breakpoint(xlarge) {
    max-width: 820px;
    width: 100%;
  }

  &-column {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      width: 100%;
      margin: 0;
    }
  }
}

.inner-box {
  box-sizing: border-box;
  margin: 0 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  &.registration,
  &.login {
    padding: 0;
    padding-bottom: 30px;
    margin: 0;
    @include breakpoint(large only) {
      width: 392px;
    }

    .checkout-login & {
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
      @include breakpoint(medium up) {
        max-width: 400px !important;
        width: 392px;
      }
    }
    .checkout-login-new-customer & {
      padding-bottom: 0;
      margin-top: 0;
      margin-bottom: 0;
      @include breakpoint(medium up) {
        max-width: 400px !important;
        width: 392px;
      }
    }

    .checkout-login-flex & {
      justify-content: flex-start;
    }
  }

  @include breakpoint(medium up) {
    max-width: 320px;
  }

  @include breakpoint(small only) {
    padding: 30px;
    margin-top: 0;
  }

  &.box-divider {
    border-top: 1px solid #000;
    padding-top: 60px;
  }

  @include breakpoint(small only) {
    p,
    input[type='checkbox'] + label,
    label .checkbox-label,
    .newletter-preference,
    select {
      font-size: 14px;
    }
  }
}
.checkout-login-wrapper {
  margin-bottom: 30px;
  padding: 0 30px;
  .jils-button {
    border: 1px solid $light-gray;
    height: 38px;
    width: 140px;
  }
  .title {
    margin-bottom: 15px;
  }
  .subtitle {
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 22px;
  }
  @include breakpoint(xlarge) {
    form {
      width: 332px !important;
    }
    margin: 0;
  }

  &.guest-checkout-box {
    margin: 0;
    padding: 20px 30px;
    .checkout-login-title {
      margin-bottom: 31px;
    }
    @include breakpoint(xlarge) {
      padding-top: 0;
      .checkout-login-title {
        margin-bottom: 46px;
      }
    }
  }

  .checkout-login-title {
    margin-bottom: 46px;
  }
}
.checkout-login-box {
  .guest-login-form-wrapper {
    width: inherit;
  }
  @include breakpoint(xlarge) {
    max-width: 400px !important;
    width: 392px;
  }
}

.login-box {
  .register-box {
    padding-left: 0;
    padding-right: 0;
    max-width: 320px;
  }
}

.form-container {
  justify-content: center;
}

// .item-button-register {
//   margin-top: 18px;
// }

.spacer-header {
  margin-top: 140px;
}

.checkout-login {
  align-items: flex-start;
  min-height: calc(100vh);
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 60px;

  @include breakpoint(xlarge) {
    align-items: center;
  }

  // .mm-form-message {
  //   display: none;
  // }

  .checkout-login-login {
    display: none;
  }

  .checkout-login-row {
    margin-top: 8px;
  }

  // .checkout-login-forgot-pwd {
  //   display: none;
  // }

  @include breakpoint(small only) {
    padding-top: 50px;

    .login {
      // padding-bottom: 30px !important;
      width: auto;
    }

    .item-input {
      line-height: 1.5;
      margin: 0;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    // .item-button > * {
    //   margin-bottom: 12px;
    // }
  }

  .register-box {
    display: none;
  }

  p {
    margin-bottom: 0;
  }

  .inner-container {
    padding: 30px 0;

    @include breakpoint(large down) {
      padding: 0;
    }
  }
}

.guest-checkout-box {
  // @include breakpoint(medium up) {
  //   max-width: 400px !important;
  //   width: 392px;
  // }
  @include breakpoint(medium down) {
    margin-left: 30px;
  }

  @include breakpoint(large down) {
    border-left: none;
    padding-top: 60px;
    padding-left: 0;
    padding-right: 0;
    // max-width: 320px;
    margin-top: 30px;
  }
  @include breakpoint(xlarge) {
    // border-top: 1px solid $primary-color;
    max-width: 400px !important;
    width: 392px;
  }

  @include breakpoint(small only) {
    padding-bottom: 0;
    padding-right: 30px;
  }

  &.inner-box {
    @include breakpoint(xlarge) {
      max-width: 380px;
    }
  }

  .card-body {
    @include breakpoint(large down) {
      margin-top: 20px;
    }

    @include breakpoint(small only) {
      margin-top: 16px;
    }
  }

  .guest-checkout-text {
    padding: 1em 0;

    @include breakpoint(medium up) {
      padding: 21px 0;
    }
  }
}

.profiling-policy {
  color: $darkgrey;
  font-weight: $global-weight-bold;
  position: relative;
  font-size: 11px;

  &-popup {
    border: 1px solid $black;
    background: $off-white;
    padding: 10px;
    width: 215px;
    position: absolute;
    top: 40px;
    z-index: 1;
    display: none;

    @include breakpoint(medium up) {
      width: 300px;
      left: -15px;
      top: 25px;
    }

    .policy-popup-content {
      position: relative;

      .policy-popup-text1,
      .policy-popup-text2,
      .policy-popup-text3 {
        font-size: 10px;
        margin-bottom: 1rem;
      }
    }

    .policy-popup-tail-border {
      position: absolute;
      top: -30px;
      border: 10px solid;
      border-color: transparent $black transparent transparent;
      width: 0;
      height: 0;
      transform: rotate(90deg);

      @include breakpoint(small down) {
        right: 40px;
      }
    }

    .policy-popup-tail {
      position: absolute;
      top: -29px;
      border: 10px solid;
      border-color: transparent $off-white transparent transparent;
      width: 0;
      height: 0;
      transform: rotate(90deg);

      @include breakpoint(small down) {
        right: 40px;
      }
    }
  }
}

.newletter-preference {
  margin-top: 22px;
  margin-left: 20px;
  display: none;

  label {
    &:first-child {
      margin-left: 10px;
    }
  }
}

.account-thankyou {
  height: 100vh;
  width: 320px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include breakpoint(small only) {
    height: calc(100vh - 175px);
    font-size: 14px;
    width: 100%;
    padding: 0 30px;
  }

  a {
    margin-top: 20px;
  }
}

.checkout-register-email-label {
  flex: 1 1 auto;
}

/* .checkout-login-returning-customer, .checkout-login-create-account-desktop, .checkout-login-create-account {
    @include breakpoint(small only) {
        width: 220px;
    }
    width: 192px;
} */

.checkout-login-returning-customer,
.checkout-login-create-account,
.checkout-login-create-account-desktop {
  width: 49%;
  // @include breakpoint(xlarge) {
  //   width: 49%;
  // }
  margin: 0;
  @include breakpoint(small only) {
    // width: 80%;
    width: 100%;
  }
}

.item-button-checkout-login {
  width: 100%;
}

.inner-container-checkout-login {
  width: 100vw;

  @include breakpoint(large down) {
    margin-top: 59px;
    max-width: 456px !important;
    // width: 392px;
  }
  @include breakpoint(xlarge) {
    max-width: 1000px;
    width: 100%;
  }
}

.checkout-login-email,
.checkout-login-email-desktop,
.checkout-login-password {
  @include breakpoint(xlarge) {
    // max-width: 80% !important;
    max-width: 100% !important;
  }
}

.card-body-guest {
  @include breakpoint(medium down) {
    width: 80%;
  }
}

.checkout-as-guest {
  @include breakpoint(medium down) {
    width: 100%;
  }
}

.remember-me-section {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
  .remember-me-flag {
    display: flex;
  }
}

.checkout-login-buttons-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  button {
    margin: 0;
    // &.login-submit {
    //   margin: 0 0 15px;
    // }
  }
  .jils-link {
    margin: 0;
    width: auto;
  }

  &.--columns {
    flex-direction: column;

    .jil-button {
      & + .jil-button {
        margin-top: 10px;
      }
    }
  }
}
.guest-login-form-wrapper {
  .checkout-login-buttons-wrapper {
    margin: 16px 0 0;
  }
  .item-input.jils-form-input {
    margin-bottom: 0;
  }
}

.box-separator {
  @include breakpoint(large down) {
    border-top: 1px solid $primary-color;
    margin: 8px 30px 18px;
  }

  @include breakpoint(xlarge) {
    height: inherit;
    border-left: 1px solid $primary-color;
    margin: 0 60px;
  }
}

.checkout-login {
  &.login-page {
    flex-direction: column;
    max-width: 380px;
    margin: 0 auto;
    .inner-container-checkout-login {
      max-width: auto;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }

    .box-separator {
      border: none;
      border-top: 1px solid #000;
      margin: 60px 30px;
      width: auto;
      padding: 0 30px;
    }

    .checkout-login-wrapper {
      .checkout-login-title {
        margin-bottom: 0;
      }
    }
    .checkout-login-title {
      letter-spacing: 0.02em;
      font-weight: 400;
      font-size: 14px;
      text-transform: none;
    }
  }
}

.login-title {
  letter-spacing: 0.02em;
  font-weight: 400;
  font-size: 14px;
  text-transform: none;
}

.journey-start {
  padding: 50px 0 0;
}

.page-registration {
  // IBT-4839
  .inner-box {
    max-width: 330px;
  }
}
