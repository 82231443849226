$modalMaxWidth: 450px;
// $modalMaxHeight: 740px;
// $modalMaxHeight: 67vw;
$modalMaxHeight: 81vh;
$modalMaxHeightScrollbar: 70vh;

$font-sm: 10px;

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(50%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-15%);
  }
}

.modal-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $lightest-gray;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
  z-index: 1000;

  @include breakpoint(small only) {
    display: block;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .modal-content,
  .modal-background {
    padding: 30px;
    width: 100%;
    // max-width: 640px;
    max-width: $modalMaxWidth;
  }

  .modal-background {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    transition: all 0.8s ease-in-out;
    background: #f9f7ef;

    @include breakpoint(medium up) {
      overflow: hidden;
      overflow-y: auto;
      // max-height: 90vh;
      max-height: $modalMaxHeight;
      -webkit-overflow-scrolling: touch;
    }

    @include breakpoint(small only) {
      position: relative;
      min-height: 100%;
      max-height: none;
    }
  }

  .modal-content {
    overflow: auto;
  }

  &.is-open {
    opacity: 1;
    pointer-events: auto;

    .modal-background {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .modal-close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 10px;
    height: 10px;
    svg {
      width: 10px;
      height: 10px;
    }
  }

  .filter-wrap .filter-panel {
    padding-top: 0;
  }
  @include breakpoint(large up) {
    .filter-wrap .filter-panel {
      min-width: auto;
    }
    // .filter-items {
    //     //max-width: 450px;
    // }
    &.filter-modal,
    &.findinstore-modal {
      .modal-content {
        max-width: $modalMaxWidth;
        max-height: $modalMaxHeight;
      }
    }

    &.findinstore-modal {
      .modal-content {
        max-height: $modalMaxHeightScrollbar;
      }
    }

    .modal-content {
      font-size: $font-sm;
      .refinement {
        font-size: $font-sm;
      }
      // .swatch-circle {
      //     width: 10px;
      //     height: 10px;
      // }
    }
    // Close icon
    &.filter-modal .modal-close svg {
      width: 10px;
      height: 10px;
    }
    // Modal width
    &.filter-modal .modal-content {
      max-width: $modalMaxWidth;
      width: $modalMaxWidth;
    }
    // Remove modal top border
    .filter-wrap .filter-panel .filter-items {
      border-top: 0;
    }
    .filter-apply {
      padding-top: 30px;
    }
    .medium-screen-modal {
      .js-modal-container {
        max-width: $modalMaxWidth;
        // max-height: $modalMaxHeight;
      }
    }
  }
}

.fullscreen-modal {
  .js-modal-container {
    max-width: $modalMaxWidth;
    @include breakpoint(small only) {
      max-width: 100%;
    }
  }
}
.modal-include-wrapper {
  &.-sticky-modal-header {
    .modal-close {
      display: none;
      opacity: 0;
    }
    .modal-header {
      align-items: center;
      background: #f9f7ef;
      display: flex;
      justify-content: center;
      height: 55px;
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 2;
      .modal-close {
        display: block;
        height: 10px;
        opacity: 1;
        position: absolute;
        right: -15px;
        top: 15px;
        width: 10px;
      }
    }
    .js-modal-container {
      padding-top: 0;
    }
    &.filters-modal-wrapper {
      .modal-header {
        .modal-close {
          right: 15px;
          // @include breakpoint(large up) {
          //   right: 32px;
          // }
        }
      }
    }
  }
  .modal-title {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.8px;
    text-align: center;
    text-transform: uppercase;
  }
}

#payment-delete,
#delete-address-modal {
  .js-modal-container {
    // max-width: 389px;
    max-width: $modalMaxWidth;
    @include breakpoint(small only) {
      max-width: 100%;
    }
  }
}

.findinstore-modal {
  .modal-confirmation-actions {
    margin-top: 30px;
  }
}

// Hide Header on StoreFinderModal (mobile)
.nav-mobile.headspace {
  &.hide-header-store-finder {
    @include breakpoint(small only) {
      z-index: 0;
    }
  }
}