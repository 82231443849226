.tooltip-global-container {
  align-items: center;
  display: flex;
  left: 8px;
  position: relative;
  width: min-content;

  .tooltip-toggler {
    height: 1rem;
    width: 1rem;
    position: relative;
    z-index: 1;
    i, svg {
      height: 1rem;
      width: 1rem;
    }
  }

  .tooltip-box {
    bottom: 27px;
    display: none;
    left: -16px;
    position: absolute;
    z-index: 1;

    &.visible {
      display: block;
    }
    .tooltip {
      background-color: #fbfbf6;
      border: 1px solid $medium-gray;
      border-radius: 4px;
      box-shadow: 0 0 6px -3px black;
      height: auto;
      width: 200px;

      .tooltip-content {
        color: black;
        padding: 16px;
      }

      .tooltip-tail {
        border: 10px solid;
        border-color: transparent #fbfbf6 transparent transparent;
        height: 0;
        left: 12px;
        position: absolute;
        top: calc(100% - 1px);
        transform: rotate(270deg);
        width: 0;
      }
      .tooltip-tail-2 {
        border: 10px solid;
        border-color: transparent $medium-gray transparent transparent;
        height: 0;
        left: 12px;
        position: absolute;
        top: 100%;
        transform: rotate(270deg);
        width: 0;
      }
    }
  }
}
