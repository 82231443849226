footer {
  padding: 15px 15px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 200px;

  @include breakpoint(small only) {
    justify-content: flex-end;
    margin-top: 30px;
    min-height: 420px;
  }
}

.footer-links {
  .d-lg-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.4;
    letter-spacing: 0.08em;
    margin-bottom: 40px;

    @include breakpoint(small only) {
      display: none;
    }

    .content-asset {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    ul {
      display: flex;

      @include breakpoint(small only) {
        flex-direction: column;
        align-content: center;
      }
    }

    li {
      padding: 0;
    }

    a {
      display: inline-block;
      vertical-align: middle;
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 0.08em;
      line-height: 1.4em;
      border-bottom: 1px solid transparent;
      margin: 4px 12px;
      -webkit-font-smoothing: auto;

      &.jils-link {
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        text-decoration: none;
      }

      &:hover {
        border-color: #000;
      }

      @include breakpoint(small only) {
        padding: 10px;
        text-align: center;
        font-size: rem-calc(11);
      }
    }
  }
  .d-lg-none {
    text-transform: uppercase;
    font-size: 11px;
    line-height: 1.5em;
    letter-spacing: 0.08em;
    font-weight: 400;
    text-align: center;
    -webkit-font-smoothing: auto;

    @include breakpoint(medium) {
      display: none;
    }

    a {
      padding: 10px;
      display: block;
      text-decoration: none;
      border: none;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        padding: 0;
      }
    }

    .content-asset > ul,
    > ul {
      margin-bottom: 30px;

      > li {
        ul {
          display: none;
          padding: 10px 0 20px;
        }

        &.active {
          a {
            span {
              border-bottom: 1px solid;
            }
          }

          > ul {
            display: block;
          }
        }
      }
    }
  }
}

.footer-logo {
  width: 100%;
  height: 14vw;
  margin-bottom: 6px;

  @include breakpoint(small only) {
    padding-top: 10px;
    margin-bottom: 14px;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}
