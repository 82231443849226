$include-fonts: false;

.error-page {
  .container {
    margin-top: 105px;
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px;

    .inner {
      max-width: 320px;
      width: 100%;

      @include breakpoint(small down) {
        max-width: none;
      }
    }

    .button {
      margin-top: 30px;
    }
  }
  #menu {
    &.error-page-headespace {
      @include breakpoint(large up) {
        position: relative;
      }
      height: unset;
      margin: 17px 0 0 0;
      .nav-primary {
        width: 100%;
        justify-content: center;
        .nav-item.item-logo {
          position: unset;
          height: unset;
          svg {
            display: block;
            height: 14px;
            width: 100px;
          }
        }
      }
    }
  }
}
