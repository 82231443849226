// #account-dashboard {
//     padding-top: 100px;

//     .dashboard-container {
//         @include container;

//         @include breakpoint(small down) {
//             padding: 0 30px;
//         }
//     }

//     .card {
//         margin-top: 60px;

//         .card-header {
//             border-bottom: 1px map_get($jil-palette, "black") solid;
//             padding-bottom: 13px;
//             margin-bottom: 16px;
//             letter-spacing: 0.02em;

//             .action {
//                 @include card-header-action;
//             }
//         }

//         .actions {
//             button,
//             a {
//                 color: map_get($jil-palette, "dark-gray");
//                 border-bottom: 1px solid transparent;

//                 &:hover {
//                     color: $black;
//                     border-color: map_get($jil-palette, "medium-gray");
//                 }
//             }

//             > * + * {
//                 margin-left: 8px;
//             }
//         }

//         &.profile {
//             .change-password {
//                 margin-top: 7px;
//                 display: inline-block;
//                 border-bottom: 1px solid $black;
//             }
//         }

//         &.address,
//         &.payment {
//             .card-body {
//                 border: 1px map_get($jil-palette, "light-gray") solid;
//                 padding: 15px 0;

//                 .info {
//                     @include breakpoint(small only) {
//                         flex-basis: 100%;
//                         order: 1;
//                     }
//                 }

//                 img {
//                     height: 16px;
//                     margin-right: 10px;
//                 }
//             }
//         }

//         &.wishlist {
//             margin-bottom: -60px;

//             // Custom grid for wishlist tiles
//             .card-body {
//                 @include wishlist-grid();
//             }
//         }
//     }

//     .orders-list {
//         @include breakpoint(medium up) {
//             margin-top: -16px;
//         }

//         .order {
//             border-bottom: 1px map-get($jil-palette, "light-gray") solid;
//             padding: 18px 0 15px 0;
//             line-height: 1.6em;

//             @include breakpoint(small only) {
//                 border: 1px map-get($jil-palette, "light-gray") solid;
//                 padding: 15px;
//                 margin-top: 16px;
//             }

//             .cell {
//                 flex-basis: 20%;
//                 order: 2;

//                 @include breakpoint(small only) {
//                     flex-basis: 100%;

//                     &.order-view {
//                         flex-basis: 60%;
//                         order: 1;
//                     }

//                     &.order-number {
//                         flex-basis: 40%;
//                         order: 0;
//                     }

//                     &.order-status {
//                         order: 4;
//                         margin-top: 8px;
//                     }
//                 }

//                 &.order-status {
//                     text-transform: capitalize;
//                 }
//             }
//         }
//     }
// }

// .modal-container.dialog {
//     font-size: 12px;

//     &:not(#deleteAddressModal) {
//         transition: none !important;

//         .modal-box {
//             transition: none !important;
//         }
//     }

//     .modal-header {
//         margin-bottom: 8px;

//         .modal-title {
//             margin-bottom: 8px;
//         }
//     }

//     .modal-footer {
//         display: flex;
//         justify-content: flex-end;

//         button {
//             text-transform: uppercase;
//             margin: 8px;
//             min-width: 50px;
//             letter-spacing: 0.05em;
//         }
//     }
// }

// refactor

.page-account-content {
  width: 100%;
  margin: 0 auto;
}

.dashboard-header {
  * {
    font-size: 14px;
    @include breakpoint(large up) {
      font-size: 12px;
    }
  }
  font-size: 14px;
  @include breakpoint(large up) {
    font-size: 12px;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  .header-title {
    font-family: $body-font-family;
    // font-size: rem-calc(14);
    font-weight: 500;

    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-weight: 300;
      margin-top: 17px;
      text-transform: none;
    }
  }

  .logout-item {
    display: inline-block;
    width: auto;
    margin: 0 auto;
    // font-size: rem-calc(14);
    font-weight: 500;
    color: $button-border-gray;
    text-align: center;
    margin-top: 10px;
  }
}

.dashboard-menu {
  padding-left: rem-calc(70);

  @include breakpoint(large up) {
    max-width: rem-calc(640);
    padding-left: 0;
    margin: 0 auto;
  }
  .menu-list {
    padding-top: rem-calc(60);
  }

  .accordion-group-list-container {
    .accordion-trigger {
      border-bottom: none;
    }

    .accordion-title {
      text-align: left;
      text-transform: uppercase;
      font-weight: 500;
      width: auto;
    }

    .sub-menu-list-item {
      padding-bottom: rem-calc(28);
      a {
        color: $link-color-gray;
      }
    }

    .logout-item {
      .accordion-title {
        text-transform: none;
        color: $button-border-gray;
      }
    }

    .sub-menu-list {
      margin: 0;
    }

    .accordion-content {
      padding-left: rem-calc(20);
      @include breakpoint(large up) {
        padding-top: 28px;
      }
    }
  }

  .logout-item {
    margin-top: rem-calc(40);
  }
  //
}

#account-orders-returns {
  border-top: 1px solid #c9c9c5;
  padding: 42px 0;
}

.show-all-link {
  // font-size: rem-calc(14);
  font-weight: 500;
  color: $dark-gray;
}

.account-content-wrapper {
  padding-left: rem-calc(30);
  padding-right: rem-calc(30);
  // padding-top: rem-calc(40);

  @include breakpoint(large up) {
    max-width: rem-calc(640);
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
  }
}

.account-section-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c9c9c5;
  padding-bottom: rem-calc(8);
  .subtitle {
    // font-size: rem-calc(14);
    font-weight: 500;
    text-transform: none;
  }

  .account-back-to {
    color: #969693;
  }
}

.account-section-wrapper {
  margin-top: 200px;
  padding-top: 2.5rem;
  * {
    font-size: 14px;
    @include breakpoint(large up) {
      font-size: 12px;
    }
  }
  .account-section-message {
    padding-top: 0.25rem;
  }
  .account-section-content {
    padding-top: 1.625rem;
    .delete-modal-button {
      background-color: transparent;
      color: #000;
    }
  }
  .jil-button {
    &:not(.--link-button) {
      padding: 10px 20px 10px;
    }
  }
}

.account-header {
  display: flex;
  justify-content: space-between;
  * {
    font-size: 14px;
    @include breakpoint(large up) {
      font-size: 12px;
    }
  }

  .order-creation-date {
    margin-bottom: 25px;
  }

  .header-title {
    font-family: $body-font-family;
    // font-size: rem-calc(14);
    font-weight: 500;
    text-align: left;
  }
  padding-left: rem-calc(30);
  padding-right: rem-calc(30);

  @include breakpoint(large up) {
    max-width: rem-calc(640);
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
  }
}

.account-header,
.account-content-wrapper {
  &.w-form {
    @include breakpoint(large up) {
      max-width: rem-calc(330);
    }
  }
}

#paymentinstruments-list,
.page-account-show {
  footer {
    display: none;
  }
}
