.marketing-policy{
    color: $darkgrey;
    font-weight: $global-weight-bold;
    // position: relative;
    font-size: 11px;

    &-popup{
        border: 1px solid $black;
        background: $off-white;
        padding: 10px;
        width: 300px;
        position: absolute;
        top: 0;
        left: -13px;
        z-index: 1;
        // display: none;
        max-height: 300px;
        overflow: scroll;

        @include breakpoint(small down) {
            width: 200px;
            max-height: 200px;
            left: -15px;
            top: -100px;
        }

        .policy-popup-content{
            position: relative;

            .policy-popup-text1,
            .policy-popup-text2,
            .policy-popup-text3{
                font-size: 10px;
                margin-bottom: 1rem;
            }

            .policy-popup-tail-border{
                position: absolute;
                top: -30px;
                border: 10px solid;
                border-color: transparent $black transparent transparent;
                width: 0;
                height: 0;
                transform: rotate(90deg);
            }
    
            .policy-popup-tail{
                position: absolute;
                top: -29px;
                border: 10px solid;
                border-color: transparent $off-white transparent transparent;
                width: 0;
                height: 0;
                transform: rotate(90deg);
            }
        }
    }
}
