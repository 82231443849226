$include-fonts: false;

@include breakpoint(xlarge) {
  .main--checkout {
    .checkoutpage.shipping,
    .checkoutpage.payment {
      .order-summary-box-container {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100vh;
        background-color: #f9f7ef;
        padding: 60px 30px;
        width: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;
      }
  
      .page-designer-checkout {
        padding-top: 25px;
      }
  
      .order-summary-box-content {
        display: flex;
        flex-direction: column;
  
        .details-box.order-summary {
          order: 0;
          border-top: 0;
          margin-top: 0;
          padding-top: 0;
  
          .details-box p,
          .details-box .items__label,
          .estimated-total p,
          .estimated-total .items__label {
            padding-block: 7px;
          }
        }
  
        .horizontal-row.details-box-upper-row {
          order: 1;
        }
  
        .estimated-total {
          order: 2;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding-bottom: 0;
        }
  
        .order-summary-title {
          order: 3;
        }
  
        .voucher-wrapper {
          display: none;
        }
  
        .proceed {
          display: none;
        }
  
        .bag-review {
          row-gap: 0;
        }
  
        .bag-review-title {
          display: none;
        }
  
        .bag-review-container {
          padding-inline: 0;
        }
  
        .accordion {
          order: 6;
          margin: 0 !important;
  
          .accordion-trigger {
            display: none;
          }
  
          .accordion-content.js-accordion-content {
            display: block !important;
            opacity: 1 !important;
            max-height: 100%;
          }
  
          .bag-product-wrapper {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 10px;
  
            &:first-child {
              border-top: 0;
            }
          }
  
          .coupon-code-field {
            width: auto;
          }
        }
      }
  
      .checkout-shipping__address {
        .voucher-wrapper {
          display: block !important;
        }
      }
  
      .checkout-shipping .navigations-buttons,
      .proceed-to-review .navigations-buttons {
        display: block !important;
      }
    }
  }
}

#checkout-main {
  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }

  .spaced {
    margin-top: 80px;

    &.payment-form {
      margin-top: 65px;
    }
  }

  .spaced-half {
    margin-top: 30px;
  }

  .spaced-half-bottom {
    margin-bottom: 16px;
  }

  .shipping-content {
    &.shipping-spaced {
      margin-top: -22px;
    }
  }

  .user-payment-instruments {
    padding-bottom: 15px;
  }

  .card-header {
    margin-bottom: 30px;
    text-transform: uppercase;
    color: #000;
    font-size: 10px;
    letter-spacing: 0.08em;
    line-height: 1.4em;

    span {
      float: right;
      color: rgba(0, 0, 0, 0.6);
      font-size: 10px;
      letter-spacing: 0.08em;
      line-height: 1.4em;
    }

    &.divider {
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      padding-top: 27px;
      margin-top: 10px;
    }

    &.no-bottom {
      margin-bottom: 0;
    }
  }

  label {
    margin: 22px 0;
    display: block;

    @include breakpoint(small only) {
      margin: 32px 0;
    }

    &.label-small {
      margin-top: 0;
      margin-bottom: 15px;
    }

    &[for='newsletter'] {
      margin: 0;
    }

    &[for='terms-privacy-checkbox'] {
      margin-top: 0;
      margin-bottom: 0;

      @include breakpoint(medium) {
        margin-bottom: 0;
      }

      .checkbox-label {
        display: block;
      }

      a {
        border-bottom: 1px solid adjust-color($black, $alpha: -0.6);
        position: relative;
        z-index: 2;
      }
    }
  }

  .section-summary {
    @include breakpoint(large down) {
      margin-top: 80px;
    }

    @include breakpoint(xlarge) {
      position: fixed;
      right: 0;
      top: 0;
      height: 100%;
      width: 300px;
      padding: 155px 30px 30px 30px;
      background-color: #f9f7ef;
      justify-content: space-between;
      overflow: hidden;
      overflow-y: auto;
    }
  }

  .checkout-help {
    display: flex;
    flex-direction: column;
    font-size: 9px;
    letter-spacing: 0.08em;
    line-height: 1.5em;
    font-weight: 400;
    text-transform: uppercase;

    @include breakpoint(large down) {
      display: none;
    }

    > * {
      padding: 5px 0;
    }
  }

  .payment-card {
    margin: 0;
    margin-bottom: -1px;

    img {
      height: 16px;
    }
  }

  .shipping-box {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 15px 15px 15px 40px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    line-height: 1.5em;
  }

  .radio-group {
    img {
      height: 16px;
      margin-right: 10px;
    }
  }

  .accordion {
    border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
    padding: 27px 0 !important;

    @include breakpoint(small only) {
      padding-top: 60px !important;
      padding-bottom: 27px !important;
    }

    .accordion-item {
      .accordion-title {
        cursor: pointer;
        position: relative;
        padding-left: 12px;

        @include breakpoint(small only) {
          font-size: rem-calc(14);
        }

        &::before {
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          @include triangle(#000, right, 3px);
        }
      }

      .accordion-content {
        display: none;

        p {
          margin: 12px 0;
        }

        .mini-form {
          margin: 22px 0;
        }

        .promo-code-submit {
          padding-left: 5px;
        }

        @include breakpoint(small only) {
          input[type='text'] {
            padding-bottom: 15px;
          }
        }
      }

      &.active {
        .accordion-title {
          &::before {
            @include triangle(#000, down, 3px);

            margin-top: 0;
          }
        }

        .accordion-content {
          display: block;
        }
      }
    }
  }

  .fake-input {
    height: 28px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 6px 0;

    @include breakpoint(large down) {
      font-size: 14px;
    }

    a {
      float: right;
    }
  }

  .radio-container {
    .price {
      min-width: 70px;
      text-align: right;
    }
  }

  .isGift {
    display: none;
  }
}

/* COMMON */
.hide-order-discount,
.hide-shipping-discount {
  display: none;
}

.leading-lines {
  margin-top: 15px;
  margin-bottom: 15px;

  .start-lines,
  .end-lines {
    font-size: 12px;
    line-height: 1.3em;
    letter-spacing: 0.02em;

    @include breakpoint(small only) {
      font-size: rem-calc(14);
    }
  }
}

.leading-lines-storedpayment {
  margin-top: 5px;
  margin-bottom: 15px;

  .start-lines,
  .end-lines {
    font-size: 12px;
    line-height: 1.3em;
    letter-spacing: 0.02em;

    @include breakpoint(small only) {
      font-size: rem-calc(14);
    }
  }
}

.grand-total {
  padding-top: 27px;
  padding-bottom: 25px;
  margin: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.order-product-summary {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;

  .confirm-details & {
    padding-bottom: 0;
  }

  .line-item-name {
    text-transform: uppercase;
  }

  .non-adjusted-price {
    display: none;
  }
}

.line-item-pricing-info {
  display: flex;
  align-items: center;

  .qty-card-quantity-label {
    padding: 0 5px;
  }
}

.line-item-availability,
.unit-price-label,
del,
.line-item-total-price,
.d-none {
  display: none;
}

.product-line-item {
  padding: 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  @include breakpoint(small only) {
    padding: 30px 0;
  }

  &:last-child {
    border: none;
  }

  .line-item-name {
    font-size: 10px;
    letter-spacing: 0.08em;
    line-height: 1.4em;
    padding-bottom: 10px;
    padding-right: 10px;

    @include breakpoint(large down) {
      font-size: 12px;
    }
  }

  .product-line-item-details {
    font-size: 12px;
    line-height: 1.3em;
    letter-spacing: 0.02em;
  }

  .quantity {
    padding-top: rem-calc(10);
  }
}

.section-checkout {
  position: relative;
  display: flex;

  max-width: 320px;
  width: calc(100% - 60px);
  margin: 130px auto 0;

  @include breakpoint(large) {
    margin-top: 170px;
  }

  .alert-box {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCAzMiAzMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiNjMDAiPiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNiwzIEMyMy4yLDMgMjksOC44IDI5LDE2IEMyOSwyMy4yIDIzLjIsMjkgMTYsMjkgQzguOCwyOSAzLDIzLjIgMywxNiBDMyw4LjggOC44LDMgMTYsMyBMMTYsMyBaIE0xNiwwIEM3LjIsMCAwLDcuMiAwLDE2IEMwLDI0LjggNy4yLDMyIDE2LDMyIEMyNC44LDMyIDMyLDI0LjggMzIsMTYgQzMyLDcuMiAyNC44LDAgMTYsMCBMMTYsMCBaIiBpZD0iU2hhcGUiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPiAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjE0LjUiIHk9IjciIHdpZHRoPSIzIiBoZWlnaHQ9IjEwIj48L3JlY3Q+ICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgeD0iMTQuNSIgeT0iMjEiIHdpZHRoPSIzIiBoZWlnaHQ9IjQiPjwvcmVjdD4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-size: 11px 11px;
    background-position: 0 2px;
    color: $error-color;
    font-size: rem-calc(11);
    font-weight: 500;
    padding: 0 0 0 20px;
    margin: 20px 0;
  }
}

p {
  @include breakpoint(large down) {
    font-size: rem-calc(14);
  }
}

#checkout-thankyou {
  .card-header {
    margin-top: 80px;
    border-bottom: 1px solid #000;
    padding-bottom: 13px;
    margin-bottom: 16px;

    @include breakpoint(small only) {
      font-size: rem-calc(14);
    }

    @include breakpoint(large) {
      margin-top: 40px;
    }
  }

  label {
    margin: 22px 0;

    &[for='newPassword'],
    &[for='newPasswordConfirm'] {
      display: block;

      @include password-toggle();
    }
  }

  .item-check {
    label {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .single-row {
    margin-bottom: 0;

    .checkbox-label {
      p {
        margin-bottom: 0;
      }
    }
  }

  .fields-select {
    display: flex;
    width: 100%;

    label {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .confirm-details {
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .shipping-line {
    display: none;
  }

  .order-total-summary {
    padding-top: 10px;
    margin-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }

  .grand-total {
    margin-top: 30px;
  }

  .shipping-section,
  .billing-section,
  .payment-section {
    margin-top: 30px;
    padding-top: 27px;
    border-top: 1px solid rgba(0, 0, 0, 0.15);

    @include breakpoint(small only) {
      font-size: rem-calc(14);
    }
  }

  .billing-addr-label,
  .payment-info-label {
    display: block;
    margin-bottom: 1rem;
    letter-spacing: 0.02em;
    font-weight: 400;

    @include breakpoint(small only) {
      font-size: rem-calc(14);
    }
  }

  .product-line-item-details {
    .item-image {
      max-width: 80px;
      padding-right: 15px;
    }

    .line-item-pricing-info {
      display: none;
    }
  }

  .summary-details {
    @include breakpoint(small only) {
      font-size: rem-calc(14);
    }

    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .checkout-registration {
    .birthday-label {
      margin-bottom: -16px;
    }

    @include breakpoint(small only) {
      .button {
        border-color: black;
      }

      .item-input {
        margin-top: 32px;
        margin-bottom: 32px;
      }

      .birthday-label {
        margin-top: 80px;
      }
    }
  }

  .newletter-preference {
    margin-top: 22px;
    margin-left: 20px;
    display: none;

    label {
      &:first-child {
        margin-left: 10px;
      }
    }
  }
}

.checkout-receipt {
  width: 320px;
  margin: 0 auto;
}

// #menu {
//     @include breakpoint(large down) {
//         display: block;
//         min-height: auto;
//     }
// }

#checkout-footer {
  min-height: 70px;
  text-align: center;
  font-size: 14px;
  margin-top: 30px;

  .checkout-help {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;

    span {
      padding: 5px 0;
      display: block;
    }

    @include breakpoint(xlarge) {
      display: none;
    }
  }
}

.remove-promo {
  opacity: 0.5;
  margin-left: 10px;

  @include breakpoint(small only) {
    font-size: 14px;
    line-height: 1.5em;
    letter-spacing: 0.02em;
  }

  &:hover {
    opacity: 1;
  }
}

.coupon-error {
  margin: -10px 0 0;

  .coupon-missing-error,
  .coupon-error-message {
    background-position: 0 0 !important;
    padding-bottom: 2px;
  }
}

.coupon-mini-form.is-hidden {
  + .coupon-error {
    margin-top: 10px;
  }
}

.coupon-message {
  &.error {
    color: $error-color;
    margin-top: rem-calc(7);
    margin-bottom: 0;
    font-size: rem-calc(11);
    font-weight: 500;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCAzMiAzMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiNjMDAiPiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNiwzIEMyMy4yLDMgMjksOC44IDI5LDE2IEMyOSwyMy4yIDIzLjIsMjkgMTYsMjkgQzguOCwyOSAzLDIzLjIgMywxNiBDMyw4LjggOC44LDMgMTYsMyBMMTYsMyBaIE0xNiwwIEM3LjIsMCAwLDcuMiAwLDE2IEMwLDI0LjggNy4yLDMyIDE2LDMyIEMyNC44LDMyIDMyLDI0LjggMzIsMTYgQzMyLDcuMiAyNC44LDAgMTYsMCBMMTYsMCBaIiBpZD0iU2hhcGUiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPiAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjE0LjUiIHk9IjciIHdpZHRoPSIzIiBoZWlnaHQ9IjEwIj48L3JlY3Q+ICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgeD0iMTQuNSIgeT0iMjEiIHdpZHRoPSIzIiBoZWlnaHQ9IjQiPjwvcmVjdD4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-size: 11px 11px;
    background-position: 0 2px;
    padding: 0 0 0 20px;
  }
}

.coupon-price-adjustment {
  margin: 15px 0;
  border: 1px solid #ccc;
  padding: 15px 15px 13px;

  .coupon-code {
    display: flex;
    justify-content: space-between;

    .coupon-code-value {
      display: flex;
      align-items: center;

      @include breakpoint(small only) {
        font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0.02em;
      }
    }
  }
}

// @import '../components/adyen';
