.modal-container,
.modal-wrapper {
  opacity: 0;
  pointer-events: none;
  display: block;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9999;
  transition: opacity 0.5s ease-in-out;

  &.modal-show {
    opacity: 1;
    pointer-events: auto;

    .modal-content {
      .modal-box {
        transform: translate3d(0, 0, 0);
      }
    }

    .modal-overlay {
      opacity: 1;
    }
  }

  .modal-content {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    height: 100%;

    .modal-overlay {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: $lightest-gray;
    }

    .modal-box {
      background-color: $off-white;
      padding: 30px;
      max-width: 380px;
      width: 100%;
      height: auto;
      max-height: 500px;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      transform: translate3d(0, 100vh, 0);
      transition: all 0.8s ease-in-out;
      z-index: 1000;

      &-header {
        display: flex;
        justify-content: space-between;

        .modal-close {
          cursor: pointer;
          padding: 15px 18px;
          margin-top: -12px;
          margin-right: -18px;
          margin-bottom: -15px;
        }
      }

      &-information {
        p {
          border-bottom: 1px solid $lightest-gray;
          margin-bottom: 18px;
          padding-bottom: 28px;
        }
      }

      &-countries {
        margin-top: 10px;

        ul {
          margin: 0;
          list-style: none;
          padding: 0;
          line-height: 2em;
          column-count: 3;

          @include breakpoint(small down) {
            column-count: 2;
          }

          li {
            padding: 0;

            .language {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
}

.content-asset-page {
  margin-top: 100px;

  @include breakpoint(large only) {
    margin-top: 60px;
  }
}

body {
  .jils-link {
    text-decoration: underline;
  }
  .-light-grey {
    color: $light-gray;
  }
  .-medium-grey {
    color: $medium-gray;
  }
  .-dark-grey {
    color: $dark-gray;
  }
  .-capitalize {
    text-transform: capitalize;
  }
  .-uppercase {
    text-transform: uppercase;
  }
  .-capitalize {
    text-transform: capitalize;
  }
  a,
  button {
    color: $black;
  }
}

.unstyled-list {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding: 0;
  }
}
.dot-list {
  list-style: none;
  padding: 0;
  margin: 12px 0 3rem;
  li {
    padding-left: 20px;
    position: relative;
    &:before {
      content: '\2013';
      position: absolute;
      left: 6px;
    }
  }
}

.f-500 {
  font-weight: 500;
  p {
    font-weight: 500;
  }
}

.d-none-checkout {
  display: none !important;
}

.content-asset-title {
  display: none;
}
