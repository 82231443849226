/* element */
.jil-switch {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &__toggle {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    background: none;
    &.-active {
      .switch-text {
        text-decoration: underline;
        color: #000;
      }
    }
  }
  .switch-text {
    font-style: normal;
    font-weight: 500;
    font-size: rem-calc(12);
    line-height: rem-calc(14);
    letter-spacing: 0.08rem;
    color: $link-color-gray;
    text-transform: uppercase;
    border: none;
  }
}
