.callout {
  margin: 30px 0;
  padding-left: 20px;
  position: relative;
  color: $error-color;

  &.closable {
    padding-right: 20px;
  }

  .close-button {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0;
    top: -4px;
    font-size: rem-calc(18);
    line-height: rem-calc(18);
    text-align: center;
    color: $error-color;

    span {
      width: 20px;
      height: 20px;
      display: inline-block;
    }
  }

  svg {
    position: absolute;
    width: 10px;
    height: 10px;
    left: 0;
    top: 2px;
    fill: $error-color;
  }
}

@mixin error-message {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCAzMiAzMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiNjMDAiPiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNiwzIEMyMy4yLDMgMjksOC44IDI5LDE2IEMyOSwyMy4yIDIzLjIsMjkgMTYsMjkgQzguOCwyOSAzLDIzLjIgMywxNiBDMyw4LjggOC44LDMgMTYsMyBMMTYsMyBaIE0xNiwwIEM3LjIsMCAwLDcuMiAwLDE2IEMwLDI0LjggNy4yLDMyIDE2LDMyIEMyNC44LDMyIDMyLDI0LjggMzIsMTYgQzMyLDcuMiAyNC44LDAgMTYsMCBMMTYsMCBaIiBpZD0iU2hhcGUiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPiAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjE0LjUiIHk9IjciIHdpZHRoPSIzIiBoZWlnaHQ9IjEwIj48L3JlY3Q+ICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgeD0iMTQuNSIgeT0iMjEiIHdpZHRoPSIzIiBoZWlnaHQ9IjQiPjwvcmVjdD4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
  background-position: 0 2px;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  color: $error-color;
}

.invalid-feedback {
  @include error-message;

  padding-left: 20px;
  position: relative;
  &:not(:empty) {
    margin: 30px 0;
  }
}

.error-message {
  &.-invalid {
    @include error-message;
    margin-top: 0.58333rem;
    margin-bottom: 0;
    font-size: 0.75rem;
    font-weight: 500;
    background-repeat: no-repeat;
    background-position: 0 2px;
    padding: 0 0 0 20px;
  }
}

.cart-error-messaging,
.error-message-cart {
  @include error-message;
  margin-left: -12px;
  margin-right: -12px;
  padding-left: 20px;
  // margin-bottom: 70px;
  &.-no-icon {
    background: none;
    padding-left: 12px;
  }
}

.error-message-cart {
  font-size: 12px;
  @include breakpoint(large up) {
    font-size: 12px;
  }
}

.coupon-error-message {
  @include error-message;
  background-image: none;
  margin-top: 10px;
}
