
#copy-btn-wrapper {
    display: inline-flex;
    .jils-copy-button {
        padding: 2px 6px;
        border-radius: 3px;
        display: inline-flex;
        align-items: center;
        margin-left: 5px;
        svg {
            margin-left: 2px;
        }
        #product-mfc:hover {
            text-decoration: underline;
        }
    }

    .copy-popover {
        visibility: hidden;
        position: absolute;
        z-index: 1;
        width: 150px;
        bottom: 140%;
        border-radius: 9px;
        background-color: $white;
        color: $primary-color;
        box-shadow: 0px 5px 10px -6px $primary-color;
        line-height: 1;
        text-align: center;
        font-size: 11px;
        padding: 10px 10px;
        &.show {
            visibility: visible;
        }
    }

    .copy-popover::after {
        content: " ";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -8px;
        border-width: 8px;
        border-style: solid;
        border-color: $secondary-color transparent transparent transparent;
    }
}

