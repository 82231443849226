.main--checkout {
  .checkoutpage.shipping,
  .checkoutpage.payment {
    .bag-product {
      margin-bottom: 10px;

      &__details-quantity {
        display: block;

        .row {
          display: flex;
          gap: 5px;
        }
      }
    }

    .edit-bag-wrapper-desktop {
      display: block;
    }

    .edit-bag-wrapper-mobile {
      display: none;
    }

    .edit-bag-wrapper {
      margin-top: 20px;
    }
  }

  .proceed {
    .mobile-button {
      display: none;
    }
  }
}