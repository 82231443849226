#vertex-modal {

  .modal-header {
    .modal-close {
      display: block;
      opacity: 1;
      position: absolute;
      right: -15px;
      top: 17px;
    }

    .rectified-title {
      margin-bottom: 0;
    }
  }
  .modal-content {
    padding: 0;
  }
  .vertex-header-title,
  .modal-title {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.8px;
    text-align: center;
  }

  .vertex-header-title {
    margin: 0.5rem 0;
    text-transform: uppercase;
  }

  .modal-content {
    overflow: auto;
  }

  .jil-button {
    height: 49px;
  }
}
