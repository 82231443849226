.confirm-details {
  .payment-section,
  .billing-section,
  .shipping-section,
  .order-total-summary,
  .order-product-summary {
    padding-top: 27px;
    margin-top: 30px;
    border-top: 1px map_get($jil-palette, 'light-gray') solid;
    line-height: 1.5em;

    .summary-section-label {
      display: inline-block;
      margin-bottom: 1em;
    }
  }

  .order-total-summary {
    .end-lines {
      letter-spacing: 0.02em;
    }

    .leading-lines {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .grand-total {
      border-top: 1px map_get($jil-palette, 'light-gray') solid;
      padding-top: 27px;
      margin-top: 30px;
      margin-bottom: 67px;
    }
  }

  .order-product-summary {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }

  .line-item-pricing-info,
  .non-adjusted-price {
    display: none;
  }

  .item-image {
    width: 80px;
    padding-right: 15px;
  }

  .product-line-item {
    padding-top: 15px;
    padding-bottom: 15px;

    + .product-line-item {
      border-top: 1px map_get($jil-palette, 'light-gray') solid;
    }

    .item-attributes,
    .pricing {
      line-height: 2em;
    }
  }

  .order-summary-email {
    margin-top: 12px;
    display: inline-block;
  }
}
