.edit-product-modal {
  .modal-close {
    display: none;
    opacity: 0;
  }
  .modal-header {
    .modal-close {
      display: block;
      opacity: 1;
      position: absolute;
      right: -15px;
      top: 17px;
    }
  }
  .modal-overlay {
    .modal-content {
      padding: 0;
    }
  }
  .edit-product-title,
  .modal-title {
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.8px;
    text-align: center;
  }
  .edit-product-title {
    margin: 0.5rem 0;
    text-transform: uppercase;
  }

  .attributes {
    position: relative;
    .size-guidelines-trigger-box {
      position: absolute;
      bottom: 0;
      right: 0;
      margin-bottom: 5px;
      z-index: 2;
    }
  }

  // .size-guidelines-trigger-box {
  //   text-align: right;
  //   margin-bottom: 25px;
  // }

  .modal-content {
    overflow: auto;
  }

  .product-quickview {
    margin-bottom: 0;
  }

  div[data-attr='color'] {
    .color-attribute {
      .selected-assistive-text {
        color: rgba(0, 0, 0, 0.65);
        display: flex;
        text-transform: lowercase;
        &:not(.selected-description) {
          display: none;
        }
      }
    }
  }
  .update-cart-product-global {
    &:disabled {
      cursor: not-allowed;
    }
  }

  .product-img-box {
    .js-carousel-item {
      display: none;
      &:first-child {
        display: block;
      }
    }
  }
}

.edit-product-modal {
  .attributes {
    position: relative;
  }
  .size-guidelines-trigger-box {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 10px;
    z-index: 2;
  }
}
