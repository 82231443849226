@import "../../components/order-detail";

#order-return-page {
    @extend .confirm-details;
    @include container;

    padding-top: 0;

    .header {
        border-bottom: 1px map_get($jil-palette, "black") solid;
        padding-bottom: 13px;
        margin-bottom: 16px;
    }

    .order-details {
        .title {
            border-bottom: 1px map_get($jil-palette, "black") solid;
            margin-top: 40px;
            margin-bottom: 16px;
            padding-bottom: 13px;
        }

        .shipping-section {
            border-top: none;
            margin-top: 12px;
            padding-top: 0;
        }
    }

    .product-line-item {
        &:first-of-type {
            border-top: 1px map_get($jil-palette, "light-gray") solid;
        }

        &:last-of-type {
            border-bottom: 1px map_get($jil-palette, "light-gray") solid;
        }

        .lesser {
            opacity: 0.5;
        }
    }

    .notes {
        font-size: 10px;
        letter-spacing: 0.02em;
        padding-top: 32px;
        border-top: 1px map_get($jil-palette, "light-gray") solid;
        color: map_get($jil-palette, "dark-gray");

        @include breakpoint(medium down) {
            font-size: 12px;
        }
    }

    .return-type {
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .return-type-option {
        select {
            margin: 8px 0;
        }
    }

    form {
        margin-top: 40px;

        button[type="submit"] {
            margin-top: 32px;
        }

        .check-privacy {
            margin-top: 32px;

            a {
                position: relative;
            }
        }
    }

    .subtitle-divider {
        border-top: 1px solid #000;
        padding-top: $paragraph-margin-bottom;
    }
}
