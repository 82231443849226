$include-fonts: false;

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
  touch-action: manipulation;

  // @include breakpoint(xlarge) {
  //     height: calc(100vh - 8rem);
  // }

  .owl-stage {
    position: relative;
    // -ms-touch-action: pan-Y;
    touch-action: auto;
    -moz-backface-visibility: hidden; /* fix firefox animation glitch */
    display: flex;
    height: 100%;
  }

  .owl-stage::after {
    content: '.';
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    height: 100%;
    // left: -1px;
    // right: -1px;
  }

  .owl-wrapper,
  .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
  }

  .owl-item {
    position: relative;
    min-height: 1px;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    display: inline-block;
    // align-self: center;
    // height: 100%;
    // text-align: center;
    // justify-content: center;
    // align-items: center;
    // margin-left: 2px;

    img {
      //     display: block;
      width: 100vw;
      max-height: 100%;
      // display: inline;
      //margin-right: -3px; // prevent the next slide to show on mobile
    }
  }

  .owl-nav.disabled,
  .owl-dots.disabled {
    display: none;
  }

  .owl-nav .owl-prev,
  .owl-nav .owl-next,
  .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-nav button.owl-prev,
  .owl-nav button.owl-next,
  button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
  }

  .owl-nav button.owl-prev,
  .owl-nav button.owl-next {
    background: transparent;
    position: absolute;
    top: calc(50% - (33px / 2));
  }

  .owl-nav button {
    // Owl carousel next button
    &.owl-next {
      right: 5%;

      @include arrow(16px, 16px, white, 1px, right); // Owl next arrow defaults to white color if no class color is defined
    }

    // Owl carousel previous button
    &.owl-prev {
      left: 5%;
      @include arrow(16px, 16px, white, 1px, left); // Owl previous arrow defaults to white color if no class color is defined
    }
  }

  &.black {
    .owl-nav button {
      &.owl-next {
        @include arrow(16px, 16px, black, 1px, right);
      }

      &.owl-prev {
        @include arrow(16px, 16px, black, 1px, left);
      }
    }
  }

  &.black-mobile {
    @include breakpoint(medium down) {
      .owl-nav button {
        &.owl-next {
          @include arrow(16px, 16px, black, 1px, right);
        }

        &.owl-prev {
          @include arrow(16px, 16px, black, 1px, left);
        }
      }
    }
  }

  &.white-mobile {
    @include breakpoint(medium down) {
      .owl-nav button {
        &.owl-next {
          @include arrow(16px, 16px, white, 1px, right);
        }

        &.owl-prev {
          @include arrow(16px, 16px, white, 1px, left);
        }
      }
    }
  }

  &.owl-loaded {
    display: block;
  }

  &.owl-loading {
    opacity: 0;
    display: block;
  }

  &.owl-hidden {
    opacity: 0;
  }

  &.owl-refresh .owl-item {
    visibility: hidden;
  }

  &.owl-drag .owl-item {
    touch-action: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.owl-grab {
    cursor: move;
    cursor: grab;
  }

  &.owl-rtl {
    direction: rtl;
  }

  &.owl-rtl .owl-item {
    float: right;
  }
}

.owl-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  button.owl-dot {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000;
    margin: 0 5px;

    &.active {
      background-color: #fbfbf6;
    }
  }
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

// Page designer customization
.pd-slider {
  .owl-carousel {
    display: flex;
    align-items: center;
  }
}

// .project-content {
//     .pd-slider {
//         .owl-carousel .owl-item img {
//             width: auto;
//             @include breakpoint(large) {
//                 max-height: 480px;
//             }

//             @include breakpoint(xlarge) {
//                 max-height: 640px;
//             }
//         }
//     }
// }

.pd-default-photo-grid {
  .toggle-slider {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
