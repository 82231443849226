.order-history-card-box {
  padding-top: rem-calc(26);
  li + li {
    margin-top: rem-calc(30);
  }
}

.single-order-history-card {
  border: 1px solid #c9c9c5;
  padding: 15px 15px 15px 9px;
}

.dashboard-order-card-status {
  text-transform: capitalize;
}

.order-list-container {
  .pagination {
    margin-top: rem-calc(40);
    .pages {
      display: flex;
      justify-content: space-between;
      .page-list {
        display: flex;
        justify-content: center;
        list-style: none;
        padding: 0;
      }
      .page-number {
        margin: 0 0.25rem;
        color: #969693;
        a {
          color: inherit;
        }
        &.current-page {
          color: #000;
        }
      }
    }
  }
}
