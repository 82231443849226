.recommendations {
  width: 100%;

  .container-carousel {
    margin: 3rem 0 0;
    row-gap: 30px;
  }

  .carousel {
    padding: 0 60px;

    &.cell {
      .product-grid {
        gap: 18px;
        @include breakpoint(small only) {
          margin: 30px 10px;
          justify-content: space-between;
        }
      }

      .product {
        margin: 0;
        padding: 0;
        @include breakpoint(small only) {
          max-width: 47%;
          flex-grow: 1;
        }
      }
    }
  }

  .swiper-wrapper {
    flex-flow: row nowrap;

    .product {
      width: 385px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    cursor: pointer;
    border: solid black;
    border-width: 0 2px 2px 0;
    height: 34px;
    position: absolute;
    top: calc(19vw - 50px);
    width: 34px;
  }

  .swiper-button-prev {
    left: 18px;
    transform: rotate(135deg);
  }

  .swiper-button-next {
    right: 18px;
    transform: rotate(-45deg);
  }

  .swiper-button-disabled {
    display: none;
  }

  .swatches {
    display: none !important;
  }
}

.prices-add-to-cart-actions {
  position: sticky;
  max-width: unset;
}

.primary-images {
  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }

  .js-swiper-wrapper.swiper-wrapper {
    max-height: calc(100vh - 76px);
    margin-left: 50px;
    width: calc(100% - 50px);
    height: calc(100vh - 76px);

    .js-carousel-item.carousel-item {
      height: 100% !important;
      align-content: center;
    }
  }

  .carousel-wrapper {
    position: relative;
    margin: auto;
    margin-right: 0;
    z-index: 0;
  }

  .swiper-pagination {
    .swiper-pagination-bullet {
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      color: black;
      opacity: 1;
      background: transparent;
      border: 1px solid black;

      width: 12px;
      height: 12px;
      margin: 0 39px 0 2px;
      transform: rotate(-270deg);
      border-radius: 7.5px;
    }
    .swiper-pagination-bullet-active {
      color: #fff;
      background: black;
    }
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction .swiper-pagination-vertical.swiper-pagination-bullets,
  .swiper-vertical > .swiper-pagination-bullets {
    z-index: 2;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 100%;
    left: 0;
    padding: 0;
    position: absolute;
    top: 25%;
    right: 16px;
    width: 47px;
    justify-content: flex-start;
    padding-top: 106px;
    .swiper-pagination-bullet {
      margin: 8px 0;
      transform: rotate(-360deg);
    }
  }
}
