@mixin fonts {

  @font-face {
    font-family: 'Jil-Futura';
    src: url('../fonts/FuturaStd-Book.woff2') format('woff2'), url('../fonts/FuturaStd-Book.woff') format('woff'), url('../fonts/FuturaStd-Book.otf') format('opentype');
    font-style: normal;
    font-weight: 400;
  }

}
