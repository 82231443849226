$include-fonts: false;

.content-asset-page {
  margin-top: 140px;

  @include breakpoint(small only) {
    margin-top: 94px;
    font-size: 14px;
  }

  .container {
    padding: 0 30px;
    display: flex;
    justify-content: center;

    .inner {
      width: 100%;

      @include breakpoint(medium up) {
        max-width: 320px;
      }

      .sitemapScrollAnchor,
      .scrollAnchor {
        position: relative;
        bottom: 45px;
      }
    }

    &-medium {
      .inner {
        width: 100%;
        max-width: 640px;
      }
    }
  }

  .-hidden {
    display: none;
  }

  h1 {
    font-size: inherit;
  }

  .legal-title p {
    margin-bottom: 0%;
  }

  .title,
  .legal-title {
    border-bottom: 1px solid #000;
    padding-bottom: 13px;
    margin-bottom: 16px;
    line-height: 1.5em;
    letter-spacing: 0.02em;
    font-weight: 400;
    display: block;
    text-transform: none;
  }

  .subtitle {
    text-transform: none;
  }

  .spacer {
    &-larger {
      margin-top: 40px;
    }
  }

  .subtitle,
  p {
    line-height: 1.5em;
    letter-spacing: 0.02em;
    margin-bottom: 12px;
    font-weight: 400;
    display: block;
  }

  .richtext p {
    margin-bottom: 0%;
  }

  .accordion-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    padding: 0;
  }

  .trigger-toggle {
    padding: 16px 0;
    position: relative;
    cursor: pointer;

    &::after {
      content: '+';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }

    &.padding-top-0::after {
      top: calc(50% - 8px);
    }

    &.open {
      &::after {
        content: '\2013';
      }
    }
  }

  .PD {
    .toggle-content {
      padding-bottom: 16px;
    }

    .trigger-toggle {
      line-height: 1.5rem;
    }
  }

  .toc {
    li {
      padding: 6px 0;

      @include breakpoint(medium up) {
        line-height: 1.3;
      }

      &::before {
        content: '';
        display: inline-block;
        //background-image: url("//dev.webonomic.nl/demos/yoga.svg#position_2");
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDMyIDMyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBvbHlsaW5lIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLXdpZHRoPSI0IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIHBvaW50cz0iMzAuNSwxNC41IDE2LDI5IDEuNSwxNC41ICIvPgo8bGluZSBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iNCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiB4MT0iMTYiIHkxPSIyOSIgeDI9IjE2IiB5Mj0iMCIvPgo8L3N2Zz4K');
        width: 8px;
        height: 8px;
        margin-right: 5px;
      }

      // Hide the ::before element. Useful if ::before should not be visually rendered, but its space is still useful - e.g., in a list to properly position specific <li> elements
      &.image-hidden::before {
        visibility: hidden;
      }
    }
  }

  .section-list {
    ul {
      li {
        padding: 0;
        line-height: 2;
      }
    }
  }

  ul.styled-list,
  .richtext ul {
    margin-left: 25px;

    li {
      position: relative;
      padding-left: 16px;

      &::before {
        content: '\2013';
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
