.message-notification {
  .notification-box {
    max-width: 450px;
    width: 50%;
    top: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
    .box-decoration-element {
      display: none;
    }
    .recommendations {
      .carousel {
        padding: 0;
        .product-tile {
          margin-bottom: 0;
        }
        .product-grid {
          margin: 0;
        }
      }
    }
  }
}