@mixin container {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 220px);

  .inner {
    max-width: 640px;
    width: 100%;

    &-small {
      width: 100%;

      @include breakpoint(medium up) {
        max-width: 320px;
      }
    }
  }

  @include breakpoint(medium down) {
    font-size: 14px;
    align-items: flex-start;
  }
}

@include breakpoint(medium down) {
  .inner-container,
  .inner-container-column {
    font-size: 14px;

    .card {
      margin-top: 0 !important;
    }
  }
}

.account-content-wrapper {
  .form-select.js_sfsc-countries {
    text-transform: capitalize;
  }
}
