.photo-tile-container {
    position: relative;

    picture {
        position: relative;
        z-index: 1;
    }

    picture + picture {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: opacity 0.1s ease-in-out; // If you change transition timing, update it also in widgets.js:linkCategory()
        z-index: 2;

        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }

    &.state-hover,
    &:hover {
        picture + picture {
            opacity: 1;
        }
    }
}
