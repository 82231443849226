.page-storelocator {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 83px 20px 0;
  max-width: 480px;

  h1,
  h2,
  h3 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.065em;
    line-height: 17px;
  }

  ul {
    line-height: 14px;
  }

  li {
    padding: 0;
  }

  a {
    border: 0;
    letter-spacing: 0.05em;
    margin-right: auto;
    text-decoration: underline;
  }

  &-landing {
    height: 100vh;
    justify-content: center;
    margin-top: -30px;
    row-gap: 22px;
    h1 {
      text-transform: none;
    }

    &:not(.country) {
      .store-locator-fields-row:last-child,
      .back-to-search {
        display: none;
      }
    }

    &.country {
      .store-locator-fields-row:first-child,
      .countries {
        display: none;
      }
    }

    .address {
      color: rgba(0, 0, 0, 0.65);
      letter-spacing: -0.02em;
      line-height: 17px;
    }
  }

  .store-locator-fields-row:first-child {
    position: relative;

    &::before {
      $sprite: map-get($sprites, 'magnifying-glass');
      background: url($sprite) center no-repeat;
      content: '';
      display: block;
      height: 14px;
      opacity: 0.3;
      position: absolute;
      top: calc(50% - 8px);
      width: 14px;
    }
  }

  .postal-code {
    // font-size: 14px;
    line-height: 17px;
    // margin: 32px 0 0;
    padding: 6px 0 6px 25px;

    &::placeholder {
      color: #969693;
    }
  }

  .reset-stores {
    position: absolute;
    right: 0;
    top: 9px;
  }

  .store-country {
    align-items: center;
    border: 1px solid #939393;
    column-gap: 9px;
    display: flex;
    letter-spacing: 0;
    margin: 0;
    padding: 13px 18px;
    text-transform: uppercase;

    &::before {
      $sprite: map-get($sprites, 'store-marker2');
      background: url($sprite) center no-repeat;
      content: '';
      display: block;
      height: 16px;
      opacity: 0.3;
      width: 16px;
    }
  }

  .map-canvas {
    display: none;
  }

  .results-panel {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    .results {
      margin-top: -15px;
      border-bottom: 1px solid #939393;
    }
  }

  .results-length-target {
    display: none;
  }

  .card-body {
    border-bottom: 1px solid #939393;
    color: rgba(0, 0, 0, 0.65);
    padding: 15px 0;

    &:last-child {
      // border-bottom: 1px solid #939393;
      border-bottom: none;
    }
  }

  .store-box-info {
    position: relative;
    font-size: 14px;

    &:not(.is-active) {
      .label-less-info,
      .expanded {
        display: none;
      }
    }

    &.is-active {
      .label-more-info,
      .live-open {
        display: none;
      }
    }
  }

  .more-info {
    color: #585856;
    position: absolute;
    letter-spacing: 0.05em;
    right: 0;
    transform: translate(1px, 1px);
  }

  .store-box-header {
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    a {
      text-decoration: none;
    }
  }

  .store-name {
    color: #585856;
    letter-spacing: 0.065em;
    // margin-bottom: 11px;
    text-transform: none;

    a {
      color: inherit;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      width: 80%;
      word-break: break-all;
    }
  }

  .address {
    font-style: normal;
  }

  .expanded {
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    flex-direction: column;
    letter-spacing: -0.02em;
    margin-top: 5px;
    row-gap: 13px;

    a {
      color: inherit;
    }
  }

  .countries {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 160px 160px;
    grid-template-rows: repeat(5, min-content);
    // margin: 30px 0 0;
    row-gap: 20.5px;

    li {
      font-size: 14px;
      letter-spacing: 0.08em;
      line-height: 14px;
      // padding: 0 0 0 15px;
    }

    .filter-stores-by-country {
      display: flex;
      align-items: center;
      column-gap: 5px;
      text-align: left;

      &::before {
        $sprite: map-get($sprites, 'store-marker');
        background: url($sprite) center no-repeat;
        content: '';
        display: block;
        flex-shrink: 0;
        /*
        height: 16px;
        opacity: 0.3;
        width: 16px;
        */
        height: 12px;
        width: 12px;
      }
    }
  }

  .back-to-search {
    display: flex;
    justify-content: center;
  }

  .reset-filter-stores-by-country {
    font-size: 14px;
    text-decoration: underline;
    text-transform: uppercase;
  }

  &-detail {
    row-gap: 18px;
    padding-left: 20px;

    section {
      // color: #585856;
      display: flex;
      flex-direction: column;
      letter-spacing: 0.04em;
      row-gap: 10px;

      &:first-child {
        color: #585856;
        margin-bottom: 15px;
        row-gap: 5px;

        * {
          color: inherit;
        }
      }
    }

    p {
      color: inherit;
      font-size: inherit;
      letter-spacing: 0.04em;
      margin: 0;
    }
  }

  section.store-message {
    margin: 20px 0;
  }
}

.page--storefinder-landing {
  height: 100vh;

  &.-stores-result-wrapper {
    height: auto;
    min-height: 100vh;
    .page-storelocator-landing {
      height: auto;
      margin-top: 0;
      min-height: 100vh;
    }
  }
}

#storefinder-landing {
  .pac-container {
    background: #fbfbf6;
    border: 1px solid #939393;
    box-shadow: none;
    transform: translateY(-1px);
  }

  .pac-item {
    font-size: 14px;
    line-height: 26px;
    padding: 10px 10px 10px 25px;

    &:not(:last-child) {
      border-bottom: 1px solid #939393;
    }
  }

  .pac-icon-marker,
  .hdpi.pac-logo:after {
    display: none;
  }
}
