.jil-registration {
  @include breakpoint(small only) {
    &.inner-box {
      padding-top: 14px;
    }
  }

  .fields-select {
    margin-top: 22px;
    // margin-bottom: 0 !important;
    width: 100%;

    > div {
      display: flex;
    }

    label {
      margin-right: 20px;
      margin-top: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .item-input,
  .item-check {
    margin-top: 12px;

    @include breakpoint(small only) {
      margin-top: 22px;
      line-height: 1.5;
    }
  }

  .item-check {
    letter-spacing: 0.02em;
  }

  .form-header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .title-required {
      text-align: right;
      color: $dark-gray;
    }
  }

  .form-field {
    p {
      cursor: auto;
    }

    input[type='checkbox'] {
      &.no-full-width {
        width: 15px;
      }
    }
  }

  .spaced {
    margin-top: 80px;
    margin-bottom: 32px;

    @include breakpoint(medium up) {
      margin-top: 40px;
      margin-bottom: 22px;
    }
  }
}

.form-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.form-title {
  font-size: rem-calc(12);
  font-weight: 400;
}

.date-wrapper {
  position: relative;
  .error-message + select,
  select + select {
    margin-left: 20px;
  }
  .error-message.-invalid {
    position: absolute;
    left: 0;
    top: 30px;
  }
}

.registration-form {
  .newsletter-interest-box {
    margin-left: 18px;
    position: relative;
    label {
      &:first-child {
        margin-left: 10px;
      }
    }

    .error-message.-invalid,
    .invalid-feedback {
      position: absolute;
      bottom: -15px;
    }
  }
  .-invalid {
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIzMnB4IiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCAzMiAzMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iR3JvdXAiIGZpbGw9IiNjMDAiPiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNiwzIEMyMy4yLDMgMjksOC44IDI5LDE2IEMyOSwyMy4yIDIzLjIsMjkgMTYsMjkgQzguOCwyOSAzLDIzLjIgMywxNiBDMyw4LjggOC44LDMgMTYsMyBMMTYsMyBaIE0xNiwwIEM3LjIsMCAwLDcuMiAwLDE2IEMwLDI0LjggNy4yLDMyIDE2LDMyIEMyNC44LDMyIDMyLDI0LjggMzIsMTYgQzMyLDcuMiAyNC44LDAgMTYsMCBMMTYsMCBaIiBpZD0iU2hhcGUiIGZpbGwtcnVsZT0ibm9uemVybyI+PC9wYXRoPiAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjE0LjUiIHk9IjciIHdpZHRoPSIzIiBoZWlnaHQ9IjEwIj48L3JlY3Q+ICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZSIgeD0iMTQuNSIgeT0iMjEiIHdpZHRoPSIzIiBoZWlnaHQ9IjQiPjwvcmVjdD4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
    background-position: 0 2px;
    background-repeat: no-repeat;
    background-size: 11px 11px;
    color: #c00;
    padding-left: 20px;
    position: relative;
  }
}

.-hide {
  display: none;
}

.-show {
  display: block;
}
