.product-tile {
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;

  @include breakpoint(xlarge) {
    margin-bottom: 55px;
  }

  @include breakpoint(xxlarge) {
    &:hover {
      .tile-badges {
        display: none;
      }
    }
  }

  .image-container {
    margin-bottom: 10px;
    position: relative;

    img {
      width: 100%;
    }

    .tile-image-hover {
      display: none;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity 0.15s ease-in-out;
    }
  }

  @include breakpoint(medium) {
    .image-container {
      .tile-image-hover {
        display: block;
      }
    }

    &:hover,
    &:focus {
      text-decoration: none;

      .image-container {
        .tile-image-hover {
          opacity: 1;
        }
      }
      .swatches {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  .tile-badges {
    column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 15px;
    font-size: 10px;

    @include breakpoint(small only) {
      font-size: 8px;
      letter-spacing: 0.5px;
      margin-top: 5px;
    }
    &.d-none {
      display: none;
    }
  }

  .pdp-link {
    margin: 0 20px;

    @include breakpoint(medium down) {
      margin: 0 5px 2px;

      &:not(:first) {
        margin-top: 2px;
      }
    }

    .link {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }

  .price {
    margin-top: 8px;
  }

  .price,
  .color-swatches {
    cursor: pointer;
  }

  .pdp-link .link {
    font-size: 11px;
    letter-spacing: 0.08em;
    line-height: 15px;
    font-weight: 400;

    // @include breakpoint(small only) {
    //   line-height: 12.7px;
    // }

    a {
      &:hover {
        border-bottom: 0;
      }
    }
  }

  .price span {
    font-size: 11px;
    letter-spacing: 0.08em;
    line-height: 15px;
    font-weight: 400;

    @include breakpoint(medium up) {
      font-size: 14px;
    }

    // @include breakpoint(small only) {
    //   line-height: 12.7px;
    // }

    a {
      &:hover {
        border-bottom: 0;
      }
    }
  }

  .price {
    > span {
      column-gap: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .strike-through {
      color: #c8c8c4;
      margin-right: 0;
      span {
        justify-content: center;
      }
    }

    .default-price-container {
      column-gap: 0;
      margin-bottom: 1rem;
      .strike-through {
        color: #c8c8c4;
        margin: 0 2px 0 0;
      }
    }
  }

  .sizes-clamped::after {
    content: '...';
  }

  .swatches {
    opacity: 0;
    pointer-events: none;
    padding-top: 10px;
    text-transform: none;
    display: flex;
    align-items: baseline;
    column-gap: 0;

    .color-display-name,
    .swatches-label {
      display: none;
    }

    @media screen and (min-width: 1920px) {
      .color-display-name,
      .swatches-label {
        display: block;
      }
    }

    @include breakpoint(xxlarge) {
      .swatches-link:nth-last-of-type(1) {
        padding-right: 0;
      }
    }

    &.color-swatches {
      padding-top: 7px;
    }

    a {
      position: relative;
      display: inline-block;
      text-align: left;
      text-transform: none;
      padding: 4px;
      border-bottom: 0;

      .swatch-circle {
        position: relative;
        // top: -1px;
        left: 0;
      }

      span {
        font-size: 11px;
        letter-spacing: 0.02em;
        white-space: nowrap;
        text-transform: lowercase;
      }

      &:hover {
        border-bottom: 0;
      }
    }
  }

  .color-swatches {
    opacity: 0;
    pointer-events: none;
    order: 2;
    @include breakpoint(small only) {
      display: none;
    }

    .swatch-circle {
      width: 11px;
      height: 11px;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      overflow: hidden;

      &.border {
        border: 1px solid rgba(0, 0, 0, 0.4);
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    a {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }
  }

  .attributes {
    display: flex;
    padding-bottom: 10px;
    justify-content: center;
    align-items: center;
    column-gap: 10px;

    @include breakpoint(xlarge down) {
      display: none;
    }
  }
}

.product-tile {
  .single-element-content-detail-price {
    .price {
      .default-price-container {
        // height: 17px;
        p {
          margin-bottom: 0;
          font-size: 11px;
          line-height: 15px;
        }
        .strike-through {
          margin-bottom: 0;
          // height: 17px;
          .value {
            align-items: center;
            height: inherit;
            margin-bottom: 0;
            font-size: 11px;
            line-height: 15px;
          }
        }
        .sales {
          // height: 17px;
          font-size: inherit;
          margin-top: 0;
          .value {
            align-items: center;
            height: inherit;
          }
        }
      }
    }
  }
}
