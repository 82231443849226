#paymentinstruments-list,
#wishlist-accountshow,
#wishlist-show {
  main {
    display: flex;
    justify-content: center;
    padding: 0 30px;

    .header-title {
      margin-bottom: 40px;
      h1 {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
      }
    }
    // .my-account-detail-title {
    //   display: flex;
    //   justify-content: space-between;
    //   border-bottom: 1px solid #969693;
    //   padding: 0 0 10px;
    //   .back-to-account-link {
    //     color: #969693;
    //     border-bottom: 1px solid $light-gray;
    //   }
    // }
    .page-account-content-wrapper {
      width: 640px;
    }
    @include breakpoint(large down) {
      flex-direction: column;
      .page-account-content-wrapper {
        width: 100%;
      }
    }
  }
}
#paymentinstruments-list {
  main {
    display: flex;
    justify-content: center;
    padding: 0 30px;
  }
  .page-account-payment {
    .no-saved-payments,
    .saved-credit-card-title {
      font-weight: 500;
      color: #585856;
      margin-bottom: 1.625rem;
    }
    .card {
      border: 1px solid $light-gray;
      font-size: 12px;
      padding: 18px 16px 25px;
      .card-body {
        display: flex;
        justify-content: space-between;
      }
      .card-info-group {
        width: 100%;
      }
      .saved-credit-card-info-wrapper {
        display: flex;
        font-size: 12px;
        width: 100%;
        .saved-credit-card-icon {
          margin-right: 8px;
          svg {
            height: 23px;
            width: 34px;
          }
        }
        .saved-credit-card-data {
          color: #585856;
          width: 100%;
          p {
            font-size: 12px;
          }
        }
        .saved-credit-card-expiration-ending {
          display: flex;
        }
      }
      .remove-card-button-wrapper {
        display: flex;
        align-items: end;
        a {
          border-bottom: 1px solid $light-gray;
          color: #969693;
        }
      }
    }
  }
  #payment-delete {
    .modal-background {
      height: 100%;
      padding-top: 25px;
      .container-payment-delete {
        height: inherit;
        .modal-content {
          display: flex;
          flex-direction: column;
          height: inherit;
          justify-content: space-between;
          padding: 60px 0 12px;
        }
        .modal-title {
          font-size: 12px;
          font-weight: normal;
          text-align: center;
        }
        .wrapper-actions {
          margin-top: 30px;
          button {
            // height: 49px;
            // width: 100%;
            &:first-child {
              margin-bottom: 30px;
              @include breakpoint(large up) {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
      @include breakpoint(large up) {
        height: auto;
        .modal-content {
          display: flex;
          flex-direction: column;
          height: inherit;
          justify-content: space-between;
          padding: 60px 0 12px;
        }
      }
    }
  }
}

#paymentinstruments-list {
  .paymentInstruments {
    > div {
      & + div {
        margin-top: 30px;
      }
    }
  }
  #payment-delete {
    margin-top: 0;
  }
}
