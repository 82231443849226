$include-fonts: false;

// 00 . Temp styles
// 00 . Grid
// 00 . Refinements

// 00 . Temp styles
.refinement-category,
.refinement-new-arrival,
.refinement-price {
  display: none;
}

// 00 . Grid
.grid-header {
  display: none;
}

// @import 'search/product-tile';

// JILS-53 breadcrumbs
.breadcrumb {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 10px;
  height: 120px;
  justify-content: center;
  line-height: 14.4px;
  margin: 48px 0 20px;

  @include breakpoint(large) {
    height: 250px;
    gap: 20px;
    margin: 44px 0 0;
  }

  .breadcrumb-items-nolast {
    display: flex;
    flex-direction: row;
  }

  .breadcrumb-item {
    padding: 0;

    @include breakpoint(large) {
      font-size: 10px;
      line-height: 12px;
    }
  }

  .breadcrumb-separator {
    margin: 0 5px;
  }

  .icon-arrow-left {
    display: table-cell;
    vertical-align: middle;

    svg {
      width: 10.5px;
      height: 10.5px;
    }

    @include breakpoint(large) {
    svg {
      width: 13.5px;
      height: 13.5px;
    }
  }
  }

  .breadcrumb-back-to {
    display: table-cell;
    vertical-align: middle;
    text-decoration: underline;
    text-transform: uppercase;
    padding-left: 8px;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    &:hover {
      border-color: transparent;
    }


  }

  .breadcrumb-item-last {
    padding: 0;

    @include breakpoint(small only) {
      font-size: 14px;
    }
  }

  h1.breadcrumb-item-last {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.91px;
    font-weight: normal;
    font-family: $body-font-family;
    padding: 10px 15px 0;
    text-align: center;
    @include breakpoint(medium up) {
      font-size: 24px;
      line-height: 28px;
      padding: 0;
    }

  }
}

.page-description.category-description {
  font-weight: 400;
  line-height: 18px;
  font-size: 13px;
  text-align: center;
  margin: -65px 15px 56px;

  @include breakpoint(large) {
    max-width: 976px;
    margin: -60px 35px 56px;
   
  }
  @include breakpoint(xlarge) {
    padding: 0 5px;
    margin: -60px auto 56px;
  }
}

.search-results {
  margin-top: 120px;

  @include breakpoint(large) {
    margin-top: 160px;
  }

  @include breakpoint(xlarge) {
    margin-top: 188px;
  }

  &.is-editorial {
    @include breakpoint(large) {
      margin-top: 200px;
    }
  }

  .no-results {
    min-height: 30vh;
    padding: 15px;

    .button {
      margin-top: 40px;
    }
  }

  .search-result-count {
    display: block;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    line-height: 1.5em;
    font-size: 11px;
    margin-top: 39px;

    font-weight: normal;
    font-family: $body-font-family;

    @include breakpoint(medium up) {
      font-size: 9px;
      margin-top: 45px;
    }

    @include breakpoint(large up) {
      margin-top: 17px;
      margin-bottom: 6px;
    }
  }

  .toggle-section {
    font-size: 10px;
    letter-spacing: 0.08em;
    line-height: 1.4em;
    text-align: center;
    margin: 145px auto 0;
    text-transform: uppercase;
    font-weight: 400;
    z-index: 9999;
    overflow: hidden;

    #sale-toggle {
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }

    @include breakpoint(small only) {
      font-size: 12px;
    }
  }

  .toggle-section-active {
    margin-bottom: 50px;
  }

  .show-more .btn,
  .plp-bottom-link {
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;

    &:hover {
      border-bottom: 1px solid #000;
    }

    @include breakpoint(small only) {
      font-size: 12px;
    }
  }

  .show-more {
    margin-top: 110px;
    .btn {
      margin-top: 40px;
    }
  }

  .plp-bottom-link {
    margin: 15px auto 50px;
    overflow: hidden;
  }
}

.product-grid {
  @include breakpoint(small only) {
    margin: 30px 5px;
  }

  @include breakpoint(large only) {
    margin: 20px;
  }

  @include breakpoint(medium only) {
    margin: 20px;
  }

  @include breakpoint(xlarge) {
    margin: 25px 30px;
  }

  &:not(.grid-margin-x) {
    @include breakpoint(small only) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  
  @include breakpoint(medium up) {
    &.product-grid-4 {
      &.product-grid>.medium-3 {
        width: 25% !important;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @include breakpoint(xlarge) {
    &.product-grid-6 {
      margin: 25px 45px;

      &.product-grid>.medium-3 {
        width: calc(16.66% - 1.875rem) !important;
        padding: 0;
        margin-left: 0.9375rem;
        margin-right: 0.9375rem;
      }

      .product-tile {
        .swatches-label,
        .color-display-name {
          display: none;
        }
      }

      .sizes-above-three {
        &:after {
          content: '...';
        }
  
        a {
          &.swatches-link {
            display: none;
  
            &:nth-of-type(-n+3) {
              display: inline-block;
            }
          }
        }
      }

      .color-swatches-above-three {
        &:after {
          content: '...';
        }

        a {
            display: none;
  
            &:nth-of-type(-n+3) {
              display: flex;
            }
        }
      }
    }
  }

  .product {
    padding: 0 15px;
    overflow: hidden;

    @include breakpoint(medium down) {
      padding: 0;
    }

    @include breakpoint(large only) {
      padding: 0 5px;
    }

    &.product-tile-large {
      .product-tile {
        padding: 0;
      }
    }

    &:nth-child(3n + 1) {
      &.product-tile-large {
        .product-tile {
          width: calc(100% + 60px);
          margin-left: -60px;
        }
      }
    }

    &:nth-child(3n + 2) {
      &.product-tile-large {
        .product-tile {
          width: calc(100% + 60px);
          margin-left: -30px;
        }
      }
    }

    &:nth-child(3n + 3) {
      &.product-tile-large {
        .product-tile {
          width: calc(100% + 60px);
        }
      }
    }

    @include breakpoint(small only) {
      &:nth-child(3n + 1),
      &:nth-child(3n + 2),
      &:nth-child(3n + 3) {
        &.product-tile-large {
          @include xy-cell(full);

          .product-tile {
            width: 100%;
            margin-left: 0;
            padding: 30px;
          }
        }
      }
    }
    &.shop-the-look-tile {
      .price {
        display: none;
      }
    }
  }
}

.filters-open {
  @include breakpoint(small only) {
    .filter-wrap {
      z-index: 1001;
    }
  }
}

.filter-wrap {
  position: relative;

  .close_filter_panel {
    display: none;

    @include breakpoint(small only) {
      svg {
        width: 100%;
        height: 100%;
      }

      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  .boolean-refinements {
    z-index: 2;
    position: relative;

    @include breakpoint(small only) {
      display: none;
    }

    > div {
      padding: 15px 15px;
      display: inline-block;
      margin: 0;
      z-index: 2;
      position: relative;
    }

    a {
      font-size: 9px;
      letter-spacing: 0.08em;
      line-height: 1.5em;
      font-weight: 400;
      text-align: center;
      text-transform: uppercase;

      &.selected {
        border-bottom: 1px solid $black;
        padding-bottom: 0;
      }
    }
  }

  .filter {
    z-index: 1;

    .toggle_filter_panel {
      color: #000;
      cursor: pointer;
      display: flex;
      font-weight: 400;
      text-align: center;
      margin: 0;
      border-bottom: 0;
      align-items: center;
      column-gap: 3px;
      @include breakpoint(small only) {
        font-size: 14px;
      }
      > span {
        opacity: 0.7;
        z-index: 2;
        position: relative;
        text-transform: uppercase;
      }

      span {
        &:first-child {
          margin-right: 1px;
        }
      }

      &:hover {
        span {
          opacity: 1;
        }
      }

      > svg {
        opacity: 0.7;
        position: relative;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        z-index: 2;
        top: 2px;
      }
    }

    .view-columns {
      cursor: pointer;
      text-transform: uppercase;
      opacity: 0.7;
      @include breakpoint(small only) {
        font-size: 14px;
      }
      span {
        &:first-child {
          margin-right: 1rem;

          @include breakpoint(small only) {
            margin-right: 0.5rem;
          }
        }

        &:not(:first-child) {
          margin: rem-calc(0 3);

          // &:hover,
          &[data-active='true'] {
            border-bottom: 1px solid $black;
          }
          // &:hover {
          //   border-bottom: none;
          // }
        }
      }
    }

    @include breakpoint(medium up) {
      .view-columns {
        display: none;
      }
    }

    .open_filter_panel {
      display: flex;
    }

    .close_filter_panel,
    .applied_filters_number {
      display: none;
    }

    &.open {
      /*
            .open_filter_panel {
                display: none;
            }
            */

      .filter-panel {
        display: block;
        position: relative;
        height: 100%;

        @include breakpoint(small only) {
          display: flex;
          flex-direction: column;
        }
      }

      /*
            .toggle_filter_panel {
                span {
                    &:first-child {
                        border-bottom: 1px solid $black;
                    }
                }

                .close_filter_panel {
                    display: inline-block;
                }
            }
            */

      @include breakpoint(small only) {
        .filter-panel {
          transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
          transform: translateY(0);
        }
      }
    }

    &.applied {
      .open_filter_panel {
        display: none;
      }

      .applied_filters_number {
        display: block;
      }
    }
  }

  .filter-panel {
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    z-index: 1;
    min-width: 480px;
    height: 100%;

    @include breakpoint(large) {
      padding-top: 10px;
    }

    @include breakpoint(small only) {
      display: block;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      min-width: 100%;
      overflow: auto;
      z-index: 2;
      padding: 0;
      background-color: map_get($jil-palette, 'off-white');
      font-size: 13px;
      transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
      transform: translateY(100%);

      .close_filter_panel {
        display: block;
        // height: 18px;
        // width: 18px;
        padding: 0;
        cursor: pointer;
        position: absolute;
        top: 24px;
        right: 16px;
      }

      .filter-header,
      .filter-items,
      .filter-apply {
        padding: 0 25px;
      }
    }

    .filter-header {
      text-transform: uppercase;
      margin-bottom: 32px;

      @include breakpoint(small only) {
        margin-bottom: 20px;
      }
    }

    .filter-items {
      display: flex;
      flex-direction: column;
      padding-top: 48px;
      height: 100%;
      overflow: auto;

      @include breakpoint(medium up) {
        max-height: calc(100% - 52.8px);
      }
    }

    .refinement {
      display: flex;
      justify-content: center;
      padding: 4px;
      flex-direction: column;
      gap: 15px;
      font-size: 9px;

      @include breakpoint(small only) {
        flex-direction: column;
        align-items: center;
        gap: 5px;
      }

      @include breakpoint(medium only) {
        .ref-values {
          max-width: 80%;
        }
      }

      .ref-name {
        font-size: 12px;
        text-transform: uppercase;
        margin-top: 57px;
        margin-right: 6px;
        letter-spacing: 0.08em;

        @include breakpoint(small only) {
          margin-right: 0;
        }
      }

      .ref-values {
        @include breakpoint(small only) {
          margin-top: 7px;
        }
      }

      &.refinement-size {

        .ref-values {
          width: 100%;

          ul {
            display: grid !important;
            gap: 10px;
            grid-template-columns: repeat(auto-fit, minmax(26px, 1fr));
            margin-left: 0 !important;

            li {
              text-align: left;

              & a {
                margin-left: 0;
              }
            }
          } 
        }
    }
  }

    li {
      line-height: 1.2;
      display: inline-block;
      padding: 0;
      margin: 0 8px;

      a {
        margin-left: 24px;
      }
    }

    a {
      padding: 4px 6px;
      display: inline-block;
      border: none;
      padding-left: 24px;

      > span {
        border-bottom: 1px transparent solid;
        color: $link-color-gray;
      }

      &.selected {
        > span {
          border-bottom: 1px #000 solid;
          color: #000;
        }
      }

      &:hover,
      &:focus {
        border: none;

        > span {
          border-bottom: 1px solid map_get($jil-palette, 'medium-gray');
        }
      }

      @include breakpoint(small only) {
        // padding: 10px;
        padding: 8px 0 7px;
        min-width: 25px;
      }
    }

    @include breakpoint(small only) {
      .filter-items {
        > .grid-x {
          flex-flow: column wrap;
        }
      }

      .card {
        flex: none;
        margin-bottom: 15px;
      }
    }

    .refinement-new-arrival {
      @include breakpoint(small only) {
        display: block;
      }
    }

    .reset {
      text-transform: uppercase;
      color: $link-color-gray;

      @include breakpoint(small only) {
        display: block;
        order: 2;
      }
    }

    li.color-attribute {
      a {
        display: flex;
        gap: 10px;
      }
    }
  }

  .swatch-circle {
    background-color: #555;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;

    &.border {
      border: 1px solid rgba(0, 0, 0, 0.4);
    }

    @include breakpoint(small only) {
      width: 16px;
      height: 16px;
    }
  }

  .refinement-size {
    order: 1;
  }

  .refinement-color {
    order: 2;

    a {
      display: block;
      position: relative;

      > span {
        display: inline-block;
        align-items: center;
        border-bottom: 1px transparent solid;
        position: relative;

        &:last-child {
          top: 4px;
        }
      }

      &:hover,
      &:focus {
        > span {
          border-color: transparent;
        }

        .selected {
          border-color: #000 !important;
          color: #000;
        }
      }
    }
  }

  .refinement-type {
    a {
      text-transform: uppercase;
    }
  }

  .refinement-color {
    &.selected,
    .selected {
      border-bottom: 1px solid $black;
    }
  }

  .filter-checkmark {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 5px;

    @include breakpoint(small only) {
      display: none;
    }
  }

  .sort-order-link {
    position: relative;

    .filter-checkmark {
      opacity: 0;
      position: absolute;
      top: 0;
      right: -20px;
    }

    &.selected,
    .selected {
      .filter-checkmark {
        opacity: 1;
      }
    }
  }

  li.disabled {
    display: none;
  }
}

.filter-apply {
  position: sticky;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 15px;
  background-color: $off-white;
  display: flex;
  margin: 0;
  z-index: 2; // JILS-186

  @include breakpoint(small only) {
    margin-top: auto;
    flex-direction: column;
    gap: 10px;
  }

  @include breakpoint(medium up) {
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    z-index: 2;
  }

  button {
    border-color: #000;
    font-size: 12px;

    @include breakpoint(medium up) {
      padding: 10px 50px !important;
    }
  }

  .loading-products-spinner {
    margin: 0 auto;
    padding: 0;
  }
}

body.filters-open {
  overflow: hidden;
}

.loading-products-spinner {
  display: none;
  justify-content: center;
  margin-bottom: 5px;

  @include breakpoint(medium up) {
    position: absolute;
    bottom: 55px;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
  }
}

.loading {
  .loading-products-spinner {
    display: flex;
  }
}

.price {
  del {
    display: inline-block;
  }

  .strike-through {
    display: inline-block;
    // JILS-198
    // margin-right: 10px;
    margin-right: 2px;
  }
}

.refinement-bar {
  > .filter-wrap {
    align-items: center;
    flex-direction: row;
    font-size: 13px;
    justify-content: space-between;
    letter-spacing: 0.065em;
    line-height: 14px;
    margin: 0 60px;

    @include breakpoint(small only) {
      margin: 0 15px;
    }

    @include breakpoint(medium only) {
      margin: 0 35px;
    }

    @include breakpoint(large only) {
      margin: 0 40px;
    }

    @include breakpoint(xlarge) {
      margin: 0 60px;
    }
  }
}

.modal-overlay.filter-modal {
  height: 100dvh;
  .modal-max-height {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100dvh;

    @include breakpoint(medium up) {
      max-height: 745px;
    }
  }
  .modal-header {
    justify-content: space-between;
    padding-left: 32px;
    padding-top: 15px;

    button.modal-close {
      display: flex;
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;

      @include breakpoint(medium up) {
        padding: 0;
      }
    }

    h2 {
      font-size: 12px;
    }

    &.modal-header_desktop {
      display: none;

      @include breakpoint(medium up) {
        display: flex;
      }
    }

    &.modal-header_mobile {
      position: static;
      padding-left: 0;

      h2 {
        padding-left: 0;
        margin-bottom: 0;
      }

      button.modal-close {
        right: 0;
      }

      @include breakpoint(medium up) {
        display: none;
      }
    }
  }

  .modal-content {
    display: flex;
    max-height: 100%;
    flex-grow: 1;
    padding: 0;
    background-color: $off-white;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: #ccc transparent;

    @include breakpoint(medium up) {
      padding-left: 0;
      padding-right: 0;
    }

    @include breakpoint(small only) {
      max-height: none;
      max-width: none;
    }

    .filter-wrap {
      width: 100%;

      .filter.cell {
        height: 100%;
        overflow: hidden;
      }
    }

    .filter-items {
      padding: 0 32px 30px 32px;
      text-transform: uppercase;
      scrollbar-width: thin;
      scrollbar-color: #ccc transparent;

      .refinement {
        padding-left: 0;
        align-items: flex-start;

        .ref-values ul {
          display: flex;
          flex-wrap: wrap;
          margin-left: -24px;
        }

        .ref-values ul li {
          margin-left: 0;
          
          @include breakpoint(medium up) {
            a {
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  .filter-apply {
    display: flex;
    align-items: center;
    padding: 8px 15px;

    @include breakpoint(medium up) {
      padding-left: 32px;
      padding-right: 32px;
    }

    a {
      order: 0;
      border-style: solid;
      border-width: 1px;
      border-color: #757575;
      color: #000;
      font-size: 12px;
      max-height: 36.8px;

      @include breakpoint(medium up) {
        padding: 10px;
      }

      &.disabled {
        border-color: rgba(0, 0, 0, .6);
        color: rgba(0, 0, 0, .6);
        pointer-events: none;
      }
    }

    button {
      background-color: #000;
      color: #FBFBF6;

      @include breakpoint(medium up) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        border: 0;
        margin-left: 24px;
      }
    }

    a, button {
      min-width: 192.5px;

      @include breakpoint(medium up) {
        min-width: 181px;
      }
    } 
  }

  .modal-close {
    right: 5px;
    top: 5px;

    @include breakpoint(medium up) {
      z-index: 2; // JILS-186
      position: sticky;
      position: -webkit-sticky;
      padding-left: 100%;
    }

    // svg {
    //   height: 18px;
    //   width: 18px;
    // }
  }
}

.back-to-top {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 80px; /* Place the button at the bottom of the page */
  right: 20px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: 0; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: #e6e6e6; /* Set a background color */
  cursor: pointer; /* Add a mouse pointer on hover */
  font-size: 39px;
  width: 60px;
  height: 60px;
  border-radius: 50%;

  &:before {
    display: block;
    content: '';
    width: 20px;
    height: 20px;
    background-color: transparent;
    transform: translateX(17px) rotate(-45deg) translateY(5px);
    border-top: 2px solid black;
    border-right: 2px solid black;
  }
}

.breadcrumb-component + .search-results {
  margin-top: 60px;

  .product-grid {
    @include breakpoint(small only) {
      margin-top: 15px;
    }
  }
}

.additional_content {
  img {
    width: 100%;
  }
}

[data-grid-columns='1'],
[data-grid-columns='4'] {
  .additional_content {
    @include breakpoint(small only) {
      display: none;
    }
    @include breakpoint(medium up) {
      display: none;
    }
  }
}

.suggestions-grid {
  .suggestions-grid-description {
    .single-element-content-detail-price {
      display: flex;
      justify-content: center;
    }
  }
}

a.disabled {
  pointer-events: none;
}
