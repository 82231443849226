// JILS-446
// .findinstore-modal {
//   // .modal-background {
//   //   max-width: 700px;
//   // }

//   // JILS-459
//   .modal-header {
//     border-bottom: 0;
//   }

//   .modal-content {
//     max-width: none;

//     // JILS-459
//     > div {
//       // row-gap: 40px;
//     }
//   }

//   .btn {
//     align-self: center;
//     width: 330px;

//     // JILS-459
//     font-size: 14px;
//     line-height: 17px;
//   }

//   // JILS-459
//   .store-phone a {
//     border-bottom: 0;
//   }
// }
.primary-images {
  .carousel-wrapper {
    max-width: fit-content;
  }
}
