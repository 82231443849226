.breadcrumb-component.breadcrumb-component-pdp {
  background: transparent;
  display: flex;
  justify-content: center;
  margin-top: rem-calc(60);
  padding: rem-calc(18) 0;
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  .breadcrumb {
    flex-direction: row;
    height: auto;
    margin: 0;
    gap: 0;
    display: flex;
    justify-content: flex-start;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .breadcrumb-item {
    align-items: center;
    display: flex;
    font-size: rem-calc(12);
    font-weight: 400;
    line-height: rem-calc(14);
    letter-spacing: 0.08em;
    text-transform: uppercase;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;

    @include breakpoint(large up) {
      font-size: rem-calc(10);
    }
  }
}
