.experience-layouts-wrapperLinksCategory {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: rem-calc(15 20);
    text-align: center;
    text-transform: uppercase;

    .experience-component.experience-assets-linkCategory{
        font-size: rem-calc(14);
        margin-bottom: rem-calc(30);

        &:last-child{
            margin-bottom: 0;
        }

        a:active {
            opacity: 0.6;
            text-decoration: none;
        }
    }

    @include breakpoint(small down) {
        padding: rem-calc(30 20);
    }
}
