.cookie-warning {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 40px 32px 32px;

  p {
    font-size: 14px;
  }

  @include breakpoint(medium up) {
    padding: 16px;

    p {
      margin-right: 64px;
      margin-bottom: 0;
      font-size: 12px;
    }
  }

  .button {
    border-color: $button-bg-blue;
  }

  .close.icon {
    position: absolute;
    right: 32px;
    top: 24px;

    @include breakpoint(medium up) {
      top: 50%;
      transform: translateY(-50%);
    }

    span::after {
      content: '';
      background-image: url('data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMzIgMzIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyIDMyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CiAgICA8bGluZSBzdHlsZT0iZmlsbDogbm9uZTsgc3Ryb2tlOiAjMDAwMDAwOyBzdHJva2Utd2lkdGg6IDM7IHN0cm9rZS1taXRlcmxpbWl0OiAxMDsiIGNsYXNzPSJzdDAiIHgxPSIwLjUiIHkxPSIwLjUiIHgyPSIzMS41IiB5Mj0iMzEuNSIgLz4KICAgIDxsaW5lIHN0eWxlPSJmaWxsOiBub25lOyBzdHJva2U6ICMwMDAwMDA7IHN0cm9rZS13aWR0aDogMzsgc3Ryb2tlLW1pdGVybGltaXQ6IDEwOyIgY2xhc3M9InN0MCIgeDE9IjAuNSIgeTE9IjMxLjUiIHgyPSIzMS41IiB5Mj0iMC41IiAvPgo8L3N2Zz4K');
      height: 8px;
      width: 8px;
      display: block;

      @include breakpoint(medium up) {
        height: 12px;
        width: 12px;
      }
    }
  }
}
