.recommendations {
  .container-carousel {
    padding-top: 1.5rem;
    row-gap: 20px;
  }
}

.single-element-content-detail-price {
  .price {
    .default-price-container {
      // height: 17px;
      p {
        margin-bottom: 0;
      }
      .strike-through {
        margin-bottom: 0;
        // height: 17px;
        .value {
          align-items: center;
          height: inherit;
        }
      }
      .sales {
        // height: 17px;
        margin-top: 0;
        .value {
          align-items: center;
          height: inherit;
        }
      }
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  display: block;
}

.swiper-pagination {
  display: none;
}
