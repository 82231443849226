#cookies-modal {
  display: none;
  &.is-open {
    display: block;
  }
  .cookies-modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1005;
    background-color: rgba($color: #000000, $alpha: 0.45);
    overflow-y: auto;
  }

  .cookies-modal-container {
    background-color: $off-white;
    width: 100%;
    padding-top: 20px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 2;

    .cookies-modal-close {
      position: absolute;
      top: 29px;
      right: 32px;
      color: map-get($jil-palette, 'medium-gray');
      text-transform: uppercase;
      text-decoration: underline;
    }

    .cookies-modal-content {
      padding: 50px 30px 30px 30px;
      .content-text {
        display: flex;
        flex-direction: column;
        gap: 15px;
        span {
          font-size: 12px;
          &.underlined {
            text-decoration: underline;
          }
        }
      }
      .content-btn {
        display: flex;
        flex-direction: column-reverse;
        .preferences-btn {
          text-transform: uppercase;
          text-decoration: underline;
          margin-top: 30px;
        }
        .accept-btn {
          margin-top: 30px;
          height: 49px;
          font-size: 12px;
          background-color: #080f21;
          color: #fbfbf6;
        }
      }
    }
  }
}

#cookies-management-modal {
  display: none;
  &.is-open {
    display: block;
  }
  .cookies-modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1005;
    background-color: rgba($color: #000000, $alpha: 0.45);
    overflow-y: auto;
  }

  .cookies-modal-header {
    .modal-title {
      font-size: 14px;
    }
    padding: 50px 30px 30px 30px;
  }
  .cookies-modal-container {
    background-color: $off-white;
    width: 100%;

    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    z-index: 2;

    .cookies-modal-close {
      position: absolute;
      top: 12px;
      right: 20px;
    }

    .cookies-modal-content {
      padding: 0 30px 30px 30px;
      .form-field {
        input[type='checkbox'] + .checkbox-label {
          &::before {
            width: 15px;
            height: 15px;
          }
          &::after {
            width: 9px;
            height: 9px;
          }
        }
        .checkbox-label {
          font-size: 14px;
        }
      }
      p {
        margin: 15px 0 30px 0;
        font-size: 12px;
      }
      .jil-button {
        &:not(last-child) {
          margin-bottom: 10px;
        }
        &.-no-border {
          border: none;
        }
      }
    }
  }
}
