footer {
  .footer-menu {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0 45px;
    gap: 60px;
    flex-wrap: wrap;

    .footer-newsletter {
      order: 2;
      flex: 0 0 calc(30% - 30px);
      padding-bottom: 0;

      .newsletter-page-wrapper {
        position: static;

        .newsletter-input-wrapper,
        .newsletter-interest-box {
          width: auto;
        }

        .newsletter-submit {
          width: 100%;
          position: static;
          right: auto;
          top: auto;
        }

        .spacer:has(~ .newsletter-submit) {
          display: block;
        }
      }
    }

    .footer-links {
      flex: 0 0 calc(70% - 30px);
    }
  }
}