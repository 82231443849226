#wishlist-accountshow,
#wishlist-show {
  .product-grid {
    .product {
      padding: 0;
      padding-bottom: 20px;
      position: relative;
      .remove-from-wishlist {
        position: absolute;
        top: 12px;
        right: 18px;
      }
      @include breakpoint(large up) {
        padding: 0;
        padding-bottom: 20px;
        .remove-from-wishlist {
          display: none;
        }
        &:hover {
          .remove-from-wishlist {
            display: block;
          }
        }
      }
      .product-tile {
        .pdp-link {
          margin: 0 5px;
          height: 35px;
        }
        .empty-price{
          height: 34px;
        }
        .selected-product-variants {
          display: block;
          margin: 5px 0;
          .selected-attribute {
            align-items: center;
            display: flex;
            justify-content: center;
            text-transform: capitalize;
            margin: 5px 0;
            .attribute-name {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
  .minicart {
    .product-grid {
      margin: 25px 30px;
      grid-template-columns: unset;
      column-gap: unset;
      display: flex;
      justify-content: space-between;
      .product {
        .product-tile {
          .selected-product-variants {
            display: none;
          }

          .pdp-link {
            margin: 0 40px;
            height: unset;
          }

          span.default-price-container {
            height: unset;
          }
        }
      }
    }
  }
  .product-grid {
    // margin: 25px 0;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    @include breakpoint(large up) {
      // margin: 25px 0;
      margin: 0;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 20px;
    }
  }
  .wishlist-tile-add-to-cart {
    margin-top: 10px;
    .jil-action-button {
      font-size: 12px;
      padding: 10px 1px;
    }
  }
  .empty-wishlist {
    align-items: center;
    display: flex;
    flex-direction: column;
    p {
      margin: 26px 0;
      text-align: center;
    }
    .button {
      align-items: center;
      display: flex;
      height: 49px;
      justify-content: center;
      width: 100%;
    }
  }
  span.default-price-container {
    height: 30px;
  }

}

// .product-info {
//   margin-bottom: 40px;
//   position: relative;
//   display: flex;
//   flex-direction: column;

//   .button {
//     margin-top: 7px;
//     letter-spacing: 0.08em;
//     line-height: 1.4;
//   }

//   .item-image {
//     margin-bottom: 10px;
//   }

//   &:hover {
//     .remove-btn {
//       display: flex;
//     }
//   }

//   &.not-available {
//     opacity: 0.6;
//   }

//   .remove-btn {
//     display: none;
//     position: absolute;
//     top: 0;
//     right: 0;
//     height: 33px;
//     width: 33px;
//     align-items: center;
//     justify-content: center;
//     line-height: 1;
//     opacity: 0.6;

//     &:hover {
//       opacity: 1;
//     }
//   }

//   .line-item-name,
//   .line-item-availability,
//   .price {
//     text-transform: uppercase;
//     text-align: center;
//     font-size: 11px;
//     letter-spacing: 0.08em;
//     line-height: 1.4;

//     @include breakpoint(medium up) {
//       font-size: 10px;
//     }

//     a {
//       display: inline-block;

//       &:hover {
//         border-color: transparent;
//       }
//     }
//   }

//   .price {
//     del {
//       display: inline-block;
//     }
//   }

//   .add-to-cart-button-container {
//     margin-top: auto;
//   }

//   .add-to-cart-wishList .add-to-cart {
//     position: relative;

//     &.is-loading {
//       color: transparent;

//       &::before {
//         content: '';
//         display: block;
//         z-index: 1;
//         width: 10px;
//         height: 10px;
//         background-color: #000;
//         position: absolute;
//         left: 50%;
//         top: 50%;
//         margin-left: -5px;
//         margin-top: -5px;
//         border-radius: 50%;
//         animation: ani_addtobag_button_isloading 1s linear infinite;
//       }
//     }

//     @keyframes ani_addtobag_button_isloading {
//       0% {
//         opacity: 1;
//       }

//       50% {
//         opacity: 1;
//       }

//       50.1% {
//         opacity: 0;
//       }

//       100% {
//         opacity: 0;
//       }
//     }
//   }
// }
