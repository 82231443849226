.contact-page {
    align-items: center;
    min-height: calc(100vh - 225px);
    @include container;

    margin-top: 140px;

    .item-input {
        display: block;
        margin: 22px 0;
    }

    textarea {
        padding: 6px;
    }

    .g-recaptcha {
        // transform: scale(1.05963);
        // transform-origin: 0 0;
        > div {
            margin: auto;

            &.invalid {
                border: 1px solid $error-color;
            }
        }
    }

    @include breakpoint(small only) {
        .header span {
            font-size: rem-calc(14);
        }

        .item-input {
            margin: rem-calc(32 0);
        }
    }
}
