#notify-me-modal {
  .modal-background {
    padding: 0;
    width: 508px;
    .modal-notify-me {
      width: 330px;
      margin: auto;
      .modal-header {
        border: none;
      }
      .modal-content {
        padding: 5px 0 30px 0;
        overflow: visible;
      }
      .submit-button {
        position: static;
        padding: 0;
        margin-top: 30px;
      }
    }
  }
}