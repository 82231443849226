$modalHeight: 540px;
.fullscreen-modal {
  &.size-guidelines-modal-wrapper {
    .js-modal-container {
      @include breakpoint(large up) {
        // max-height: 540px;
        // max-width: 700px;
        height: $modalHeight;
        max-height: $modalHeight;
        max-width: 700px;
      }
    }
  }
}

#size-guidelines-modal {
  // .modal-header {
  //   height: 58px;
  // }
  .accordion-group-list-container {
    padding-top: 0;
  }
  // .modal-close {
  //   left: 100%;
  //   top: 15px;
  //   z-index: 2;
  // }
  .modal-header {
    .modal-close {
      display: block;
      opacity: 1;
      position: absolute;
      left: unset;
      right: 15px;
      top: 17px;
    }
  }

  .modal-content {
    overflow: auto;
    padding: 0;
    max-width: unset;
  }

  .modal-title {
    font-size: 14px;
    @include breakpoint(large up) {
      font-size: 12px;
    }
    font-weight: normal;
    letter-spacing: 0.8px;
    text-align: center;
  }
  .edit-product-title {
    margin: 0.5rem 0;
    text-transform: uppercase;
  }

  .size-guidelines-trigger-box {
    text-align: right;
    margin-bottom: 25px;
  }

  .modal-footer {
    display: none;
  }

  .product-quickview {
    margin-bottom: 0;
  }

  .fitguide__titles {
    align-items: center;
    display: flex;
    padding: 0 30px;
    .tab-button {
      font-weight: 500;
      // font-size: 12px;
      letter-spacing: 0.08em;
      margin: 0 30px 0 0;
      line-height: 14px;
      text-align: center;
      .fitguide__tab-button--active {
        text-decoration: underline;
      }
      button {
        text-transform: uppercase;
      }
    }
    @include breakpoint(medium down) {
      flex-direction: column;
      justify-content: center;
      margin: 20px 0;
      .tab-button {
        margin: 10px 0;
      }
    }
  }

  .fitguide__content {
    display: none;
    overflow-x: hidden;
    padding: 0 30px;
    &.fitguide__content--active {
      display: block;
    }
  }

  .fitguide__interactive {
    .table-details-value {
      ._measures-cm,
      ._measures-in {
        &:not(.d-none) {
          display: flex;
        }
      }
      .unit-measure {
        display: block;
        margin-left: 4px;
      }
      .value-letter {
        align-items: center;
        border: 1px solid #585856;
        color: #585856;
        display: flex;
        font-size: 9px;
        justify-content: center;
        margin-right: 13px;
        width: 16px;
      }
      &:not(.d-none) {
        display: flex;
        flex-direction: row-reverse;
        width: max-content;
      }
    }

    .single-detail-content {
      display: flex;
      flex-direction: column-reverse;
    }

    .table-details-label {
      margin-left: 29px;
    }

    .choose-size-radio-input-row {
      display: flex;
      flex-direction: column-reverse;
      .choose-size-and-unit-measure {
        margin: 0 0 20px;
        max-width: 329px;
        position: relative;
        select {
          background: transparent;
          margin-top: 17px;
          padding-left: 10px;
        }
        .custom-icon {
          svg {
            position: absolute;
            bottom: 7px;
            right: 10px;
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }

  .image-and-sizing-wrapper {
    li {
      padding-left: 16px;
      position: relative;
      .dash-list {
        border-top: 1px solid;
        left: 0;
        position: absolute;
        top: 12px;
        width: 8px;
      }
    }
  }

  .table-wrapper {
    // max-width: 500px;
    // margin: auto;
    margin-top: 3rem;
    overflow-x: auto;
    &.-no-margin-top {
      margin-top: 0;
    }
    table {
      position: relative;
      border-collapse: collapse;
      border-spacing: 0;
      table-layout: auto;
      width: 100%;
      tbody {
        td {
          text-align: left;
          vertical-align: middle;
        }
      }
      .fixed-side {
        background-color: $off-white;
        left: 0;
        padding-right: 10px;
        position: sticky;
      }
      .unit-measure-value {
        display: block;
      }
    }
  }

  .measuring-guide__radio-input {
    input {
      accent-color: $black;
    }
    &.-jils-measuring-guide-input {
      .form-group {
        align-items: center;
        display: flex;
        // font-size: 12px;

        input {
          margin-right: 15px;
        }
        label {
          margin-right: 30px;
        }

        .change-unit-measure-label {
          margin-right: 4px;
          color: rgba(0, 0, 0, 0.6);
        }
        .jils-change-unit-measure {
          color: rgba(0, 0, 0, 0.6);
          label,
          input {
            cursor: pointer;
            margin-right: 0;
          }
          &.-active {
            text-decoration: underline;
          }
        }
        .change-unit-measure-separator {
          color: rgba(0, 0, 0, 0.6);
          margin: 0 4px;
        }
      }
    }
    &.measure-guide-interactive-input {
      .form-group {
        display: flex;
        margin: 15px 0 17px;
        label {
          display: flex;
          align-items: center;
          margin-right: 30px;
          input {
            margin-right: 13px;
          }
        }
      }
    }
  }

  .js-modal-container {
    // height: 100vh;

    .widget-sizeguide {
      min-height: inherit;
    }
    @include breakpoint(large up) {
      // max-height: 540px;
      // max-width: 700px;
      .table-details {
        align-items: baseline;
        display: flex;
        justify-content: space-between;
        .single-detail-content {
          width: 104px;
        }
      }
    }

    .measuring-guide {
      overflow-x: hidden;
      position: relative;
      padding-bottom: 20px;
    }
    .measuring-guide-container {
      margin: 0 -30px 30px;
      padding: 0 30px 30px;
      position: relative;
      .measuring-guide__header-content {
        display: none;
      }
    }

    .body-measure-table {
      thead {
        tr {
          th {
            &:first-child {
              display: none;
            }
          }
        }
      }
    }
  }

  .modal-background {
    padding: 0;
  }

  .measuring-guide__toggler {
    position: absolute;
    left: 30px;
    bottom: -15px;
    @include breakpoint(large up) {
      left: unset;
      right: 32px;
    }
    label {
      margin-right: 0;
      position: relative;
      input {
        position: absolute;
        height: 100%;
        left: 0;
        opacity: 0;
        width: 100%;
      }
    }
  }

  .interactive-unit-measure-label {
    align-items: center;
    display: flex;
    margin-right: 30px;
    input {
      margin-right: 13px;
    }
  }

  .js-in {
    &:after {
      content: '"';
    }
  }

  .measuring-img__sketches {
    height: 200px;
    margin-top: 8px;
    width: 100%;
    img {
      width: inherit;
      height: inherit;
      max-height: inherit;
    }
    @include breakpoint(large up) {
      height: 100%;
      margin-bottom: 80px;
      max-height: 390px;
    }
  }
}
