footer {
  .footer-menu {
    padding: 0 25px;

    .footer-newsletter {
      flex: 0 0 100%;
      order: 0;
      max-width: none;

      .newsletter-page-wrapper {
        position: relative;

        .newsletter-input-wrapper,
        .newsletter-interest-box {
          width: 60%;
        }

        .newsletter-submit {
          width: 35%;
          position: absolute;
          right: 0;
          top: 40px;
        }

        .spacer:has(~ .newsletter-submit) {
          display: none;
        }
      }
    }

    .footer-links {
      .footer-accordion-content {
        .editorial-footer {
          flex-direction: row;
          flex-wrap: wrap;
          gap: 24px;
        }

        .section-footer-box {
          flex: 0 0 calc(25% - 18px);
        }

        .single-footer-section {
          .accordion-content {
            display: block;
            max-height: none;
            opacity: 1;
          }

          .button-open-footer {
            display: none;
          }

          & >.title-section-footer {
            display: block;
            padding-bottom: 15px;
          }

          .button-icon {
            display: none;
          }
        }
      }
    }
  }
}