// BUTTONS

// Default button

.button,
a.button {
  cursor: pointer;
  letter-spacing: 0.08em;
  line-height: 1.4em;
  font-weight: 400;
  text-align: center;
  border: 1px solid $medium-gray;
  text-transform: uppercase;
  display: inline-block;
  font-size: map-get($button-sizes, small);

  @include breakpoint(medium up) {
    padding: 8px 20px 5px;
    font-size: map-get($button-sizes, default);
  }

  &:hover {
    border-color: $primary-color;

    // @include breakpoint(medium up) {
    //   padding: 8px 20px 5px;
    // }
  }

  // Transparent button

  &.button-transparent {
    border-color: transparent;
    color: $dark-gray;

    &:hover {
      color: $primary-color;
      background: transparent;
      border-color: $primary-color;
    }
  }

  // Large button

  &.button-large {
    padding: 17px 20px 14px;

    @include breakpoint(medium up) {
      padding: 11px 20px 8px;
    }
  }

  // Largest button

  &.button-largest {
    padding: 20px 20px 18px;
    background-color: $light-primary-color;
    color: $secondary-color;

    &:hover {
      background-color: transparent;
      color: $primary-color;
    }
  }

  &.button-fullwidth {
    width: 100%;
    display: block;
  }

  &.button-margin-top {
    margin-top: rem-calc(80);

    @include breakpoint(medium) {
      margin-top: rem-calc(40);
    }
  }

  &.button-border-big {
    border: 2px solid $light-primary-color;
  }

  &[disabled] {
    cursor: pointer;

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }

  &.-solid {
    background-color: $button-bg-blue;
    color: $secondary-color;
  }
}

.jil-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px 15px;
  border: 1px solid $button-border-gray;
  font-size: rem-calc(12);
  font-weight: 400;
  line-height: rem-calc(17);
  letter-spacing: 0.065rem;
  text-transform: uppercase;
  width: 100%;

  @include breakpoint(large up) {
    padding: 10px 20px;
  }

  &.--black {
    background-color: $button-bg-blue;
    color: $secondary-color;
  }
  //
  &.--blue {
    background-color: $button-bg-blue;
    color: $secondary-color;
  }

  &.--small {
    padding: 7px 20px 6px;
    font-size: rem-calc(10);
  }

  &.--auto-width {
    display: inline-flex;
    width: auto;
  }

  &.--transparent {
    border-color: transparent;
    color: $dark-gray;

    &:hover {
      color: $primary-color;
      background: transparent;
      border-color: $primary-color;
    }
  }

  &.--link-button {
    border: none;
    margin: 0 auto;
    margin-top: 10px;
    padding: 0;
    text-transform: capitalize;
    width: min-content;
    &:hover {
      border-bottom: 1px solid $primary-color;
    }
  }
}

.jil-form-message {
  color: $darkgrey;
  &.-danger {
    color: $error-messages;
  }
  &.-info {
    color: $lightgrey;
  }
}
