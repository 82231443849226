$include-fonts: false;

#seasons {
  margin: 145px 30px 0;

  @include breakpoint(large only) {
    margin: 160px 60px 0;
  }

  @include breakpoint(xlarge) {
    margin: 200px 90px 0;
  }

  .season {
    margin-bottom: 40px;

    a {
      &:hover {
        border-bottom: 0;
      }
    }
  }

  .season-title {
    font-size: 12px;
    padding: 14px 0 13px;
    margin-bottom: 15px;
    letter-spacing: 0.08em;
    line-height: 1.4em;
    text-transform: uppercase;
    border-bottom: 1px solid #000;

    @include breakpoint(large) {
      font-size: 10px;
      padding: 15px 0;
      margin-bottom: 20px;
    }
  }

  .season-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;

    @include breakpoint(large only) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include breakpoint(large) {
      grid-gap: 20px;
    }

    @include breakpoint(xlarge) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }

  .season-content-caption {
    padding: 10px 0 0;
    text-align: center;
    font-size: 11px;
    letter-spacing: 0.08em;
    line-height: 1.4em;
    text-transform: uppercase;

    @include breakpoint(large) {
      font-size: 10px;
    }
  }
}
