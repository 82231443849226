.icon {
  border: none;
  min-width: initial;
  padding: 0;
  height: auto;
  @each $name, $uri in $sprites {
    &--#{$name} {
      @extend .icon;

      // @include fixedSprite($name);
      $sprite: map-get($sprites, $name);
      background: url($sprite) center no-repeat;
      display: block;
      height: 25px;
      width: 35px;

      > span {
        display: none;
      }
    }
  }
}
