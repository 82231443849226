// Variables
$color-primary: #111;
$header-height: 44px;

:root {
  --header-height-calc: 48px;
}

.nav-mobile {
  display: none;
}
.nav-desktop {
  display: flex;
  justify-content: space-between;
  height: $header-height;
  flex-direction: column;

  &.headspace {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $header-height;
    z-index: 1000;
    transition: transform 0.2s ease-in-out;

    &--fixed {
      position: fixed;
      background-color: #fbfbf6;
    }

    &--hidden {
      transform: translate3d(0, -$header-height, 0);
    }
  }

  & .desktop-menu-wrapper {
    .menu-section-wrapper {
      display: grid;
      grid-template-rows: 47px;
      grid-template-columns: 1fr max-content 1fr;
      align-items: center;
      grid-template-areas: 'menustart logo menuend';
    }

    .mm-menu__primary__start {
      display: inline-flex;
      grid-area: menustart;
    }

    .mm-menu__primary__center {
      grid-area: logo;

      .item-logo {
        width: 100px;
        height: 38px;
        margin-top: 30px;

        svg {
          width: 100%;
        }
      }
    }

    .mm-menu__primary__end {
      display: inline-flex;
      justify-content: flex-end;
      grid-area: menuend;
    }
  }

  .nav-primary,
  .nav-secondary {
    position: relative;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0;
    margin-bottom: 0;

    & .nav-item-title {
      position: relative !important;

      &::before {
        // display: block;
        // content: '';
        // position: absolute;
        // bottom: 6px;
        // right: 10px;
        // left: 10px;
        // height: 1px;
        // background: transparent;
      }
    }

    .nav-item {
      margin: 0;
      position: relative;
      padding: 24px 10px 6px;

      a,
      button {
        font-size: 0.625rem;
        text-transform: uppercase;
        white-space: nowrap;
      }

      &.is-open,
      &:hover {
        & > .nav-item-title {
          &::before {
            background: rgba(0, 0, 0, .4);
          }
        }
      }

      &.item-mobile-hidden,
      .item-mobile-hidden,
      a {
        display: block;
      }

      .submenu {
        display: none;
        width: 33.33vw;
        min-height: 100vh;
        z-index: 99;
        padding: 5px 15px;

        & > ul {
          margin-bottom: 20px;
        }

        li {
          font-size: rem-calc(10);
          letter-spacing: 0.08em;
          padding: 2px 10px;
          line-height: 1.5em;

          a {
            display: inline-flex;
            padding: 5px 0;
            text-transform: inherit;
          }

          &.item-divider {
            margin-top: 36px;
          }

          &.level-2.special-padding {
            padding-left: 35px !important;
          }
        }

        .descriptionMenu {
          display: grid;
          grid-area: descriptionMenu;
          padding: 10px 35px 20px;
        }

        &#desktop-jilsanderplus .submenu-inner#level-1 {
          & > ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 15px;
            grid-template-rows: auto auto auto;
            grid-template-areas:
              'descriptionMenu descriptionMenu'
              'gallery gallery'
              'plusWoman plusMan';

            & > #submenuItem-jilsanderplus-gallery {
              grid-area: gallery;
              margin-bottom: 40px;
            }
            & > #submenuItem-jilsanderplus-woman {
              grid-area: plusWoman;
              margin-top: 0;
            }
            & > #submenuItem-jilsanderplus-man {
              grid-area: plusMan;
              margin-top: 0;
            }
          }
        }

        & .menu-header-banner {
          padding-left: 0;
        }

        .viewAllList {
          li {
            padding-left: 0;

            a.viewAllLink {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 8px;
              text-decoration: underline !important;
              color: #000 !important;
              padding-left: 0 !important;

              &:hover {
                text-decoration: underline !important;
                color: #000 !important;
                opacity: 1 !important;
              }
            }
          }
        }
      }

      &.is-open > .submenu,
      &.is-open > .submenu .submenu,
      &:hover > .submenu,
      &:hover > .minicart-backdrop .submenu,
      &:hover > .submenu .submenu,
      &.hover > .submenu {
        display: flex;
      }

      &-title {
        padding: 0;
        // display: inline-block;
        text-align: left;
        font-size: rem-calc(10);

        // button,
        // a {
        //   padding: 26px 10px 6px;
        // }
      }
    }
  }

  .nav-primary {
    position: relative;

    & > .nav-item,
    & > .html-slot-container {
      &.level-1,
      & > .level-1,
      &.project-menu-item {
        & > .nav-item-title {
          position: relative;
          z-index: 2;
          background: transparent;
          text-transform: uppercase;
          margin-inline: 0;
          margin: 0 5px;


          .item-search {
            padding-left: 0;
          }
        }

        & > .submenu {
          position: fixed;
          top: 0;
          bottom: 0;
          width: var(--menu-width-calc , 33.3vw);
          min-width: 560px;
          min-height: 100vh;
          height: 100vh;
          padding-top: var(--header-height-calc);
          z-index: 0;
          overflow-y: hidden;
          scrollbar-color: #ccc transparent;
          scrollbar-width: thin;

          .submenu-inner#level-1 {
            scrollbar-width: thin;
            scrollbar-color: #ccc transparent;
            overflow-y: scroll;
            padding-top: 48px;

            .menu-photo-tile {
              order: 1;
              padding: 0 35px 35px; 

              .caption-box {
                margin: 0;
                max-width: 100%;
              }

              a.clickableItemContainer {
                &:hover {
                  border-bottom: 1px solid transparent;
                }
              }

              .experience-assets-richTexttile {
                padding: 10px 0;
                font-size: 0.625rem;

                p {
                  margin: 0;
                }

                a {
                  &:hover {
                    border-bottom: 1px solid transparent;
                  }
                }
              }

              .experience-assets-photoTile + .experience-assets-richTexttile {
                padding-bottom: 0;
              }
            }
          }

          & .submenu,
          & .submenu .submenu {
            width: 100%;
            min-height: auto !important;
          }
        }
      }
    }

    .submenu-inner#level-1 {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
    }
  }

  .nav-secondary {
    .nav-item {
      .submenu {
        position: fixed;
        right: 0;
        width: 25vw;

        * {
          text-transform: uppercase;
        }

        li {
          a {
            padding: 0;
            width: 100%;
          }
        }

        &.customer-care-layer {
          padding: 130px 5px 15px;

          .submenu-title {
            color: rgba(0, 0, 0, 0.5);
          }
        }

        & .account-submenu-container {
          padding: 130px 5px 15px;

          .submenu-title {
            display: none;
          }

          .submenu-box-newsletter {
            padding: 0 10px 10px;
          }
        }

        &.mini-cart-content {
          padding-top: 55px;
        }
      }
    }
  }
}

.header-notification-slot-container.mobile-version {
  display: none;
}

a.link-icon-left {
  svg {
    position: relative;
    top: inherit;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.minicart {
  .nav-item-title {
    margin-top: -1px;
  }

  .recommendations {
    .carousel {
      padding: 0;
    }
  }
}

body {
  > #inside_holder {
    z-index: 1 !important;
  }
}

// .home-page .item-logo {
//   display: none !important;
// }

/* Apply background to all .nav-item-titles with submenu */
.nav-desktop .nav-primary .nav-item {
  & .nav-item-title {
    background-color: transparent;
  }

  &.js-nav-item:hover {
    & ~ .nav-item .nav-item-title,
    & .nav-item-title {
      background-color: #f9f7ef;
    }
  }
}
.nav-secondary .nav-item:hover .nav-item-title button,
.nav-secondary .nav-item:hover .nav-item-title a,
.nav-primary .nav-item:hover .nav-item-title button {
  border-bottom: 1px solid #000;
}

.nav-secondary .nav-item .nav-item-title button:hover, 
.nav-secondary .nav-item .nav-item-title a:hover, 
.nav-primary .nav-item .nav-item-title button:hover {
  border-color: rgba(0, 0, 0, .4);
}

.link-icon-left .mini-cart-link {
  margin-bottom: 0;
}

.nav-primary .nav-itemitem-mobile-hidden .nav-item-title,
.nav-primary .nav-item .item-mobile-hidden .nav-item-title{
  margin: 0;
}

.nav-primary .nav-item:hover.item-mobile-hidden .nav-item-title button,
.nav-primary .nav-item:hover .item-mobile-hidden .nav-item-title button {
  border-bottom: none;
}

.nav-primary .nav-item.item-mobile-hidden .nav-item-title button:hover
.nav-primary .nav-item .item-mobile-hidden .nav-item-title button:hover {
  border-color: none;
}


