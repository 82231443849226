.carousel-product-tile{
    padding: rem-calc(40 0);

    .owl-carousel{
        .owl-item{
            .item{
                width: 100%;

                .pdp-link{
                    @include breakpoint(small down) {
                        margin: rem-calc(0 10);
                    }

                    .link{
                        @include breakpoint(small down) {
                            font-size: rem-calc(11);
                        }
                    }
                }

                .price span{
                    @include breakpoint(small down) {
                        font-size: rem-calc(11);
                    }
                }
            }
        }
    }    
}
