.order-history-grid {
  display: grid;
  grid-template-areas:
    'order-number order-track order-view'
    'order-image order-attributes order-quantity';

  grid-template-columns: max-content;
  row-gap: 15px;
  column-gap: 18px;

  .order-number {
    grid-area: order-number;
  }
  .order-track {
    grid-area: order-track;
    color: $button-border-gray;
  }
  .order-view {
    grid-area: order-view;
    color: $button-border-gray;
  }
  .order-image {
    grid-area: order-image;
    max-width: 94px;
  }
  .order-attributes {
    grid-area: order-attributes;
    grid-column: 2 / span 2;

    .cell {
      padding-bottom: 15px;
    }
  }
  .order-quantity {
    grid-area: order-quantity;
    display: grid;
    place-content: flex-end;
    .order-quantity-content {
      display: flex;
    }

    color: $button-border-gray;
  }

  &.legacy-order {
    grid-template-areas:
      'order-number order-track order-view'
      'order-attributes order-attributes order-quantity';
    .order-attributes {
      grid-column: 1 / span 3;
    }
  }
}
