$include-fonts: false;

#projects {
  margin-top: 105px;

  @include breakpoint(large) {
    margin-top: 130px;
  }

  @include breakpoint(xlarge) {
    margin-top: 175px;
  }

  .projects-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }

  .project {
    grid-column: span 6;
    margin: 30px 30px 0;

    @include breakpoint(large) {
      grid-column: span 3;
      margin: 40px 40px 0;
    }

    @include breakpoint(xlarge) {
      margin: 60px 60px 0;
    }

    &:hover {
      border-bottom: none;
    }
  }

  .project-image {
    transition: opacity 1s ease-in-out;

    img {
      width: 100%;
      display: block;
    }
  }

  .project-caption {
    text-align: center;
    margin: 20px 0 55px;

    @include breakpoint(large) {
      margin: 30px 0 80px;
    }

    p {
      margin-bottom: rem-calc(6);
      font-size: 10px;
      letter-spacing: 0.08em;
      line-height: 1.4em;
    }
  }

  .filter-panel {
    padding: 10px 30px 8px 30px;
    font-size: 9px;
    letter-spacing: 0.08em;
    line-height: 1.5em;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint(large) {
      padding: 10px 30px 6px 30px;
    }

    #projects-filter-toggle {
      color: #000;
      cursor: pointer;
      margin: 22px 25px 10px 5px;
      text-transform: uppercase;
      opacity: 0.7;
      position: relative;
      font-size: 11px;
      letter-spacing: 0.09em;
      border-bottom: 1px solid transparent;
      outline: none;

      @include breakpoint(large) {
        font-size: 9px;
      }

      &::after {
        content: '+';
        position: absolute;
        right: -15px;
        top: -3px;
        font-size: 14px;
        font-weight: 100;

        @include breakpoint(large) {
          font-size: 12px;
        }
      }

      &.active {
        @include breakpoint(medium) {
          border-bottom: 1px solid #000;
        }

        &::after {
          content: '-';
          font-size: 18px;
          right: -13px;
          top: -5px;
        }
      }

      &:hover {
        opacity: 1;
        border-bottom-color: #000;
      }
    }

    #projects-filters {
      display: none;
      position: relative;

      @include breakpoint(small only) {
        background: #fff;
        position: fixed;
        bottom: -50vh;
        right: 0;
        left: 0;
        transition: all 0.5s ease-in-out;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
        height: 50vh;
        padding: 30px 20px 0 20px;
        display: block;
      }

      &.visible {
        display: block;

        @include breakpoint(small only) {
          bottom: 0;
        }
      }
    }

    #close-projects-filter {
      position: absolute;
      right: 5px;
      top: 10px;
      width: 44px;
      height: 44px;
      cursor: pointer;
      font-size: 20px;
      opacity: 0.5;

      @include breakpoint(large) {
        display: none;
      }
    }

    ul {
      list-style: none;
      padding: 10px 30px;
      margin: 0;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      display: flex;

      @include breakpoint(small only) {
        margin-top: 45px;
      }

      li {
        margin: 0;

        @include breakpoint(small only) {
          padding: 13px 10px 7px 10px;
        }
      }

      .filter-trigger {
        cursor: pointer;

        &.active,
        &:hover {
          border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        }

        @include breakpoint(small only) {
          font-size: 11px;
        }
      }
    }
  }
}

.project-content {
  &:first-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 120px;
    margin-bottom: 30px;

    @include breakpoint(xlarge) {
      min-height: 118px;
    }

    p.title {
      font-size: 16px;
      line-height: 1.5em;
      letter-spacing: 0.02em;
      -webkit-font-smoothing: auto;
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: 15px;

      @include breakpoint(small only) {
        padding-bottom: 60px;
        letter-spacing: 0.32px;
        line-height: 24px;
      }

      @include breakpoint(xlarge) {
        font-size: 17px;
      }

      + .page-content-box {
        font-size: 9px;
        letter-spacing: 0.08em;
        line-height: 1.5em;
        margin-top: 0;
        margin-bottom: 0;

        p.small {
          font-size: 9px;
          letter-spacing: 0.08em;
          line-height: 1.5em;
          margin-top: 0;
          margin-bottom: 0;

          @include breakpoint(small only) {
            font-size: 11px;
            letter-spacing: 0.88px;
            line-height: 16.5px;
          }
        }
      }
    }
  }

  .pd-slider .owl-carousel {
    @include breakpoint(large) {
      min-height: 480px;
    }

    @include breakpoint(xlarge) {
      min-height: 640px;
    }
  }
  .owl-carousel .owl-stage {
    align-items: center;
  }
}
