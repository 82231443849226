#cookies-modal {
  .cookies-modal-container {
    max-width: 450px;
    top:0;
    bottom: 0;
    margin:auto;
    max-height: fit-content;
  }
}

#cookies-management-modal {
  .cookies-modal-container {
    background-color: $off-white;
    width: 389px;

    position: fixed;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 2;

    .cookies-modal-close {
      top: 21px;
      right: 21px;
    }
  }
}