// #account-addressBook {
//     @include container;

//     align-items: center;
//     min-height: calc(100vh - 220px);
//     line-height: 1.5;
//     padding: 0 30px;

//     @include breakpoint(small only) {
//         &.spacer-header {
//             margin-top: 154px;
//         }
//     }

//     .card-header {
//         border-bottom: 1px map_get($jil-palette, "black") solid;
//         padding-bottom: 13px;
//         margin-bottom: 16px;
//         letter-spacing: 0.02em;
//         line-height: 1.5;

//         .action {
//             @include card-header-action;
//         }
//     }

//     .address {
//         border: 1px map_get($jil-palette, "light-gray") solid;
//         padding: 15px 0;
//         margin-bottom: 20px;

//         .info {
//             @include breakpoint(small only) {
//                 flex-basis: 100%;
//                 order: 1;
//             }
//         }

//         .actions {
//             button,
//             a {
//                 color: map_get($jil-palette, "dark-gray");
//                 border-bottom: 1px solid transparent;

//                 &:hover {
//                     color: $black;
//                     border-color: map_get($jil-palette, "medium-gray");
//                 }
//             }

//             > * + * {
//                 margin-left: 8px;
//             }
//         }
//     }

//     .buttons {
//         margin-top: 30px;
//     }
// }

// #account-address {
//     @include breakpoint(small only) {
//         .inner-box {
//             padding-top: 14px;
//         }
//     }

//     .card {
//         width: 100%;

//         .card-header {
//             border-bottom: 1px map_get($jil-palette, "black") solid;
//             padding-bottom: 13px;
//             margin-bottom: 40px;
//             letter-spacing: 0.02em;

//             .action {
//                 @include card-header-action;
//             }
//         }
//     }

//     .form-group {
//         margin-top: 22px;

//         @include breakpoint(small only) {
//             margin-top: 32px;

//             input {
//                 line-height: 1.5;
//             }
//         }

//         &.spaced {
//             margin-top: 80px;

//             @include breakpoint(medium) {
//                 margin-top: 40px;
//             }
//         }

//         &--checkboxes {
//             margin-top: 32px;
//             margin-bottom: 32px;

//             @include breakpoint(medium) {
//                 margin-top: 22px;
//                 margin-bottom: 22px;
//             }
//         }

//         .checkbox-label {
//             &::before, &::after {
//                 top: 50%;
//                 transform: translateY(-50%);
//             }
//         }

//         &.required {
//             label::after {
//                 content: "*";
//             }
//         }
//     }

//     button[name="save"] {
//         margin-top: 30px;
//     }

//     .info-required {
//         color: map_get($jil-palette, "dark-gray");
//     }

//     .parsley-errors-list {
//         color: map_get($jil-palette, "error");
//     }
// }

.address-detail-page {
  .invalid-feedback {
    &:not(:empty) {
      margin: 10px 0 0;
    }
  }

  .card-box {
    border: 1px solid #c9c9c5;
    padding: 15px 8px;
    margin-bottom: 30px;
    font-size: rem-calc(14);
    font-weight: 500;
  }

  .card {
    display: grid;
    grid-template-areas:
      'title actions'
      'body .'
      'default-actions .';

    grid-template-columns: max-content;
    row-gap: 25px;
    column-gap: 18px;
  }

  .card-header {
    grid-area: title;
  }
  .card-body {
    grid-area: body;
  }
  .card-actions {
    grid-area: actions;
    display: flex;
    justify-content: flex-end;
  }
  .card-actions-default {
    grid-area: default-actions;
  }

  .card-actions-default,
  .card-actions {
    a {
      color: #969693;
    }
  }

  .single-card-action {
    color: #969693;
    font-weight: 500;
    + .single-card-action {
      margin-left: 20px;
    }
  }
}

.buttons-wrapper {
  margin-top: rem-calc(26);
  display: flex;
  flex-direction: column;
  .jil-button {
    & + .jil-button {
      margin-top: 10px;
    }
  }
}

.modal-overlay {
  .delete-address-modal-container .modal-content {
    display: flex;
    flex-direction: column;
    height: inherit;
    justify-content: space-between;
    padding: 60px 0 12px;
  }

  .modal-title {
    font-size: 12px;
    font-weight: normal;
    text-align: center;
  }
}
//
#delete-address-modal {
  @include breakpoint(small only) {
    .modal-background {
      height: 100%;
    }
    .delete-modal-dialog,
    .delete-address-modal-container {
      height: inherit;
    }
  }
}
