.accordion {
  &.is-enabled {
    .accordion-content {
      transition: all ease-in 0.3s;
      max-height: 0;
      display: none;
      opacity: 0;
    }

    &.is-active {
      > .accordion-content {
        display: block;
        max-height: 20000px;
      }
      .accordion-trigger.accordion-pdp::after {
        content: "\2212";
      }

      &.is-visible {
        > .accordion-content {
          opacity: 1;
        }

        .accordion-icon {
          transition: all ease-in 0.3s;
          transform: rotate(180deg);
        }
      }
    }
  }

  &-title {
    width: 100%;
    justify-content: space-between;
    &-icon {
      margin-top: 0.8rem;
    }
  }
}

.accordion-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: all ease-in 0.3s;
}
.accordion-trigger.accordion-pdp:after {
  content: "\002B";
}

.accordion-group-list-container {
  // padding-top: 3rem;
  padding-top: 1rem;
  .accordion-trigger {
    // padding: 16px 10px;
    padding: 16px 0 10px;
    border-bottom: 1px solid #c8c8c4;
  }
  .accordion-content {
    background-color: transparent;
    padding: 18px 13px;
  }

  font-size: rem-calc(14);
  font-weight: 500;
  @include breakpoint(large up) {
    font-size: rem-calc(12);
  }

  .accordion-content {
    ul {
      & + ul {
        margin-top: 30px;
      }
    }
  }
}
